import { useEffect, useState } from "react";
import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/header";
import carregando from '../../components/Geral/loading.gif';
import '../obras/Obras.css'
import {base_url, midia_url, foto_default, error_default} from '../../environment'
import Skeleton from "@mui/material/Skeleton";

import styled from "@emotion/styled";
var CSSPadrao = styled.div`${localStorage.getItem("cssGeral")}`

var urlGeral = base_url+'/public/prefeitura/obras';

var qtdPaginas=1, page, inicio = 0, fim, urlPagina,  tamanhoFixo = 9, status;
var umaVez=true;
var unaVez=true;
var pinaVez=true;
var optionClicado = "background-color: var(--mainColor); color: var(--textColor2);"

function Obras(){

    function loading() {
        return <div id='loading'><img src={carregando} alt="Carregando"/></div>;
    }  

    const [obrasArray, setobrasArray] = useState([]);
       
    const search = window.location.search;
    const params = new URLSearchParams(search);
    page = params.get('page');
    if(page == null)page = 1;

    inicio = tamanhoFixo * (page -1); 
    fim = tamanhoFixo * page;

    status = params.get('status');    
    localStorage.setItem("statusSelecionada", status);

    if(status == null){
        urlPagina = String(urlGeral+"?&start="+inicio+"&size="+fim);
    }
    else
    {
        urlPagina = String(urlGeral+"?start="+inicio+"&size="+fim+"&status="+status);
    }
     

    // pegar dados das obras (intervalo) e marcar filtros
    useEffect(() => {
        if(pinaVez)
        {
            fetch(urlPagina)
            .then((d) => d.json())
            .then(dados => {      

                //setobrasArray(dados);
                var obras = [];
                for(let i=0; i<=dados.length; i++){
                    
                    if(dados[i] == null)
                    {
                        if(i > 0)break;
                        obras.push(
                            // eslint-disable-next-line
                            String('<a target="_self" href=/pontos-turisticos rel="noreferrer"><button><img src='+error_default+
                            ' alt="Noticia"/><p></p><p>Não há dados. </p></button></a>')
                        );   
                    }
                    else
                    {
                        var origemNoticia = window.location.origin+"/obra?slug="+dados[i].slug;

                        var imgSource;
                        if(dados[i].capa == null){
                            imgSource = error_default;}
                        else{
                            imgSource = midia_url+dados[i].capa;
                        }

                        let data = new Date(dados[i].data_inicio).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).toString();

                        obras.push(
                            // eslint-disable-next-line
                            String('<a target="_self" href='+origemNoticia+'><button><img src='+imgSource+' alt="Obra"/><p>'+dados[i].titulo+' <br>Início: '+data+'</p><p>Progresso: '+dados[i].progresso+'% </p></button></a>')
                        );
                    }
                }                  
                document.getElementById("dados").innerHTML = obras; 

                
                if(status == null){document.getElementById('none').style = optionClicado;} 
                if(status == "lancada"){document.getElementById('lancada').style = optionClicado;} 
                if(status == "em_execucao"){document.getElementById('em_execucao').style = optionClicado;} 
                if(status == "executada"){document.getElementById('executada').style = optionClicado;} 
                
            });
            

            if(status != null)
            {
                document.getElementById(status).scrollIntoView({ behavior: "smooth", block: "end" });
            }


            pinaVez=false;
            
        }
    }, []);

    // verificar quantas páginas tem
    if(umaVez)
    {
        if(status == null){
            fetch(urlGeral)
            .then((d) => d.json())
            .then(dados => {      
                //console.log(dados.length); 
                // eslint-disable-next-line
                if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message}`;}

                var extrapolou = dados.length % tamanhoFixo;
                qtdPaginas = (dados.length - extrapolou) / tamanhoFixo;
                // eslint-disable-next-line
                if(extrapolou != 0) qtdPaginas = qtdPaginas + 1;
                //console.log(qtdPaginas);    
                
                var movepag = null;
                
                for(let i=1; i<=qtdPaginas; i++){

                    const index = document.createElement("a");
                    index.href = "obras?page="+i;
                    const butao = document.createElement("button");
                    butao.innerHTML = i;
                    butao.id = i;

                    // eslint-disable-next-line
                    if(page == i)
                    {
                        butao.style = optionClicado;
                        movepag = String(i);
                    }  


                    index.appendChild(butao);
                    document.getElementById("indexObras").appendChild(index);   
                }

                if(movepag != null)
                {
                    document.getElementById(movepag).scrollIntoView({ behavior: "smooth", block: "end" });
                }   

                
                document.getElementById("ultimaPagina").href = 'obras?page='+ qtdPaginas;
                    
            });
        }
        else
        {
            fetch(urlPagina)
            .then((d) => d.json())
            .then(dados => {      
                //console.log(dados.length); 
                // eslint-disable-next-line
                if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message}`;}

                var extrapolou = dados.length % tamanhoFixo;
                qtdPaginas = (dados.length - extrapolou) / tamanhoFixo;
                // eslint-disable-next-line
                if(extrapolou != 0) qtdPaginas = qtdPaginas + 1;
                //console.log(qtdPaginas);     
                
                
                    for(let i=1; i<=qtdPaginas; i++){

                        const index = document.createElement("a");
                        index.href = "obras?page="+i+"&status="+status;
                        const butao = document.createElement("button");
                        butao.innerHTML = i;
                        butao.id = i;

                        index.appendChild(butao);
                        document.getElementById("indexObras").appendChild(index);   
                    }

                
                
                
                document.getElementById("ultimaPagina").href = 'obras?page='+ qtdPaginas +"&status="+status;
                document.getElementById("primeiraPagina").href = 'obras?page=1&status='+status;  
            });
        }     
        
        umaVez=false;
    }   
   
    // pular dez apenas se tiver páginas suficientes
    if((qtdPaginas-page) > 10){
        document.getElementById("menos10").href = 'obras?page='+ (page - 10);
        document.getElementById("mais10").href = 'obras?page='+ (page + 10);
    } 


    return(
        <>   
            <CSSPadrao>
            <Header />
                <p id="pagObras" className="pagObras">Obras</p>
                <div className="secretarias">
                    <div id="secretarias">
                        <a href="?" target="_self"><button id="none" >Todas</button></a>
                        <a href="?status=lancada" target="_self"><button  id="lancada" > Lançada</button></a>
                        <a href="?status=em_execucao" target="_self"><button  id="em_execucao" > Em Execução</button></a>
                        <a href="?status=executada" target="_self"><button  id="executada" > Executada</button></a>
                    </div> 
                </div>

                <div className="ptsTuristicos">
                    <div id="ptsTuristicos" >
                    </div>                    
                    
                    <div className="obrasInfo ptsTuristicosInfo" >
                        <div id='dados'>{loading()} </div>  
                    </div>   
                </div>
                
                <div className="menuObras">
                    <div id="indexObras"></div>
                    <div>
                        <a id="primeiraPagina" href="obras" ><button >Primeira</button></a>
                        <a id="menos10" href="obras" ><button >-10 páginas</button></a>
                        <a id="mais10" href="obras" ><button >+10 páginas</button></a>
                        <a id="ultimaPagina" href="obras" ><button >Última</button></a>
                    </div>
                </div>
            </CSSPadrao>
            <Footer/>
        </>   
    );
}

export default Obras;