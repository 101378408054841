import {React} from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import  "./main.css" 

export default function Perguntas(props){
 



  

    return(
        <div className="container-pergunta">
         
        <div className="content-perguntas">

        
        <Accordion className="teste-perguntas" style={{width: '90%', marginBottom: '1rem'}}>
  <AccordionSummary
    expandIcon={<ExpandMoreIcon />}
    aria-controls="panel1a-content"
    id="panel1a-header"
  >
    <Typography style={{fontSize: '1.5rem'}}>{props.title}</Typography>
  </AccordionSummary>
  <AccordionDetails>
    <Typography  style={{fontSize: '1.2rem'}}>
      {props.description}
    </Typography>
  </AccordionDetails>
</Accordion>
       
              
        
        

      </div>
      </div>
        );
}