import React, { useEffect} from "react";
import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/header";
import './Plenarias.css'
import carregando from '../../components/Geral/loading.gif';
import {base_url} from '../../environment'
import styled from "@emotion/styled";
var CSSPadrao = styled.div`${localStorage.getItem("cssGeral")}`
var optionClicado = "background-color: var(--mainColor); color: var(--textColor2);"

function Pessoa(){

    function loading() {
        return <div id='loading'><img src={carregando} alt="Carregando"/></div>;
    }
    // pegar dados dos concursos
    useEffect(() => {
        fetch(`${base_url}/public/camara/sessoes-plenarias`)
        .then((d) => d.json())
        .then(dados => { 

            // eslint-disable-next-line
            if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message}`;}

            // eslint-disable-next-line 
            if(dados.length == 0)
            {
                document.getElementById("aberto").innerHTML = '<button><p>Não há plenárias.</p><p></p><p></p></button>';
                    
            }
            else
            {
                var arrayInicial = [];
                
                for(let i=0; i<dados.length; i++){

                    if((dados == null) || (dados[i] == null))
                    {
                        arrayInicial.push(
                            // eslint-disable-next-line
                            String('<button><p> Sem dados disponíveis. </p>'
                            +'<p> - </p>'
                            +'<p> - </p>'
                            +'</button>')                        
                        );
                        break;
                    }

                    var data = new Date(dados[i].data).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).toString();
                
                    arrayInicial.push(
                        // eslint-disable-next-line
                        String('<button><p>'+dados[i].titulo+', nº '+dados[i].numero+'</p><p>Data: </p><p>'+data+'</p><a target="_self" href=./plenaria/'+dados[i].slug+'>Saiba mais</a></button>')
                        
                    );  
                }
                document.getElementById("aberto").innerHTML = arrayInicial;   
            }            
        });     
        
        
    }); 

    return(
        <>   
            <Header />
            <CSSPadrao>
                <div className="concurso">
                    <div className="tituloPagina"> 
                        <p id="titu" >Plenárias</p>
                    </div>                
                    <div className="concursoCard" >
                        <div id="aberto">{loading()}</div>
                    </div>                       
                </div>
                </CSSPadrao>
            <Footer/>
        </>   
    );
}

export default Pessoa;