import React, { useEffect, useState} from "react";
import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/header";
import './Documentos.css'
import carregando from '../../components/Geral/loading.gif';
import {base_url} from '../../environment'
import styled from "@emotion/styled";
var CSSPadrao = styled.div`${localStorage.getItem("cssGeral")}`

var optionClicado = "background-color: var(--mainColor); color: var(--textColor2);"

var urlDocs, essaVez=true, cadavez=true, pinaVez = true;

function Pessoa(){

    function loading() {
        return <div id='loading'><img src={carregando} alt="Carregando"/></div>;
    }

    const [refreshKey, setRefreshKey] = useState(0);

    const search = window.location.search;
    const params = new URLSearchParams(search);
    console.log(String(params))

    var tipo_documento_id = params.get('tipo_documento_id');
    var orgao = params.get('orgao_id');
    var grupo_documento_id = params.get('grupo_documento_id');
    
    //console.log(grupo_documento_id)
   
    if(String(params) == ""){
        urlDocs = String(base_url+"/public/documentos");
    }
    else
    {
        urlDocs = String(base_url+"/public/documentos"+"?"+params.toString());
    }

    // pegar dados  grupo_documento
    if(essaVez){
        fetch(`${base_url}/public/grupos-documento`)
        .then((d) => d.json())
        .then(dados => { 


            // eslint-disable-next-line 
            if(dados.length == 0)
            {
                window.location.pathname = `mensagem/Não há grupos de documento.`;    
            }
            else
            {
                //console.log(dados);
                

                var dado = document.getElementById("grupo_documento_id");   

                const option = document.createElement("option");
                option.value = "";
                option.innerHTML = "--";      
                option.id = "todos";        
                dado.appendChild(option);
                            
                for(let i=0; i<dados.length; i++){
                    const option = document.createElement("option");
                    option.value = dados[i].id;
                    option.id = dados[i].id;
                    option.innerHTML = dados[i].nome;                  
                    dado.appendChild(option);
                }

                if(grupo_documento_id != null)
                document.getElementById(grupo_documento_id).selected = true;                


            }            
        })    
        .catch(error => { 
            console.log(error.message);
            if(error.response)
            {
                // eslint-disable-next-line
                if(error.dados.statusCode == 401)
                {
                    window.location.pathname = `login`;
                }
                else{
                    window.location.pathname = `mensagem/${error.message}`;
                }  
            }
            else{
                window.location.pathname = `mensagem/${error.message}`;
            }            
        });
        essaVez=false;
    }

    // pegar dados  tipos_documento
    if(pinaVez){
        fetch(`${base_url}/public/tipos-documento`)
        .then((d) => d.json())
        .then(dados => { 


            // eslint-disable-next-line 
            if(dados.length == 0)
            {
                window.location.pathname = `mensagem/Não há tipos de documento.`;    
            }
            else
            {
                //console.log(dados);
                

                var dado = document.getElementById("tipo_documento_id");   

                const option = document.createElement("option");
                option.value = "";
                option.innerHTML = "--";      
                option.id = "todos";        
                dado.appendChild(option);
                            
                for(let i=0; i<dados.length; i++){
                    const option = document.createElement("option");
                    option.value = dados[i].id;
                    option.id = dados[i].id;
                    option.innerHTML = dados[i].nome;                  
                    dado.appendChild(option);
                }

                if(tipo_documento_id != null)
                document.getElementById(tipo_documento_id).selected = true;
            

            }            
        })    
        .catch(error => { 
            console.log(error.message);
            if(error.response)
            {
                // eslint-disable-next-line
                if(error.dados.statusCode == 401)
                {
                    window.location.pathname = `login`;
                }
                else{
                    window.location.pathname = `mensagem/${error.message}`;
                }  
            }
            else{
                window.location.pathname = `mensagem/${error.message}`;
            }            
        });
        pinaVez=false;
    }

    // pegar dados orgaos
    if(cadavez){
        fetch(`${base_url}/public/orgaos`)
        .then((d) => d.json())
        .then(dados => { 


            // eslint-disable-next-line 
            if(dados.length == 0)
            {
                window.location.pathname = `mensagem/Não há órgãos.`;    
            }
            else
            {
                //console.log(dados);
                

                var dado = document.getElementById("orgao");   

                const option = document.createElement("option");
                option.value = "";
                option.innerHTML = "--";      
                option.id = "todos";        
                dado.appendChild(option);
                            
                for(let i=0; i<dados.length; i++){
                    const option = document.createElement("option");
                    option.value = dados[i].id;
                    option.id = dados[i].id;
                    option.innerHTML = dados[i].nome;                  
                    dado.appendChild(option);
                }

                if(orgao != null)
                document.getElementById(orgao).selected = true;

            }            
        })    
        .catch(error => { 
            console.log(error.message);
            if(error.response)
            {
                // eslint-disable-next-line
                if(error.dados.statusCode == 401)
                {
                    window.location.pathname = `login`;
                }
                else{
                    window.location.pathname = `mensagem/${error.message}`;
                }  
            }
            else{
                window.location.pathname = `mensagem/${error.message}`;
            }            
        });
        cadavez=false;
    }
    
    // pegar dados dos documentos
    // filtros e funcoes no final
    useEffect(() => {
        fetch(urlDocs)
        .then((d) => d.json())
        .then(dados => { 

            // eslint-disable-next-line
            if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message}`;}

            // eslint-disable-next-line 
            if(dados.length == 0)
            {
                document.getElementById("valores").innerHTML = '<button><p>Não há documentos.</p><p></p><p></p></button>';
                    
            }
            else
            {
                var arrayInicial = [];
                
                for(let i=0; i<dados.length; i++){

                    if((dados == null) || (dados[i] == null))
                    {
                        arrayInicial.push(
                            // eslint-disable-next-line
                            String('<button><p> Sem dados disponíveis. </p>'
                            +'<p> - </p>'
                            +'<p> - </p>'
                            +'</button>')                        
                        );
                        break;
                    }

                    var data = new Date(dados[i].data).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).toString();

                    var titulo = dados[i].titulo.length < 20 ? titulo = dados[i].titulo+'<br></br>'  : titulo = dados[i].titulo.substring(0,40);
                    var texto = dados[i].ementa.length < 20 ? texto = dados[i].ementa +'<br></br>' : texto = dados[i].ementa.substring(0,50);

                   
                
                    arrayInicial.push(
                        // eslint-disable-next-line
                        String('<button><p>Documento nº '+dados[i].numero+'</p>'   
                        +'<p>Título: '+titulo+'</p>'                                    
                        +'<p>Data: '+data+'</p>'           
                        +'<p>Tipo: '+dados[i].Tipo.nome+'</p>'  
                        +'<p>Ementa: '+texto+'</p>'      
                        +'<a target="_self" href=documentos/'+dados[i].slug+'>Saiba mais</a>'
                        +'</button>')
                        
                    ); 
                    
                }
                document.getElementById("valores").innerHTML = arrayInicial;   
            }            
        });     
             

        //chama funcao ao selecionar o grupo_documento_id
        document.getElementById("grupo_documento_id").addEventListener("change", (e) => filtros(document.getElementById("grupo_documento_id").value, null, null));
        
        
        //chama funcao ao selecionar a tipo_documento_id
        document.getElementById("tipo_documento_id").addEventListener("change", (e) => filtros(null, document.getElementById("tipo_documento_id").value, null));
        
        //chama funcao ao selecionar o orgao
        document.getElementById("orgao").addEventListener("change", (e) => filtros(null, null, document.getElementById("orgao").value));
        
    },[refreshKey]); 

    function primeiraMauiscula(valor) {
        
        var str = valor.replace("_", " ");
        str = str.toLowerCase();
        const words = str.split(' ');
        for (let i = 0; i < words.length; i++) {
            words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
        }
        return words.join(' ');
    }

    function filtros(valor1, valor2, valor3){

        document.getElementById("valores").innerHTML = '<button><p>Carregando...</p><p></p><p></p></button>';

        
        //window.location = String("licitacoes?modalidades-licitacao="+filtro);    
        
        // Change URL without reloading webpage
        const search = window.location.search;
        const params = new URLSearchParams(search);

        if(valor1 != null){params.set("grupo_documento_id", valor1)}        
        if(valor2 != null)params.set("tipo_documento_id", valor2);
        if(valor3 != null)params.set("orgao_id", valor3);
        
        if (valor1 == ""){params.delete("grupo_documento_id");}
        if (valor2 == ""){params.delete("tipo_documento_id");}
        if (valor3 == ""){params.delete("orgao_id");}

        //console.log(params.toString());
        window.history.replaceState({}, "", String(window.location.pathname+"?"+params.toString()));
        
        const redireciona = setInterval(function () {

            setRefreshKey(oldKey => oldKey +1); 
            clearInterval(redireciona);

        }, 2000);     
       // window.history.replaceState({}, "", String(window.location.pathname+window.location.search+"?modalidades-licitacao="+filtro));

    }

    // menu pesquisa
    const [pesquisa, setPesquisa] = React.useState("");
    function handleSubmit(event) {

        document.getElementById("valores").innerHTML = '<button><p>Carregando...</p><p></p><p></p></button>';
        
        // Change URL without reloading webpage
        const search = window.location.search;
        const params = new URLSearchParams(search);

        params.set("pesquisa", pesquisa)      

        //console.log(params.toString());
        window.history.replaceState({}, "", String(window.location.pathname+"?"+params.toString()));
        
        const redireciona = setInterval(function () {

            setRefreshKey(oldKey => oldKey +1); 
            clearInterval(redireciona);

        }, 2000);    
    }   
    
    return(
        <>   
            <Header />
            <CSSPadrao>
                <div className="licitacao">
                    <div className="tituloPagina"> 
                        <p id="titu" >Documentos</p>
                    </div>        

                    <div className="licitacaoOpt" >
                        
                        <div className="LicitacaoOptions">
                            <p>Grupo</p>
                            <select name="grupo_documento_id" id="grupo_documento_id">
                            </select>
                        </div>        

                        <div className="LicitacaoOptions">
                            <p>Tipo</p>
                            <select name="tipo_documento_id" id="tipo_documento_id">
                            </select>
                        </div>   


                        <div className="LicitacaoOptions">
                            <p>Órgão</p>
                            <select name="orgao" id="orgao">
                            </select>
                        </div>         

                        <div className="LicitacaoOptions">
                            <p>Pesquisar</p>
                            <input type="text" name="pesquisa" id="pesquisa" placeholder="Digite: " autoComplete="off" onChange={(e) => setPesquisa(e.target.value)} ></input>
                            <p onClick={handleSubmit}>Ir</p>
                        </div>             
                        
                    </div>   

                    <div className="licitacaoCard" >
                        <div id="valores">{loading()}</div>
                    </div>                       
                </div>
            </CSSPadrao>
            <Footer/>
        </>   
    );
}

export default Pessoa;