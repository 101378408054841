import { Chart } from "react-google-charts";
import React, { useEffect, useState } from 'react';
import {base_url} from '../../../environment'

function Graficos() {

  const [grafico, setGrafico] = useState({ 'Aguardando...': 0 })

  useEffect(() => {
    async function fetchData() {
      try {
        var dataAtual = new Date().toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).toString();
        var anoAnterior = parseInt(dataAtual.substring(6))-1;
        //console.log(anoAnterior); 

        const url = await fetch(`${base_url}/public/ouvidoria/relatorio-tipo?ano=${anoAnterior}`);        
        const resource = await url.json();

        // eslint-disable-next-line
        if((resource.statusCode != undefined) && (resource.statusCode != 200)){window.location.pathname = `mensagem/${resource.message}`;}
                      
        setGrafico(resource)       
       
      } catch (error) {
        console.log('requisição não de certo')
      }
    }
    fetchData();
  }, []);
  
  return (
 
      <div className="">
        <Chart
          width={"100%"}
          height={"600px"}
          chartType="ColumnChart" //azul        vermelho            verde         cinza                 laranja
          data={[  
            ["Tipo", "Em Andamento","Fora do Prazo", "Respondidas", "Indeferido", "Respondido fora do prazo"], 
            ...Object.keys(grafico).map((tipo) => [
                tipo, 
                grafico[tipo].EM_TRAMITACAO || 0, 
                grafico[tipo].RESPONDIDO || 0,
                grafico[tipo].FORA_DO_PRAZO || 0, 
                grafico[tipo].RESPONDIDO_FORA_DO_PRAZO || 0, 
                grafico[tipo].INDEFERIDO || 0
            ])
          ]}

          options={{
            title: "Gráfico da Ouvidoria",
            titleTextStyle: {
              color: '000000',
              fontSize: 20,
             
            },
            is3D: true,
            chartArea: { width: '70%' },
            legend: { position: 'bottom' },
            backgroundColor: 'none',
            colors: ['#6699FF', '#C72310', '#0af95e', '#666b67', '#Ffa800'],
            
          }}
        />
      </div>
  
  );
}

export default  Graficos;