import { React, useEffect,useState } from "react";
import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/header";
import carregando from '../../components/Geral/loading.gif';

import './Pesquisa.css'
import FramePopUp from './FramePopUp'
import AccordionComponet from "../../components/Accordion/accordion";

import {base_url, midia_url, error_default, foto_default} from '../../environment'
import { useParams } from "react-router-dom";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styled from "@emotion/styled";
var CSSPadrao = styled.div`${localStorage.getItem("cssGeral")}`

var optionClicado = "background-color: var(--mainColor); color: var(--textColor2);"
var linkAcaoPadrao = "_blank", pontoVez=true;

function Secretaria(){

    //Retira argumentos de páginas. Ex: pesquisando na pag noticia especifica
    // eslint-disable-next-line
    if(window.location.search != "")
    {
        window.location.search = "";
    }   
        
    const {pesquisa} = useParams();
    if(pesquisa == null){window.location = "/*";}

    function loading() {
        return <div id='loading'><img src={carregando} alt="Carregando"/></div>;
    }  

    const [isOpen, setIsOpen] = useState(false);
    const [videoUrl, setvideoUrl] = useState('');    
    const [question, setPergunta] = useState([]);    
    const [tables, setTables] = useState();

    function toggleModal(id){
        //console.log(id);
        setvideoUrl(id);
        //console.log(videoUrl);
        //console.log("isOpen antes:", isOpen);
        setIsOpen(!isOpen);
        //console.log("isOpen depois:", isOpen);
    };

    // GERAL
    useEffect(() => {

        if(pontoVez)
        {
            fetch(`${base_url}/public/busca?busca=${pesquisa}`)
            .then((d) => d.json())
            .then(dados => {   

                var origemNoticia;
                var contadorOptions = 0;
            
                // pegar dados das noticias 
                // eslint-disable-next-line
                if(dados.Noticia != "")
                {
                    var noticia = [];
                    for(let i=0; i<=dados.Noticia.length; i++){

                        if(dados.Noticia[i] == null){break;}                                
                        origemNoticia = window.location.origin+"/noticia?slug="+dados.Noticia[i].slug;
                        
                        noticia.push(
                            // eslint-disable-next-line
                            String('<a target='+linkAcaoPadrao+ ' href='+origemNoticia+'><button><img src='+midia_url+dados.Noticia[i].capa+' alt="Noticia"/><p>'+dados.Noticia[i].titulo+'</p><p> Notícia </p></button></a>')
                        );
                    }                  
                    document.getElementById("pesquisaNoticias").innerHTML = noticia;
                    document.getElementById(1).removeAttribute('hidden');
                    contadorOptions++;
                    // eslint-disable-next-line
                    if(contadorOptions == 1)document.getElementById(1).click();  
                }            
                
                // pegar dados da galeria
                // eslint-disable-next-line
                if(dados.Galeria != "")
                {
                    var galeria = [];
                    for(let i=0; i<=dados.Galeria.length; i++){

                        if(dados.Galeria[i] == null){break;}                                
                        origemNoticia = window.location.origin+"/galeria/"+dados.Galeria[i].slug;
                        
                        galeria.push(
                            // eslint-disable-next-line
                            String('<a target='+linkAcaoPadrao+ ' href='+origemNoticia+'><button><img src='+midia_url+dados.Galeria[i].capa+' alt="Galeria"/><p>'+dados.Galeria[i].titulo+'</p><p> Galeria </p></button></a>')
                        );
                    }                  
                    document.getElementById("pesquisaGaleria").innerHTML = galeria; 
                    document.getElementById(2).removeAttribute('hidden');
                    contadorOptions++;
                    // eslint-disable-next-line
                    if(contadorOptions == 1)document.getElementById(2).click();  
                }

                // pegar dados do video
                // eslint-disable-next-line
                if(dados.Video != "")
                {
                    console.log(dados.Video);
                    //var videos = [];
                    for(let i=0; i<=dados.Video.length; i++){

                        var data = new Date(dados.Video[0].data).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).toString();
                        var titulo = dados.Video[0].titulo.length < 20 ? titulo = dados.Video[0].titulo+'<br></br>'  : titulo = dados.Video[0].titulo.substring(0,40);
                    
                        const btn = document.createElement("button");
                        btn.id = i;

                        const p1 = document.createElement("p");
                        p1.innerHTML = "Título do vídeo: "+titulo;

                        const p2 = document.createElement("p");
                        p2.innerHTML = "Data: "+data;

                        const p3 = document.createElement("p");
                        p3.innerHTML = "- "; //tipo

                        const a = document.createElement("a");
                        a.innerHTML = "Ver vídeo";
                        a.addEventListener("click", (e) => toggleModal(dados.Video[0].link));     

                        btn.appendChild(p1);
                        btn.appendChild(p2);
                        btn.appendChild(p3);
                        btn.appendChild(a);
                                        
                        document.getElementById("pesquisaVideos").appendChild(btn);
                        
                    }                  
                    //document.getElementById("pesquisaVideos").innerHTML = videos; 
                    document.getElementById(3).removeAttribute('hidden');
                    contadorOptions++;
                    // eslint-disable-next-line
                    if(contadorOptions == 1)document.getElementById(3).click();   
                }

                // pegar dados do contato util
                // eslint-disable-next-line
                if(dados.ContatoUtil != "")
                {
                    setTables(dados.ContatoUtil)
                    document.getElementById(4).removeAttribute('hidden');
                    document.getElementById("pesquisaContatos").removeAttribute('hidden');        
                    contadorOptions++;      
                    document.getElementById("pesquisaContatos").setAttribute('hidden', '');
                    // eslint-disable-next-line
                    if(contadorOptions == 1)document.getElementById(4).click();        
                }   
                
                // pegar dados das perguntas
                // eslint-disable-next-line
                if(dados.PerguntaFrequente != "")
                {
                    setPergunta(dados.PerguntaFrequente);
                    //console.log(dados.PerguntaFrequente);
                    document.getElementById(5).removeAttribute('hidden');
                    contadorOptions++;
                    // eslint-disable-next-line
                    if(contadorOptions == 1)document.getElementById(5).click();  
                }                
                   
                // pegar dados do concurso
                // eslint-disable-next-line
                if(dados.Concurso != "")
                {
                    var concurso = [];
                    
                    for(let i=0; i<=dados.Concurso.length; i++){
                        
                        if(dados.Concurso[i] == null){break;}                                
                        origemNoticia = window.location.origin+"/concurso/"+dados.Concurso[i].slug;
                        var dataAbertura = new Date(dados.Concurso[i].data_inicio).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).toString();
                                    
                        concurso.push(
                            // eslint-disable-next-line
                            String('<button><p>'+dados.Concurso[i].titulo+'</p><p>Data de abertura: </p><p>'+dataAbertura+'</p><a target="_blank" href=concurso/'+dados.Concurso[i].slug+'>Saiba mais</a></button>')
                        );
                    }                  
                    document.getElementById("pesquisaConcurso").innerHTML = concurso; 
                    document.getElementById(6).removeAttribute('hidden');
                    contadorOptions++;
                    // eslint-disable-next-line
                    if(contadorOptions == 1)document.getElementById(6).click();  
                }

                // pegar dados dos pontos turisticos
                // eslint-disable-next-line
                if(dados.PontoTuristico != "")
                {
                    var ptTuristico = [];
                    for(let i=0; i<=dados.PontoTuristico.length; i++){

                        if(dados.PontoTuristico[i] == null){break;}                                
                        origemNoticia = window.location.origin+"/ponto-turistico?slug="+dados.PontoTuristico[i].slug;
                        
                        ptTuristico.push(
                            // eslint-disable-next-line
                            String('<a target='+linkAcaoPadrao+ ' href='+origemNoticia+'><button><img src='+midia_url+dados.PontoTuristico[i].capa+' alt="Ponto Turistico"/><p>'+dados.PontoTuristico[i].titulo+'</p><p> '+dados.PontoTuristico[i].Categoria.nome+' </p></button></a>')
                        );
                    }                  
                    document.getElementById("pesquisaPontos").innerHTML = ptTuristico; 
                    document.getElementById(7).removeAttribute('hidden');
                    contadorOptions++;
                    // eslint-disable-next-line
                    if(contadorOptions == 1)document.getElementById(7).click();  
                }

                // pegar dados do link
                // eslint-disable-next-line
                if(dados.Link != "")
                {
                    var link = [], urlFinal;
                    for(let i=0; i<=dados.Link.length; i++){

                        if(dados.Link[i] == null){break;}    

                        // eslint-disable-next-line
                        if(dados.Link[i].tipo == "EXTERNO"){
                            urlFinal = dados.Link[i].url;     
                            linkAcaoPadrao = "_blank";                       
                        }
                        // eslint-disable-next-line
                        else if(dados.Link[i].tipo == "INTERNO"){
                            urlFinal = dados.Link[i].slug;     
                            linkAcaoPadrao = "_self";                       
                        }
                                       
                        link.push(
                            String('<a target='+linkAcaoPadrao+ ' href='+urlFinal+'><button><img src='+dados.Link[i].Icone.imagem+' alt="Logo da opção selecionada"/> <br>'+dados.Link[i].titulo+' </button></a>')
                        );
                    }                  
                    document.getElementById("pesquisaLinks").innerHTML = link; 
                    document.getElementById(8).removeAttribute('hidden');
                    contadorOptions++;
                    // eslint-disable-next-line
                    if(contadorOptions == 1)document.getElementById(8).click();  
                }

                // pegar dados das pessoas
                // eslint-disable-next-line
                if(dados.Pessoa != "")
                {
                    var pessoas = [];
                    for(let i=0; i<=dados.Pessoa.length; i++){

                        if(dados.Pessoa[i] == null){break;}                                
                        origemNoticia = window.location.origin+"/pessoas/"+dados.Pessoa[i].slug;

                        var imagem;
                        if(dados.Pessoa[i].foto == null){
                            imagem = foto_default;}
                        else
                        {imagem = midia_url+dados.Pessoa[i].foto;}
                        
                        pessoas.push(
                            // eslint-disable-next-line
                            String('<a target='+linkAcaoPadrao+ ' href='+origemNoticia+'><button type="button" ><img src='+imagem+' alt="Pessoa"/><p>'+dados.Pessoa[i].nome+'</p><p>'+dados.Pessoa[i].Cargo.titulo+'</p></button></a>')
                        );
                    }                  
                    document.getElementById("pesquisaPessoas").innerHTML = pessoas; 
                    document.getElementById(9).removeAttribute('hidden');
                    contadorOptions++;
                    // eslint-disable-next-line
                    if(contadorOptions == 1)document.getElementById(9).click();  
                }

                // pegar dados dos orgaos
                // eslint-disable-next-line
                if(dados.Orgao != "")
                {
                    var orgao = [];
                    for(let i=0; i<=dados.Orgao.length; i++){

                        if(dados.Orgao[i] == null){break;}                                
                        origemNoticia = window.location.origin+"/orgao/"+dados.Orgao[i].slug;

                        var imgSource;
                        if(dados.Orgao[i].logo == null){
                            imgSource = error_default;}
                        else{
                            imgSource = midia_url+dados.Orgao[i].logo;
                        }

                        var siglaG = (dados.Orgao[i].sigla || "(Não informado)");
                        
                        orgao.push(
                            // eslint-disable-next-line
                            String('<a target='+linkAcaoPadrao+ ' href='+origemNoticia+'><button><img src='+imgSource+' alt="Órgão"/><p>'+dados.Orgao[i].nome+'</p><p> '+siglaG+' </p></button></a>')
                        );
                    }                  
                    document.getElementById("pesquisaOrgao").innerHTML = orgao; 
                    document.getElementById(10).removeAttribute('hidden');
                    contadorOptions++;
                    // eslint-disable-next-line
                    if(contadorOptions == 1)document.getElementById(10).click();  
                }

                // se não tiver nada
                // eslint-disable-next-line
                if(contadorOptions == 0)
                {
                    document.getElementById("pesquisaNoticias").innerHTML = String(`<button><img src="${error_default}" alt="Noticia"><p></p><p>Não há dados.</p></button>`);
                    document.getElementById(1).innerHTML = "Geral";
                    document.getElementById(1).removeAttribute('hidden');
                    // clicar no inicial quando terminar de carregar TUDO
                    document.getElementById(1).click();   
                }
            });  

            pontoVez=false;
        }             
    });    

    function menuSec(id) {
        for(let i=1; i<=10; i++){
            document.getElementById(i).style = null;
            //document.querySelector(`#dados div:nth-child(${i})`).setAttribute('hidden', '');
        }
        document.getElementById(id).style = optionClicado;
        //document.querySelector(`#dados div:nth-child(${id})`).removeAttribute('hidden');
        
        switch (id) {
        case '1':
            document.getElementById("pesquisaNoticias").removeAttribute('hidden');
            document.getElementById("pesquisaGaleria").setAttribute('hidden', '');
            document.getElementById("pesquisaConcurso").setAttribute('hidden', '');
            document.getElementById("pesquisaPontos").setAttribute('hidden', '');
            document.getElementById("pesquisaPessoas").setAttribute('hidden', '');
            document.getElementById("pesquisaVideos").setAttribute('hidden', '');
            document.getElementById("pesquisaOrgao").setAttribute('hidden', '');
            document.getElementById("pesquisaLinks").setAttribute('hidden', '');
            document.getElementById("pesquisaPerguntas").setAttribute('hidden', '');
            document.getElementById("pesquisaContatos").setAttribute('hidden', '');
            break;
        case '2':
            document.getElementById("pesquisaGaleria").removeAttribute('hidden');
            document.getElementById("pesquisaNoticias").setAttribute('hidden', '');
            document.getElementById("pesquisaConcurso").setAttribute('hidden', '');
            document.getElementById("pesquisaPontos").setAttribute('hidden', '');
            document.getElementById("pesquisaPessoas").setAttribute('hidden', '');
            document.getElementById("pesquisaVideos").setAttribute('hidden', '');
            document.getElementById("pesquisaOrgao").setAttribute('hidden', '');
            document.getElementById("pesquisaLinks").setAttribute('hidden', '');
            document.getElementById("pesquisaPerguntas").setAttribute('hidden', '');
            document.getElementById("pesquisaContatos").setAttribute('hidden', '');
            break;
        case '3':
            document.getElementById("pesquisaVideos").removeAttribute('hidden');
            document.getElementById("pesquisaPessoas").setAttribute('hidden', '');
            document.getElementById("pesquisaNoticias").setAttribute('hidden', '');
            document.getElementById("pesquisaGaleria").setAttribute('hidden', '');
            document.getElementById("pesquisaConcurso").setAttribute('hidden', '');
            document.getElementById("pesquisaPontos").setAttribute('hidden', '');
            document.getElementById("pesquisaOrgao").setAttribute('hidden', '');
            document.getElementById("pesquisaLinks").setAttribute('hidden', '');
            document.getElementById("pesquisaPerguntas").setAttribute('hidden', '');
            document.getElementById("pesquisaContatos").setAttribute('hidden', '');
            break;
        case '4':
            document.getElementById("pesquisaContatos").removeAttribute('hidden');
            document.getElementById("pesquisaPessoas").setAttribute('hidden', '');
            document.getElementById("pesquisaNoticias").setAttribute('hidden', '');
            document.getElementById("pesquisaGaleria").setAttribute('hidden', '');
            document.getElementById("pesquisaConcurso").setAttribute('hidden', '');
            document.getElementById("pesquisaPontos").setAttribute('hidden', '');
            document.getElementById("pesquisaOrgao").setAttribute('hidden', '');
            document.getElementById("pesquisaLinks").setAttribute('hidden', '');
            document.getElementById("pesquisaPerguntas").setAttribute('hidden', '');
            document.getElementById("pesquisaVideos").setAttribute('hidden', '');
            break;
        case '5':
            document.getElementById("pesquisaPerguntas").removeAttribute('hidden');
            document.getElementById("pesquisaNoticias").setAttribute('hidden', '');
            document.getElementById("pesquisaGaleria").setAttribute('hidden', '');
            document.getElementById("pesquisaPontos").setAttribute('hidden', '');
            document.getElementById("pesquisaPessoas").setAttribute('hidden', '');
            document.getElementById("pesquisaVideos").setAttribute('hidden', '');
            document.getElementById("pesquisaOrgao").setAttribute('hidden', '');
            document.getElementById("pesquisaLinks").setAttribute('hidden', '');
            document.getElementById("pesquisaConcurso").setAttribute('hidden', '');
            document.getElementById("pesquisaContatos").setAttribute('hidden', '');
            break;
        case '6':
            document.getElementById("pesquisaConcurso").removeAttribute('hidden');
            document.getElementById("pesquisaNoticias").setAttribute('hidden', '');
            document.getElementById("pesquisaGaleria").setAttribute('hidden', '');
            document.getElementById("pesquisaPontos").setAttribute('hidden', '');
            document.getElementById("pesquisaPessoas").setAttribute('hidden', '');
            document.getElementById("pesquisaVideos").setAttribute('hidden', '');
            document.getElementById("pesquisaOrgao").setAttribute('hidden', '');
            document.getElementById("pesquisaLinks").setAttribute('hidden', '');
            document.getElementById("pesquisaPerguntas").setAttribute('hidden', '');
            document.getElementById("pesquisaContatos").setAttribute('hidden', '');
            break;
        case '7':
            document.getElementById("pesquisaPontos").removeAttribute('hidden');
            document.getElementById("pesquisaNoticias").setAttribute('hidden', '');
            document.getElementById("pesquisaGaleria").setAttribute('hidden', '');
            document.getElementById("pesquisaConcurso").setAttribute('hidden', '');
            document.getElementById("pesquisaPessoas").setAttribute('hidden', '');
            document.getElementById("pesquisaVideos").setAttribute('hidden', '');
            document.getElementById("pesquisaOrgao").setAttribute('hidden', '');
            document.getElementById("pesquisaLinks").setAttribute('hidden', '');
            document.getElementById("pesquisaPerguntas").setAttribute('hidden', '');
            document.getElementById("pesquisaContatos").setAttribute('hidden', '');
            break;
        case '8':
            document.getElementById("pesquisaLinks").removeAttribute('hidden');
            document.getElementById("pesquisaNoticias").setAttribute('hidden', '');
            document.getElementById("pesquisaGaleria").setAttribute('hidden', '');
            document.getElementById("pesquisaConcurso").setAttribute('hidden', '');
            document.getElementById("pesquisaPontos").setAttribute('hidden', '');
            document.getElementById("pesquisaVideos").setAttribute('hidden', '');
            document.getElementById("pesquisaPessoas").setAttribute('hidden', '');
            document.getElementById("pesquisaOrgao").setAttribute('hidden', '');
            document.getElementById("pesquisaPerguntas").setAttribute('hidden', '');
            document.getElementById("pesquisaContatos").setAttribute('hidden', '');
            break;
        case '9':
            document.getElementById("pesquisaPessoas").removeAttribute('hidden');
            document.getElementById("pesquisaNoticias").setAttribute('hidden', '');
            document.getElementById("pesquisaGaleria").setAttribute('hidden', '');
            document.getElementById("pesquisaConcurso").setAttribute('hidden', '');
            document.getElementById("pesquisaPontos").setAttribute('hidden', '');
            document.getElementById("pesquisaVideos").setAttribute('hidden', '');
            document.getElementById("pesquisaOrgao").setAttribute('hidden', '');
            document.getElementById("pesquisaLinks").setAttribute('hidden', '');
            document.getElementById("pesquisaPerguntas").setAttribute('hidden', '');
            document.getElementById("pesquisaContatos").setAttribute('hidden', '');
            break;
        case '10':
            document.getElementById("pesquisaOrgao").removeAttribute('hidden');
            document.getElementById("pesquisaNoticias").setAttribute('hidden', '');
            document.getElementById("pesquisaGaleria").setAttribute('hidden', '');
            document.getElementById("pesquisaConcurso").setAttribute('hidden', '');
            document.getElementById("pesquisaPontos").setAttribute('hidden', '');
            document.getElementById("pesquisaVideos").setAttribute('hidden', '');
            document.getElementById("pesquisaPessoas").setAttribute('hidden', '');
            document.getElementById("pesquisaLinks").setAttribute('hidden', '');            
            document.getElementById("pesquisaPerguntas").setAttribute('hidden', '');
            document.getElementById("pesquisaContatos").setAttribute('hidden', '');            
            break;
        default:
            document.getElementById("pesquisaNoticias").setAttribute('hidden', '');
            document.getElementById("pesquisaGaleria").setAttribute('hidden', '');
            document.getElementById("pesquisaConcurso").setAttribute('hidden', '');
            document.getElementById("pesquisaPontos").setAttribute('hidden', '');
            document.getElementById("pesquisaPessoas").setAttribute('hidden', '');
            document.getElementById("pesquisaVideos").setAttribute('hidden', '');
            document.getElementById("pesquisaOrgao").setAttribute('hidden', '');
            document.getElementById("pesquisaLinks").setAttribute('hidden', '');
            document.getElementById("pesquisaPerguntas").setAttribute('hidden', '');
            document.getElementById("pesquisaContatos").setAttribute('hidden', '');
        }
    }  
     
    return(
        <>   
            <Header />
            <CSSPadrao>
                <div className="pagPesquisa">
                    <li className="menuPesquisa">
                        <button hidden id="1" onClick={(e) => menuSec(e.target.id)}>Notícia</button>
                        <button hidden id="2" onClick={(e) => menuSec(e.target.id)}>Galeria</button>
                        <button hidden id="3" onClick={(e) => menuSec(e.target.id)}>Video</button>
                        <button hidden id="4" onClick={(e) => menuSec(e.target.id)}>Contatos</button>
                        <button hidden id="5" onClick={(e) => menuSec(e.target.id)}>Perguntas</button>
                        <button hidden id="6" onClick={(e) => menuSec(e.target.id)}>Concurso</button>
                        <button hidden id="7" onClick={(e) => menuSec(e.target.id)}>Turismo</button>
                        <button hidden id="8" onClick={(e) => menuSec(e.target.id)}>Link</button>
                        <button hidden id="9" onClick={(e) => menuSec(e.target.id)}>Pessoa</button>
                        <button hidden id="10" onClick={(e) => menuSec(e.target.id)}>Órgão</button>
                    </li>  

                    <div className="dadosPesquisa" >
                        <div className="pesquisaFormatoNoticias" ><div id="pesquisaNoticias">{loading()}</div></div>
                        <div className="pesquisaFormatoNoticias" ><div id="pesquisaGaleria"></div></div>                        
                        <div className="licitacao videoCard" ><div id="pesquisaVideos"></div></div>                        
                        <div className="pesquisaFormatoNoticias" ><div id="pesquisaPontos"></div></div>  
                        <div className="pesquisaLinks"><div id='pesquisaLinks'></div></div> 
                        <div className="pesquisaFormatoNoticias"><div id="pesquisaOrgao"></div></div>
                        <div className="pesquisaPessoas" ><div id="pesquisaPessoas"></div></div>      
                        <div className="pesquisaConcurso" ><div id="pesquisaConcurso"></div></div>   
                        <div id="pesquisaPerguntas">{question.map( itens  => (<AccordionComponet title={itens.pergunta} description={itens.resposta}/>))}</div>
                        <div hidden className="pesquisaContatos" id="pesquisaContatos" >
                            <TableContainer className="modal-content">
                                <Table sx={{minWidth: 650}} arial-label="caption table" className="table">
                                <TableHead>
                                    <TableRow>
                                    <TableCell>Instituição</TableCell>
                                    <TableCell>Telefone</TableCell>
                                    <TableCell>Celular</TableCell>
                                    <TableCell>E-mail</TableCell>
                                    <TableCell>Endereço</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {tables && tables.map(item => (
                                    <TableRow>
                                        <TableCell>{item.nome || '--'}</TableCell>
                                        <TableCell>{item.fone || '--'}</TableCell>
                                        <TableCell>{item.celular || '--'}</TableCell>
                                        <TableCell>{item.email || '--'}</TableCell>
                                        <TableCell>{item.endereco || '--'} sfsfsdfsdfsfsfdf</TableCell>
                                    </TableRow>
                                ))}

                                {tables && tables.map(item => (
                                    <TableRow>
                                        <TableCell>{item.nome}</TableCell>
                                        <TableCell>{item.fone || '--'}</TableCell>
                                        <TableCell>{item.celular}</TableCell>
                                        <TableCell>{item.email}</TableCell>
                                        <TableCell>{item.endereco} sfsfsdfsdfsfsfdf</TableCell>
                                    </TableRow>
                                ))}                    
                                    </TableBody>
                                    </Table>                    
                            </TableContainer>
                        </div>
                        <div className="spacePesquisa"> </div>  
                    </div>

                    {isOpen && (
                        <div className="modal-wrapper">
                        <FramePopUp toggle={toggleModal} state={isOpen} video={videoUrl}/>
                        </div>
                    )}     

                </div>
                </CSSPadrao>
            <Footer/>
        </>   
    );
}

export default Secretaria;