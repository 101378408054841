import React, { useEffect, useState } from 'react';
import {base_url} from '../../environment'
import Header from '../../components/Header/header';
import Footer from '../../components/Footer/footer';
import Graficos from './components/Graficos';
import GraficoPizza from './components/GraficoPizza'

import './style/ouvidoria.css'
import { Link } from 'react-router-dom';
import { formatarNumero } from '../../environment/formatNumber';
import styled from "@emotion/styled";
var CSSPadrao = styled.div`${localStorage.getItem("cssGeral")}`

function Ouvidoria (){

  const [ouvidoria, setOuvidoria] = useState({});
  const [grafico, setGrafico] = useState({})

  useEffect(() => {
    async function fetchData() {
      try {
        const url = await fetch(`${base_url}/public/ouvidoria/relatorio-status`);
        
        const resource = await url.json();

        // eslint-disable-next-line
        if((resource.statusCode != undefined) && (resource.statusCode != 200)){window.location.pathname = `mensagem/${resource.message}`;}
        
        setGrafico(resource)
      
      } catch (error) {
        console.log('requisição não de certo'+error)
      }
    }
    fetchData();
  }, []);

   useEffect(() => {
        async function fetchData() {
          try {
            const url = await fetch(`${base_url}/public/ouvidoria`);
            
            const resource = await url.json();

            // eslint-disable-next-line
            if((resource.statusCode != undefined) && (resource.statusCode != 200)){window.location.pathname = `mensagem/${resource.message}`;}
                
            setOuvidoria(resource)
           
           
          } catch (error) {
            console.log('requisição não de certo')
          }
        }
        fetchData();
      }, []);
    
    return (
        <div className='scrolOuvidoria'>
            <Header />
            <CSSPadrao>
            <h1 className='tituloPagina'>Ouvidoria</h1>

            <div className='ouvidoria-container'>
             
              <div className='ouvidoria-text'>
                  <div className='ouvidoria-info'>
                    <div>
                      <h2>Quando utilizar a Ouvidoria?</h2>
                      <p>Você pode acionar a Ouvidoria após ter sido atendido em qualquer área da Prefeitura de Paraíso do Tocantins - TO ou demais canais de atendimento disponíveis, caso queira se manifestar a respeito do atendimento prestado ou queira fazer umadenúncia.</p>

                      <h2>Como a Ouvidoria atua?</h2>
                      <p>A Ouvidoria atua em três momentos. Primeiramente, recebe, analisa e encaminha as manifestações dos clientes aos setores responsáveis pelo assunto apresentado. Em seguida, acompanha as providências adotadas, cobra soluções e mantém os clientes informados sobre o resultado ou a conclusão de suas Manifestações. Por fim, a Ouvidoria elabora relatórios sobre atendimentos, resultados e avaliações dos clientes, de modo a subsidiar os gestores na tomada de decisões.</p>
                    </div>
                  </div>

                  <div className='ouvidoria-info'>
                    <div>
                      <h2>Informações </h2>
                      <p> <b>Responsável: </b>{ouvidoria.Responsavel?.nome && (ouvidoria.Responsavel?.nome)} </p>
                      <p> <b>E-mail: </b>{ouvidoria.email && (ouvidoria.email)}</p>
                      <p> <b>Horário de funcionamento: </b>{ouvidoria.expediente && (ouvidoria.expediente)}</p>
                      <p> <b>Telefone: </b>{formatarNumero(ouvidoria.celular)} / {formatarNumero(ouvidoria.fone)}</p>
                      <p> <b>Endereço:</b> {ouvidoria.endereco && (ouvidoria.endereco)}</p>

                      <div className='form-ouvidoria-btnManifestação'>
                        <Link to={`/ouvidoria/manifestacao`}>
                        <button>FAZER MANIFESTAÇÃO</button>
                        </Link>
                      </div>  
                    </div> 
                  </div>

              </div>

            </div>

             <div className='ouvidoria-grafico-barra'>
                <Graficos />                
            </div>

            <div className='ouvidoria-grafico-text'>
                    <div id='title-ouvidoria-info'>

                   
                      <div>
                        <h2>Em Andamento</h2>
                        <p>Demonstração de satisfação ou agradecimento por serviço prestado pela Prefeitura de Paraíso do Tocantins - TO.</p>
                      </div>

                      <div>
                        <h2>Fora do Prazo</h2>
                        <p>Demonstração de satisfação ou agradecimento por serviço prestado pela Prefeitura de Paraíso do Tocantins - TO.</p>
                      </div>
                  
                    </div>

                    <div id='title-ouvidoria-info'>
                      <div>
                        <h2>Respondidas</h2>
                        <p>Demonstração de satisfação ou agradecimento por serviço prestado pela Prefeitura de Paraíso do Tocantins - TO.</p>
                      </div>  

                      <div>
                        <h2>Indeferido</h2>
                        <p>Demonstração de satisfação ou agradecimento por serviço prestado pela Prefeitura de Paraíso do Tocantins - TO.</p>
                      </div>
                    </div>

                    <div id='title-ouvidoria-info'>
                      <div>
                        <h2>Respondido fora do prazo</h2>
                        <p>Demonstração de satisfação ou agradecimento por serviço prestado pela Prefeitura de Paraíso do Tocantins - TO.</p>
                      </div>
                    
                    </div>
            </div>
            
            <h1 className='tituloPagina'>Manifestações</h1>

            <div className='ouvidoria-manifestacao-grafico'>
              <GraficoPizza grafico={grafico}/>
            </div>

            <div className='ouvidoria-manifestacao-text'>              

              <div id='title-manifestacao-info'>
                <div>
                  <h2>Indeferido</h2>
                  <p>Demonstração de satisfação ou agradecimento por serviço prestado pela Prefeitura de Paraíso do Tocantins - TO.</p>
                </div>

                <div>
                  <h2>Respondidas</h2>
                  <p>Demonstração de satisfação ou agradecimento por serviço prestado pela Prefeitura de Paraíso do Tocantins - TO.</p>
                </div>
              </div>  

              <div id='title-manifestacao-info'>
                <div>    
                  <h2>Em Tramição</h2>
                  <p>Demonstração de satisfação ou agradecimento por serviço prestado pela Prefeitura de Paraíso do Tocantins - TO.</p>
                </div>

                <div>    
                  <h2>Fora do prazo</h2>
                  <p>Demonstração de satisfação ou agradecimento por serviço prestado pela Prefeitura de Paraíso do Tocantins - TO.</p>
                </div>
              </div>   

            </div>  
            </CSSPadrao>
            <Footer/>
        </div>
    );
}

export default Ouvidoria;