import ImageGallery from 'react-image-gallery';
import './image-gallery-modificada.css';

export default function Galeria(props){
    return (
      <>
    
        <ImageGallery items={props.image} useBrowserFullscreen={false} showThumbnails={false} showFullscreenButton={false}  showPlayButton={false}/> 
        </>
    );
};