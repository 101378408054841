import { useEffect, useState } from "react";
import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/header";
import './Carta.css'
import carregando from '../../components/Geral/loading.gif';
import {base_url, midia_url} from '../../environment'
import styled from "@emotion/styled";
var CSSPadrao = styled.div`${localStorage.getItem("cssGeral")}`

function Pessoa(){

    const [carta, setCarta] = useState('');

    function loading() {
        return <div id='loading'><img src={carregando} alt="Carregando"/></div>;
    }
    // pegar dados dos concursos
    useEffect(() => {
        fetch(`${base_url}/public/carta-servicos`)
        .then((d) => d.json())
        .then(dados => { 

            // eslint-disable-next-line
            if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message}`;}

            // eslint-disable-next-line 
            if(dados.length == 0)
            {
                document.getElementById("aberto").innerHTML = '<button><p>Não há concursos abertos.</p><p></p><p></p></button>';
                    
            }
            else
            {
                setCarta(dados.carta_servicos);
            }            
        });  
    }); 

    return(
        <>   
            <Header />
            <CSSPadrao>
                <div className="concurso">
                    <div className="tituloPagina"> 
                        <p id="titu" >Carta de Serviços</p>
                    </div> 

                    <div className="iframeCarta">                  
                        <iframe
                        src={midia_url+"/"+carta}
                        width="100%"
                        height="600px"
                        style={{ border: 'none' }}
                        />
                    </div>  
                                      
                </div>
            </CSSPadrao>
            <Footer/>
        </>   
    );
}

export default Pessoa;