import Header from "../../components/Header/header";
import Footer from "../../components/Footer/footer";
import Card from "./Card";
import styled from "@emotion/styled";
var CSSPadrao = styled.div`${localStorage.getItem("cssGeral")}`

function FaleConosco(){
   


    return (
        <div className="container-faleConosco"> 
            <Header/>
            <CSSPadrao>
                <div>
                    <Card/>                 
                </div>
            </CSSPadrao>
            <Footer />
        </div> 
    );

}

export default FaleConosco;