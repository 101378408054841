import React, { useState, useEffect } from "react";
import Headers from '../../components/Header/header'
import Footer from '../../components/Footer/footer'
import Entidades from "./components/Entidades";

import Ouvidoria from "./components/Ouvidoria"

import { Tree, TreeNode } from "react-organizational-chart";
import {StyledNode,Unidades,Secretarias} from './style'
import './style.css'

import { AiFillMail,AiOutlineUser } from "react-icons/ai";
import {FaUserEdit} from "react-icons/fa";
import { BsFillPersonFill, BsTelephoneFill } from "react-icons/bs";
import {base_url} from '../../environment'
import { Link } from "react-router-dom";
import Esic from "./components/Esic";

import { formatarNumero } from "../../environment/formatNumber";
import styled from "@emotion/styled";
var CSSPadrao = styled.div`${localStorage.getItem("cssGeral")}`

function createTreeNode(entidades, posicao = 0){
  const entidade = entidades[posicao]
  console.log(entidade)
  
  if (posicao === entidades.length) return;
  

  return <TreeNode className="entity" label={
        <Unidades  >
             {entidade.nome && (
                  <h1>{entidade.nome}</h1>
              )}

              {entidade.Responsavel && (
                  <p><BsFillPersonFill /> {entidade.Responsavel}</p>
              )}
               {entidade.celular && (
                  <p><BsTelephoneFill /> {formatarNumero(entidade.celular)}</p>
              )}
               {entidade.email && (
                  <p><AiFillMail /> {entidade.email}</p>
              )}
             
        </Unidades>}>
          {createTreeNode(entidades, posicao + 1)}
        </TreeNode>
}

function Organograma()  {
  const [organograma , setOrganograma] = useState('')
  const [esic, setEsic] = useState('');
  const [ouvidoria, setOuvidoria] = useState('');
  
  useEffect(() => {
    

    fetch(`${base_url}/public/organograma`)
    .then(resource => resource.json())
    .then( data => {
        console.log(data)
        setOrganograma(data)
        setEsic(data.Esic)
        setOuvidoria(data.Ouvidoria)
    })
  },[])
  
    return(
      <div >
        <Headers/>
        <CSSPadrao>
          <div className="tituloPagina"> 
              <p id="titu" >Organograma</p>
          </div>  
          <div className="main-organograma">
                
            <div className="container-organograma">

            <Tree   lineWidth={'2px'}   label={
              <div className="ajustarPrefeito">
              <StyledNode>
                <h1>{organograma.nome}</h1>
                <Link to={`/pessoas/${organograma.Prefeito?.slug}`}>
                  <p><FaUserEdit/> {organograma.Prefeito?.nome}</p>
                </Link>

                <Link to={`/pessoas/${organograma.VicePrefeito?.slug}`}>
                 <p> <AiOutlineUser/>{ organograma.VicePrefeito?.nome}</p>
                </Link>
              </StyledNode>
              </div>
              
            }>

              {organograma.orgaos?.map( itensOrg => (
                
                <TreeNode   label={
                  <div>
                  <Secretarias >
                    <Link to={`/orgao/${itensOrg.slug}`}>
                      <h1 >{itensOrg.nome}</h1>
                    </Link> 
                          {itensOrg.Responsavel?.nome && (
                            <Link to={`/pessoas/${itensOrg.Responsavel?.slug}`}>
                            <p> <BsFillPersonFill/>{itensOrg.Responsavel?.nome}</p>
                            </Link>
                          )}
                          {itensOrg.email && (
                            <p>
                              <AiFillMail /> {itensOrg.email}
                            </p>
                          )}
                          {itensOrg.celular && (
                            <p>
                              <BsTelephoneFill /> {formatarNumero(itensOrg.celular)}
                            </p>
                          )}
                  </Secretarias>   

             
                  </div>
                  
                }>
                  {/*{createTreeNode(itensOrg.entidades) } */} 
                  
                </TreeNode>
              ))}


      {/*
          {organograma.entidades?.map( itens1 => (        
              <Entidades nome={itens1.nome} resposanve={itens1.Responsavel} celular={itens1.celular} email={itens1.email}/>
              
          ))}
      */}

       
       {
        <Esic nome={esic.Responsavel} email={esic.email} celular={esic.celular && (<p><BsTelephoneFill /> {formatarNumero(esic.celular)}</p>)}/>
       }

      {
        <Ouvidoria nome={ouvidoria.Responsavel} email={ouvidoria.email} celular={ouvidoria.celular && (<p><BsTelephoneFill /> {formatarNumero(ouvidoria.celular)}</p>)}/>
       }      
        </Tree>
        
            </div>
         </div>
         </CSSPadrao>
        <Footer/>
   </div>
    );
};

export default Organograma;
