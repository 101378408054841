import { useEffect, useState } from "react";
import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/header";
import Carrousel from  '../../components/Galeria/Galeria'
import carregando from '../../components/Geral/loading.gif';
import '../obra/Obra.css'
import {base_url, foto_default, midia_url,error_default} from '../../environment'
import Skeleton from "@mui/material/Skeleton";
import axios from 'axios';
import styled from "@emotion/styled";
var CSSPadrao = styled.div`${localStorage.getItem("cssGeral")}`

var url = base_url+'/public/prefeitura/obras';
var caminhoGlobal = midia_url;
var ptEnderecoLink;

const images = [{original: carregando,},];
var umaVez = true, unaVez=true, pinaVez=true, status, umavez = true;

var optionClicado = "background-color: var(--mainColor); color: var(--textColor2);"

function Noticia(){
    
    const search = window.location.search;
    const params = new URLSearchParams(search);

    const [principalArray, setprincipalArray] = useState([]);
    const [galeria, setgaleria] = useState('');

    const slug = params.get('slug');
    // eslint-disable-next-line
    if(slug == null){window.location = "/*";}

    var retorno = localStorage.getItem("statusSelecionada");    

    //console.log(retorno);

    //fltro das relacionadas lateral
    // eslint-disable-next-line
    if((retorno != 'null') && (retorno != 'undefined')  && (retorno != null)){

        url = base_url+"/public/prefeitura/obras?status="+retorno;
    }
    else
    {
        url = base_url+"/public/prefeitura/obras";
    }

    var urlEspecifica = base_url+'/public/prefeitura/obras/' + slug;
    
    function loading() {
        return <div id='loading'><img src={carregando} alt="Carregando"/></div>;
    }

    // pegar dados da obra principal
    useEffect(() => {

        if(umaVez)
            {
            fetch(urlEspecifica)
            .then((d) => d.json())
            .then(dados => {      
                //console.log(dados.length); 
                // eslint-disable-next-line
                if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message}`;}
            
                document.getElementById("ntEspTitulo").innerHTML = dados.titulo;      

                setgaleria("galeria/"+dados.Galeria.slug);

                images.pop();            
                images.push({original: caminhoGlobal+dados.capa,},);
                images.push({original: caminhoGlobal+dados.capa,},);

                
                setprincipalArray(dados);   
                ptEnderecoLink = dados.link_maps || 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31791811.201448027!2d-73.06340218699069!3d-13.422456123522103!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9c59c7ebcc28cf%3A0x295a1506f2293e63!2sBrasil!5e0!3m2!1spt-BR!2sbr!4v1684358272973!5m2!1spt-BR!2sbr';

                //pegar mensagens
                if(umavez){
                    document.getElementById("obraEtapas").innerHTML = null;   
                    for(let i=0; i<dados.Etapa.length; i++){
                                            
                        var divGeral = document.getElementById("obraEtapas");
                        
                        const divContainter = document.createElement("div");
                        
                        const conteudo = document.createElement("p");
                        if(dados.Etapa[i].descricao != null) 
                        {
                            conteudo.innerHTML = 'Descrição: ' + dados.Etapa[i].descricao;
                        }
                        else
                        {
                            conteudo.innerHTML = 'Descrição: Sem descrição.';
                        }  
                            
                        const anexos = document.createElement("p");
                        anexos.innerHTML = 'Anexos:';
                            
                        divContainter.appendChild(conteudo); 
                        divContainter.appendChild(anexos); 

                        for(let y=0; y<dados.Etapa[i].Imagem.length; y++){

                            const a = document.createElement("a");
                            //ou button?
                            //a.innerHTML = String(dados.Etapa[i].Imagem[y].imagem);
                            a.target = "_blank";
                            a.href = midia_url+dados.Etapa[i].Imagem[y].imagem;


                            const p = document.createElement("p");
                            p.innerHTML = String(dados.Etapa[i].Imagem[y].imagem).substring(49,);
                            a.appendChild(p); 
                            
                            divContainter.appendChild(a); 
                        }
                            
                        divGeral.appendChild(divContainter);
                        
                    };
                    umavez = false;
                } 

                //anexos
                var arrayInicial = [];    
                for(let i=0; i<dados.Anexo.length; i++){
               
                    arrayInicial.push(
                        // eslint-disable-next-line
                        String('<p>'+dados.Anexo[i].titulo+'<a target="_blank" href='+midia_url+dados.Anexo[i].anexo+'><button>Baixar (Download)</button></a></p>')
                    );  
                }
    
                //console.log(arrayInicial);  
                document.getElementById("arquivoAnexos").innerHTML = arrayInicial;   
                
            });

            umaVez = false;
        }

        document.getElementById(1).click();    
    });

    // pegar dados das obras relacionadas
    useEffect(() => {

        if(unaVez)
        {

            fetch(url)
            .then((d) => d.json())
            .then(dados => {      
                //console.log(dados.length); 
                // eslint-disable-next-line
                                
                if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message}`;}
            
                var arrayInicial = [];
                
                for(let i=0; i<3; i++){
    
                    if(dados[i] == null){break;}

                    var origemNoticia = window.location.origin+"/obra?slug="+dados[i].slug;

                    var imgSource;
                    if(dados[i].capa == null){
                        imgSource = error_default;}
                    else{
                        imgSource = midia_url+dados[i].capa;
                    }

                    let data = new Date(dados[i].data_inicio).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).toString();

                    arrayInicial.push(
                        // eslint-disable-next-line
                        String('<a target="_self" href='+origemNoticia+'><button><img src='+imgSource+' alt="Obra"/><p>'+dados[i].titulo+' <br>Início: '+data+'</p><p>Progresso: '+dados[i].progresso+'% </p></button></a>')
                    );                      
                }
                
                console.log(retorno);
                
                if(retorno == 'null')
                {
                    arrayInicial.push(
                        // eslint-disable-next-line
                        String('<a target="_self" href="obras" rel="noreferrer" id="maisPontos" ><button>Mais pontos</button></a>')
                    );  
                }
                else
                {
                    arrayInicial.push(
                        // eslint-disable-next-line
                        String('<a target="_self" href="obras?status='+retorno+'" rel="noreferrer" id="maisPontos" ><button>Mais pontos</button></a>')
                    );  
                }
    
                document.getElementById("inicial").innerHTML = arrayInicial;      


                // pegar info da secretaria pro botao 
                var filtrou;
                switch (retorno) {
                case 'lancada':
                    filtrou = "Lançada";
                    break;
                case 'em_execucao':
                    filtrou = "Em execução";
                    break;
                case 'executada':
                    filtrou = "Executada";
                    break;
                default:
                    filtrou = "Geral";
                }

                document.getElementById("selecionada").innerHTML = 'Filtro: '+filtrou+' &nbsp;&nbsp; &#10006';                
                
            });
 
            unaVez=false;
        }
    });   

    function menuSec(id) {
        for(let i=1; i<=2; i++){
            document.getElementById(i).style = null;
        }
        document.getElementById(id).style = optionClicado;       
        //console.log("Selecionou o botão " + id);

        // eslint-disable-next-line
        if(id != 1)
        {
            document.getElementById("etapas").setAttribute('hidden', '');
        }
        else
        {
            document.getElementById("etapas").removeAttribute('hidden');
            document.getElementById("anexos").setAttribute('hidden', '');
        }

        // eslint-disable-next-line
        if(id != 2){
            document.getElementById("anexos").setAttribute('hidden', '');
            //document.getElementById("anexos").style = "display: none;";
        }
        else{
            document.getElementById("anexos").removeAttribute('hidden');
            document.getElementById("etapas").setAttribute('hidden', '');
            //document.getElementById("anexos").style = "display: flex;";
        }

        // eslint-disable-next-line
        //if(id == 8){window.location = "obras";}
        // eslint-disable-next-line
        //if(id == 7){window.location = "galeria";}
    }  
    

    return(
        <>   
            <Header />
            <CSSPadrao>
            <a href="obras" target="_self"></a>
                <div>
                    <p className="ntEspTitulo" id="ntEspTitulo"></p>
                    <a href="obras" target="_self"><button className="selecionada" id="selecionada" > Geral    ✖</button></a>
                </div>
                
                <div className="obraEspecifica">
                    <div className="obraEspecificaInfo " id="secretariasInfo">
                        <div id='destaque' className='destaque'> 
                            
                            <Carrousel image={images}/>

                            <div class="ptTurEspecificoDados">
                                <a>
                                    <button><span></span>
                                        <span>Valor total da obra: R$ {principalArray.valor_total}</span>
                                    </button>
                                </a>

                                <a target="_self" href="malito:parquecesamar@gmail.com" rel="noreferrer">
                                    <button><span></span>
                                        <span>Valor já executado: R$ {principalArray.valor_executado}</span>
                                    </button>
                                </a>
                                
                                <a target="_self" href="malito:parquecesamar@gmail.com" rel="noreferrer">
                                    <button><span></span>
                                        <span>Data de início: {new Date(principalArray.data_inicio).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).toString()}</span>
                                    </button>
                                </a>
                                
                                <a target="_self" href="malito:parquecesamar@gmail.com" rel="noreferrer">
                                    <button><span></span>
                                        <span>Data de conclusão: {new Date(principalArray.data_fim).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).toString()}</span>
                                    </button>
                                </a>

                                <a target="_self" href="malito:parquecesamar@gmail.com" rel="noreferrer">
                                    <button><span></span>
                                        <span>Progresso: {principalArray.progresso}%</span>
                                    </button>
                                </a>

                                <a target="_self" href="malito:parquecesamar@gmail.com" rel="noreferrer">
                                    <button><span></span>
                                        <span>Profissional responsável: {principalArray.profissional_responsavel}</span>
                                    </button>
                                </a>

                                <a target="_self" href="malito:parquecesamar@gmail.com" rel="noreferrer">
                                    <button><span></span>
                                        <span>Fiscal responsável: {principalArray.fiscal_responsavel}</span>
                                    </button>
                                </a>

                                <a target="_self" href="malito:parquecesamar@gmail.com" rel="noreferrer">
                                    <button><span></span>
                                        <span>Empresa responsável: {principalArray.empresa_responsavel}</span>
                                    </button>
                                </a>

                                <a target="_self" href="malito:parquecesamar@gmail.com" rel="noreferrer">
                                    <button><span></span>
                                        <span>Localidade: {principalArray.localidade}</span>
                                    </button>
                                </a>

                                <a target="_self" href="malito:parquecesamar@gmail.com" rel="noreferrer">
                                    <button><span></span>
                                        <span>Prazo (em meses): {principalArray.prazo_meses}</span>
                                    </button>
                                </a>

                                <a target="_self" href="malito:parquecesamar@gmail.com" rel="noreferrer">
                                    <button><span></span>
                                        <span>Região da Obra: {principalArray.regiao}</span>
                                    </button>
                                </a>

                                <a target="_self" href="malito:parquecesamar@gmail.com" rel="noreferrer">
                                    <button><span></span>
                                        <span>Status da Obra: {principalArray.status}</span>
                                    </button>
                                </a>

                            </div>

                            <div class="maps">
                                <iframe src={ptEnderecoLink} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>

                            <p class="textoptTurEspecifico"><p>{principalArray.descricao}</p></p>

                            <div className="menuSecretaria">
                                <li>
                                    <button id="1" onClick={(e) => menuSec(e.target.id)}>Etapas</button>
                                    <button id="2" onClick={(e) => menuSec(e.target.id)}>Anexos</button> 
                                    <a href={galeria} target="_blank"><button id="3" >Galeria</button> </a>
                                    
                                </li>  


                                <div id="etapas">
                                    <div className='form-ouvidoria-margin ObraEtapa'>                                
                                        <div className="cadastroDoc">
                                            <div id="etapas">
                                                <div className="cadastroDocInterno">
                                                    <div id="obraEtapas" className="anexos">
                                                        {loading()}
                                                    </div>   
                                                </div>
                                            </div>                        
                                        </div>
                                    </div>
                                </div>
                                
                                <div id="anexos">
                                    <div className="ObraAnexos lonha" >
                                        <div></div>
                                        <div id="arquivoAnexos">{loading()}</div>           
                                    </div>         
                                </div>
                                
                            </div> 
                        </div>   
                        <div className="obrasEspecificaInfo">
                            <div id='inicial' className='ptTurOutros'>{loading()}</div>
                        </div>                        
                    </div>   
                </div>
                </CSSPadrao>
            <Footer/>
        </>   
    );
}

export default Noticia;