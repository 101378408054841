import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/header";
import Carrousel from  '../../components/Galeria/Galeria'
import carregando from '../../components/Geral/loading.gif';
import './PontoTuristico.css'
import {base_url, midia_url,error_default} from '../../environment'
import { formatarNumero } from '../../environment/formatNumber';

import {TfiMapAlt} from "react-icons/tfi"
import {MdEmail} from "react-icons/md"
import {FaPhoneSquareAlt, FaInstagramSquare} from "react-icons/fa"
import styled from "@emotion/styled";
var CSSPadrao = styled.div`${localStorage.getItem("cssGeral")}`

var url = base_url+'/public/prefeitura/pontos-turisticos';
var urlPtTuristico = base_url+'/public/prefeitura/categorias-ponto-turistico';
var linkAcaoPadrao = "_self", ptEnderecoLink;

const images = [{original: carregando,},];
var umaVez = true, unaVez = true, categoria_id;
var secretarias= [];

function Noticia(){

    const [ptEmail, setptEmail] = useState('');
    const [ptSite, setptSite] = useState('');
    const [ptFone, setptFone] = useState('');
    const [ptEndereco, setptEndereco] = useState('');

    const [ptEmailLink, setptEmailLink] = useState('');
    const [ptSiteLink, setptSiteLink] = useState('');
    const [ptFoneLink, setptFoneLink] = useState('');
    
    const search = window.location.search;
    const params = new URLSearchParams(search);

    const slug = params.get('slug');
    // eslint-disable-next-line
    if(slug == null){window.location = "/*";}

    categoria_id = localStorage.getItem("ptTurSelecionada");  

    //fltro das relacionadas lateral
    // eslint-disable-next-line
    if((categoria_id != 'null') && (categoria_id != 'undefined') && (categoria_id != null)){

        if(unaVez)
        {
            url = url+'?categoria_id='+categoria_id;
            //console.log('url é '+url); 
            unaVez = false;
        } 
    }    
    //se digitar na url sem categoria_id, não vai trazer relacionadas
    //categoria_id = params.get('categoria_id'); 
    //categoria_id = null; 

    // pegar info da secretaria pro botao
    useEffect(() => {
        fetch(urlPtTuristico)
        .then((d) => d.json())
        .then(dados => {      
            //console.log(dados.length); 

            // eslint-disable-next-line
            if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message}`;}
            
            var qtdSecretarias = dados.length;
                      
            //console.log(categoria_id); 
            // eslint-disable-next-line
            if((categoria_id != 'null') && (categoria_id != 'undefined')){
                
                for(let i=0; i<qtdSecretarias; i++){
                    // eslint-disable-next-line
                    if(dados[i].id == categoria_id)
                    {
                        //qualSecretaria = dados[i].nome.substring(13);
                        //console.log(dados[i].nome.substring(13)); 
                        document.getElementById("ptTurSelecionado").innerHTML = dados[i].nome+' &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &#10006';                
                        
                    }                
                }
            }
            else
            {
                document.getElementById("ptTurSelecionado").innerHTML = 'Geral  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &#10006';    
            }      
            
            for(let i=0; i<qtdSecretarias; i++){

                //guarda dados das secretarias
                secretarias.push(dados[i]);
            }

            //console.log(secretarias); 

        });
    });

    var urlEspecifica = base_url+'/public/prefeitura/ponto-turistico/' + slug;
    
    function loading() {
        return <div id='loading'><img src={carregando} alt="Carregando"/></div>;
    }
   
    // pegar dados da noticia principal
    useEffect(() => {
        fetch(urlEspecifica)
        .then((d) => d.json())
        .then(dados => {      
            //console.log(dados.length); 

            // eslint-disable-next-line
            if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message}`;}
            
            document.getElementById("ptTurTitulo").innerHTML = dados.titulo; 

            var divGeral = document.getElementById("destaque");    
            
            //console.log(dados.Imagem[0]); 
            
            if(dados.Imagem[0] == null)
            {
                images.pop();   
                if(dados.capa != null)
                {
                    images.push({original: midia_url+dados.capa,},);
                    images.push({original: midia_url+dados.capa,},);
                }
                else
                {images.push({original: error_default,},);}
                
            }
            else
            {
                images.pop();            
                for(let i=0; i<dados.Imagem.length; i++){    
                    images.push({original: midia_url+dados.Imagem[i].imagem,},);
                }
            }
            
            const textoptTurEspecifico = document.createElement("p");
            textoptTurEspecifico.innerHTML = dados.conteudo  || "Parece que não há dados aqui. É necessário que o cadastrador da notícia insira a descrição do ponto turístico.";
            textoptTurEspecifico.className = "textoptTurEspecifico";

            setptEndereco((dados.endereco) || "(Não informado)");
            setptEmail((dados.email) || "(Não informado)");
            setptFone((formatarNumero(dados.fone) || "(Não informado)") +"/"+ (formatarNumero(dados.celular) || "(Não informado)"));
            setptSite((dados.site) || "(Não informado)");

            if(dados.email != null)
            {setptEmailLink("malito:"+dados.email);}
            else{setptEmailLink(null);}

            // eslint-disable-next-line
            if(dados.site = 'undefined')
            {setptSiteLink(null);}
            else{setptSiteLink("https://www.instagram.com/"+dados.site);}

            if(dados.fone != null)
            {setptFoneLink("tel:"+dados.fone);}
            else{setptFoneLink(null);}

            ptEnderecoLink = dados.link_maps || 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31791811.201448027!2d-73.06340218699069!3d-13.422456123522103!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9c59c7ebcc28cf%3A0x295a1506f2293e63!2sBrasil!5e0!3m2!1spt-BR!2sbr!4v1684358272973!5m2!1spt-BR!2sbr';

            if(umaVez)
            {         
                divGeral.appendChild(textoptTurEspecifico); 
                umaVez = false;
            }
                          
        });
    });

    // pegar dados das noticias relacionadas
    useEffect(() => {
        fetch(url)
        .then((d) => d.json())
        .then(dados => {      
            //console.log(dados.length); 
            //console.log(dados); 

            // eslint-disable-next-line
            if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message}`;}
            
            var arrayInicial = [];
            var origemNoticia = '#';
            var mostraSecretaria;
            
            for(let i=0; i<3; i++){

                //console.log(dados[i].Orgao);

                if(dados[i] == null)
                {
                    break;
                }

                mostraSecretaria = null;
                
                origemNoticia = "ponto-turistico?slug="+dados[i].slug;

                mostraSecretaria = dados[i].Categoria.nome;

                arrayInicial.push(
                    // eslint-disable-next-line
                    String('<a target='+linkAcaoPadrao+ ' href='+origemNoticia+
                    ' rel="noreferrer"><button><img src='+midia_url+dados[i].capa+
                    ' alt="Ponto-Turistico"/><p>'+dados[i].titulo+
                    '</p><p>'+mostraSecretaria+'</p></button></a>')
                );  
                
            }
            
            if(categoria_id == 'null')
            {
                arrayInicial.push(
                    // eslint-disable-next-line
                    String('<a target='+linkAcaoPadrao+' href="/pontos-turisticos" rel="noreferrer" id="maisPontos" ><button>Mais pontos</button></a>')
                );  
            }
            else
            {
                arrayInicial.push(
                    // eslint-disable-next-line
                    String('<a target='+linkAcaoPadrao+' href=pontos-turisticos?categoria_id='+categoria_id+' rel="noreferrer" id="maisPontos" ><button>Mais pontos</button></a>')
                );  
            }
            

            document.getElementById("inicial").innerHTML = arrayInicial;           
        });
    });   
    

    return(
        <>   
            <Header />
            <CSSPadrao>
                <div className="ponto-turistico">
                    <p className="ptTurTitulo" id="ptTurTitulo"></p>
                    <a href="pontos-turisticos" target="_self"><button className="selecionada" id="ptTurSelecionado" >Carregando...  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &#10006;</button></a>
                    
                    <div className="ptTurEspecifico">
                        <div className="ptTurEspecificoInfo">
                            <div id='destaque' className='destaque'>
                                <Carrousel image={images}/>                               
                                <div className="ptTurEspecificoDados">
                                    <a >
                                        <button>
                                            <span><TfiMapAlt/></span><span>{ptEndereco}</span>
                                        </button>
                                    </a>
                                    <a target="_self" href={ptEmailLink} rel="noreferrer">
                                        <button>
                                            <span><MdEmail/></span><span>{ptEmail}</span>
                                        </button>
                                    </a>
                                    <a target="_self" href={ptFoneLink} rel="noreferrer">
                                        <button>
                                            <span><FaPhoneSquareAlt/></span><span>{ptFone}</span>
                                        </button>
                                    </a>
                                    <a target="_blank" href={ptSiteLink} rel="noreferrer">
                                        <button>
                                            <span><FaInstagramSquare/></span><span>{ptSite}</span>
                                        </button>
                                    </a>
                                </div>
                                <div class="maps">
                                    <iframe src={ptEnderecoLink} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </div>    
                            <div id='inicial' className='ptTurOutros' >{loading()}</div>
                        </div>
                    </div>
                </div>
                </CSSPadrao>
            <Footer/>
        </>   
    );
}

export default Noticia;