import { useEffect, useRef, useState } from "react";
import carregando from '../../components/Geral/loading.gif';
import {base_url, midia_url, foto_default} from '../../environment'
import axios from 'axios';
import { useParams } from "react-router-dom";
import './style/manifestacao.css'
import Header from "../../components/Header/header";
import Footer from "../../components/Footer/footer";

import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import styled from "@emotion/styled";
var CSSPadrao = styled.div`${localStorage.getItem("cssGeral")}`

var unavez = true, umavez = true;

function Solicitação (){
    
    const [refreshKey, setRefreshKey] = useState(0);
    
    function atualiza() {
        setRefreshKey(oldKey => oldKey +1); 
    }

    const [alertAberto, setAlertAberto] = React.useState(false);
    const [alertMensagem, setAlertMensagem] = React.useState('');
    const [tipoAlerta, setTipoAlerta] = React.useState('');

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    function meuAlert(texto, tipo) {
        setTipoAlerta(tipo);
        setAlertMensagem(texto);
        window.location = `#root`;
        setAlertAberto(true);
    }

    function loading() {return <div id='loading'><img src={carregando} alt="Carregando"/></div>; }    
    
    const {id} = useParams();
    var token = localStorage.getItem('LOCAL_STORAGE_KEY__ACCESS_TOKEN');
 
    
    function verificaVazios() {

        if(document.getElementById("titulo").value == ""){meuAlert("Título náo preenchido.", "warning");}
        else if(document.getElementById("nome_manifestante").value == ""){meuAlert("Nome não preenchido", "warning");}
        else if(document.getElementById("identidade_manifestante").value == ""){meuAlert("Identidade não preenchida.", "warning");}
        else if(document.getElementById("email_manifestante").value == ""){meuAlert("Email não preenchido.", "warning");}
        else if(document.getElementById("telefone_manifestante").value == ""){meuAlert("Telefone não preenchido.", "warning");}
        else if(document.getElementById("endereco_manifestante").value == ""){meuAlert("Endereço não preenchido.", "warning");}
        else if(document.getElementById("conteudo").value == ""){meuAlert("Conteúdo não preenchido.", "warning");}
        else
        {
            return true;
        }    
    }
    
    // Cadastra manifestacao

    const FileUploadMultiple  = () => {

      const [alertAberto, setAlertAberto] = React.useState(false);
      const [alertMensagem, setAlertMensagem] = React.useState('');
      const [tipoAlerta, setTipoAlerta] = React.useState('');
    
      const [open, setOpen] = React.useState(false);
      const handleClose = () => {
          setOpen(false);
      };
      const handleOpen = () => {
          setOpen(true);
      };
    
      function meuAlert(texto, tipo) {
          setTipoAlerta(tipo);
          setAlertMensagem(texto);
          ///window.location = `#root`;
          setAlertAberto(true);
      }
    
      const {id} = useParams();
      const [fileList, setFileList] = useState<FileList | null>(null);
    
      const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFileList(e.target.files);
      };

        function iniciarVerificacao()
        {
            if(verificaVazios())
            {
                handleUploadClick();
            }; 
        }
    
      const handleUploadClick = async () => {
        //if (!fileList) {return;}
    
        // 👇 Create new FormData object and append files
        const data = new FormData();
        //files.forEach((file, i) => {
        files.forEach((file) => {
          //data.append(`file-${i}`, file, file.name);
          data.append(`anexo`, file, file.name);
        });

        data.append('titulo', String(document.getElementById("titulo").value))  
        data.append('anonimato', document.querySelector('input[name="statusAnonimo"]:checked').value)
        data.append('sigilo', document.querySelector('input[name="statusSigilo"]:checked').value)
        data.append('tipo', document.getElementById("tipo").value)   
        data.append('nome_manifestante', String(document.getElementById("nome_manifestante").value))
        data.append('identidade_manifestante', String(document.getElementById("identidade_manifestante").value))
        data.append('email_manifestante', String(document.getElementById("email_manifestante").value))
        data.append('telefone_manifestante', String(document.getElementById("telefone_manifestante").value))
        data.append('endereco_manifestante', String(document.getElementById("endereco_manifestante").value))
        data.append('conteudo', String(document.getElementById("conteudo").value))
        //data.append('anexo', input.files[0])  
            
        // 👇 Uploading the files using the fetch API to the server
    
        const url = `${base_url}/public/manifestacao`;
        var token = localStorage.getItem('LOCAL_STORAGE_KEY__ACCESS_TOKEN');
    
        document.getElementById("mensagem").innerHTML = "Processo em andamento, aguarde...";
        // eslint-disable-next-line
        {handleOpen()}   
        
        await axios.post(url, data, 
            { headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,
            }})
            .then(response => { 
                //console.log(response);
                //console.log("Resposta ok");
                //window.location.pathname = `concurso/editar/${response.data.id}`;
                // eslint-disable-next-line
                console.log(response.data.id);

                document.getElementById("mensagem").innerHTML = "Cadastrado com sucesso!";
                const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

                meuAlert(`Cadastrado com sucesso! Protocolo ${response.data.protocolo}`, "success");
        
            })
            .catch(error => { 
              // eslint-disable-next-line
              {handleClose()}            
              console.log(error.message);
              //console.log("Erro "+error.response.data.statusCode);
              if(error.response)
              {
                // eslint-disable-next-line
                if(error.response.data.statusCode == 415)
                {
                  //alert("Formato de arquivo incorrreto! ")
                  meuAlert("Formato de arquivo incorrreto! ", "warning");
                }
                else
                {
                  meuAlert(`${error.message}`, "error");
                }
              }
              else{
                meuAlert(`${error.message}`, "error");
              }            
            })
    
      };
    
      // 👇 files is not an array, but it's iterable, spread to get an array of files
      const files = fileList ? [...fileList] : [];
      //console.log(files);
    
      return (
        <>  
          <div className='alertUpload'>
            <Box sx={{ width: '100%'}}>
              <Collapse in={alertAberto}>
                  <Alert
                  severity={tipoAlerta}
                  variant="filled"
                  action={
                      <IconButton
                      aria-label="close"
                      size="small"
                      color="inherit"
                      onClick={() => {
                          setAlertAberto(false);
                      }}
                      >
                      <CloseIcon/>
                      </IconButton>
                  }
                  sx={{ mb: 2 }}
                  >
                  {alertMensagem}
                  </Alert>
              </Collapse>
            </Box>
          </div>      
    
          <div>
            <input type="file" onChange={handleFileChange} multiple />
    
            <ul>
              {files.map((file, i) => (
                <li key={i}>
                  {(file.name.split(".").slice(0,1)).join().substring(0,15)+"..."} - Tipo: {file.type}
                </li>
              ))}
            </ul>
    
            <button className="form-btn" onClick={iniciarVerificacao}>Enviar Solicitação</button>
          </div>
    
          <div className='uploadWait'>
              <Backdrop
                  sx={{ color: '#FFF', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={open}
              ><p id="mensagem" ></p>
                  <CircularProgress color="inherit" />
              </Backdrop>
          </div>
        </> 
      ); 
    }    

    function redir()
    {
        const protocolo: string = document.querySelector<HTMLInputElement>('input[name="protocolo"]').value;
        window.location.pathname = `esic/solicitacao/${protocolo}`; 
    }
  
  return(
        <>
            <Header/>  
            <CSSPadrao>
            <Box sx={{ width: '100%'}}>
              <Collapse in={alertAberto}>
                  <Alert
                  severity={tipoAlerta}
                  variant="filled"
                  action={
                      <IconButton
                      aria-label="close"
                      size="small"
                      color="inherit"
                      onClick={() => {
                          setAlertAberto(false);
                      }}
                      >
                      <CloseIcon/>
                      </IconButton>
                  }
                  sx={{ mb: 2 }}
                  >
                  {alertMensagem}
                  </Alert>
              </Collapse>
            </Box>

            <div className="form-ouvidoria-container">

                <div className='form-ouvidoria-protocolo'>
                    <div><h1>Consultar Solicitação</h1> </div>

                    <div>
                        <p>Protocolo do atendimento</p>  
                        <input type="number" name="protocolo" id="protocolo" autoComplete="off" placeholder='Digite seu protocolo (somente números)' ></input>
                                
                        <button onClick={redir}>Consultar</button>
                    </div>

                </div>
                
                <div className='form-ouvidoria-content'>                    

                    <div className='form-ouvidoria-header'><h2>Cadastre sua Solicitação</h2></div>
                    
                    <div className='form-ouvidoria-margin'>

                        <div className='container-sigilo'>
                            <div className="form-group">
                                <label>Anônimo</label>
                                <div className='form-ouvidoria-radio'>                            
                                                            
                                    <input type="radio" id="AnonimoAtivo" name="statusAnonimo" value="true"checked></input>
                                    <label for="ativo">Sim</label>

                                    <input type="radio" id="AnonimoInativo" name="statusAnonimo" value="false"></input>
                                    <label for="inativo">Não</label>
                                        
                                </div>
                            </div>

                            <div className="form-group">
                                <label>Sigilo</label>
                                <div className='form-ouvidoria-radio'>                            
                                                            
                                    <input type="radio" id="sigiloAtivo" name="statusSigilo" value="true"checked></input>
                                    <label for="ativo">Sim</label>

                                    <input type="radio" id="sigiloInativo" name="statusSigilo" value="false"></input>
                                    <label for="inativo">Não</label>
                                        
                                </div>
                            </div>                                            
                        </div>

                        <div className='container-sigilo'>
                            <div className="form-group">
                                <label>Título</label>
                                <div className='form-ouvidoria-title'>
                                
                                <input type="text" name="titulo" id="titulo" autoComplete="off" placeholder="Assunto da manifestação" ></input>
                         
                                </div>
                            </div>

                        </div>

                        <div className='container-sigilo-info'>

                            <div className="form-group">
                                <label>Nome</label>
                                <input type="text" name="nome_manifestante" id="nome_manifestante" autoComplete="off" placeholder="Digite seu nome" ></input>
                         
                            </div>                        

                            <div className="form-group">
                                <label>Identidade</label>
                                <input type="text" name="identidade_manifestante" id="identidade_manifestante" autoComplete="off" placeholder="Digite sua identidade" ></input>
                         
                            </div>     

                        </div>

                        <div className='container-sigilo-info'>

                            <div className="form-group">
                                <label>E-mail</label>
                                <input type="text" name="email_manifestante" id="email_manifestante" autoComplete="off" placeholder="Digite seu email" ></input>
                         
                            </div>                        

                            <div className="form-group">
                                <label htmlFor="telefone_manifestante">Telefone</label>
                                <input type="number" name="telefone_manifestante" id="telefone_manifestante" autoComplete="off" placeholder="Digite seu telefone" ></input>
                         
                            </div>

                        </div>

                        <div className='container-sigilo-info'>

                            <div className="form-group">
                                <label>Endereço</label>
                                <input type="text" name="endereco_manifestante" id="endereco_manifestante" autoComplete="off" placeholder="Digite seu endereço" ></input>
                         
                            </div>                        

                            <div className="form-group">
                                <label>Tipo</label>
                                <select name="tipo" id="tipo">
                                    <option value="SOLICITACAO">Solicitação</option>
                                    <option value="CONSULTA">Consulta</option>
                                    <option value="DENUNCIA">Denúncia</option>
                                    <option value="DUVIDA">Dúvida</option>
                                    <option value="ELOGIO">Elogio</option>
                                    <option value="RECLAMACAO">Reclamação</option>
                                    <option value="SUGESTAO">Sugestão</option>
                                </select>
                            </div>     

                        </div>
                    
                        <div className="form-group textArea-ouvidoria">
                            <label htmlFor="conteudo">Descreva qual a sua solicitação</label>      
                            <textarea                                                                
                                id="conteudo" 
                                name="conteudo" 
                                placeholder='Escreva aqui o conteúdo da sua mensagem..'
                                rows="5"
                                //{...register("textoArea")}
                            /> 
                        </div>
                    
                        <div className="form-group form-documents">
                            <label>Anexar arquivos (PDF, PNG, JPG, DOCx, XLSx)</label>
                            <FileUploadMultiple/>    
                        </div>                       

                        
                    </div>
                    
                </div>            

            </div>
            </CSSPadrao>
            <Footer/>

            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                ><p id="notifica" >vghfgh</p>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </>
    );
}

export default Solicitação;