import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/header";
import './galeria.css';
import { base_url, midia_url } from "../../environment";
import Skeleton from "@mui/material/Skeleton";
import styled from "@emotion/styled";
var CSSPadrao = styled.div`${localStorage.getItem("cssGeral")}`

export default function Galeria() {
  const [imagens, setImagens] = useState([]);

  useEffect(() => {
    fetch(`${base_url}/public/galerias`)
      .then((resource) => resource.json()) 
      .then((data) => {
        
        // eslint-disable-next-line
        if((data.statusCode != undefined) && (data.statusCode != 200)){window.location.pathname = `mensagem/${data.message}`;}
        
        //console.log(data);
        setImagens(data);
      });
  }, []);

  return (
    <>
      <Header />
      <CSSPadrao>
      <p className="tituloPagina">Galeria</p>
      <div className="containerGaleria">
        <div className="containerGaleriaImgs">
          {imagens.length === 0 ? (
            <>
              <Skeleton variant="rectangular" width={300} height={200} />
              <Skeleton variant="text" width={200} height={60} />
            </>
          ) : (
            imagens.map((itens) => (
              <Link to={`/galeria/${itens.slug}`} key={itens.id}>
                <div className="containerGaleriaTextImg">
                  <img src={midia_url + itens.capa} alt={itens.titulo} />
                  <div className="containerImgText">
                    <p>{itens.titulo}</p>
                  </div>
                </div>
              </Link>
            ))
          )}
        </div>
      </div>
      </CSSPadrao>
      <Footer />
    </>
  );
}
