import './FramePopUp.css';

function FramePopUp(props) {

    //console.log(props.video)
    var srcVideo = 'https://www.youtube.com/embed/' + props.video + '?controls=0?rel=0?';
      
  return (
    <>
      {props.state && (
        <div className="modal">
            <iframe id="videoPrincipal" width="" height="" src={srcVideo} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" allowfullscreen></iframe> 
            
            <div className='modalButton'>
              <button onClick={props.toggle} variant="outlined">Fechar </button>
            </div>            
        </div>
      )}
    </>
  );
}

export default FramePopUp;
