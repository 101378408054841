import React, { useEffect} from "react";
import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/header";
import './Concurso.css'
import carregando from '../../components/Geral/loading.gif';
import {base_url} from '../../environment'

import styled from "@emotion/styled";
var CSSPadrao = styled.div`${localStorage.getItem("cssGeral")}`

var optionClicado = "background-color: var(--mainColor); color: var(--textColor2);"

function Pessoa(){

    function loading() {
        return <div id='loading'><img src={carregando} alt="Carregando"/></div>;
    }
    // pegar dados dos concursos
    useEffect(() => {
        fetch(`${base_url}/public/prefeitura/concursos?status=aberto`)
        .then((d) => d.json())
        .then(dados => { 

            // eslint-disable-next-line
            if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message}`;}

            // eslint-disable-next-line 
            if(dados.length == 0)
            {
                document.getElementById("aberto").innerHTML = '<button><p>Não há concursos abertos.</p><p></p><p></p></button>';
                    
            }
            else
            {
                var arrayInicial = [];
                
                for(let i=0; i<dados.length; i++){

                    if((dados == null) || (dados[i] == null))
                    {
                        arrayInicial.push(
                            // eslint-disable-next-line
                            String('<button><p> Sem dados disponíveis. </p>'
                            +'<p> - </p>'
                            +'<p> - </p>'
                            +'</button>')                        
                        );
                        break;
                    }

                    var dataAbertura = new Date(dados[i].data_inicio).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).toString();
                
                    arrayInicial.push(
                        // eslint-disable-next-line
                        String('<button><p>'+dados[i].titulo+'</p><p>Data de abertura: </p><p>'+dataAbertura+'</p><a target="_self" href=concurso/'+dados[i].slug+'>Saiba mais</a></button>')
                        
                    );  
                }
                document.getElementById("aberto").innerHTML = arrayInicial;   
            }            
        });     

        fetch(`${base_url}/public/prefeitura/concursos?status=lancado`)
        .then((d) => d.json())
        .then(dados => { 

            // eslint-disable-next-line
            if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message}`;}

            var arrayInicial = [];
            
            for(let i=0; i<dados.length; i++){

                if((dados == null) || (dados[i] == null))
                {
                    arrayInicial.push(
                        // eslint-disable-next-line
                        String('<button><p> Sem dados disponíveis. </p>'
                        +'<p> - </p>'
                        +'<p> - </p>'
                        +'</button>')                        
                    );
                    break;
                }

                var dataAbertura = new Date(dados[i].data_inicio).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).toString();
            
                arrayInicial.push(
                    // eslint-disable-next-line
                    String('<button><p>'+dados[i].titulo+'</p><p>Data de abertura: </p><p>'+dataAbertura+'</p><a target="_self" href=concurso/'+dados[i].slug+'>Saiba mais</a></button>')
                    
                );  
            }
            document.getElementById("lancado").innerHTML = arrayInicial;   
        });      

        fetch(`${base_url}/public/prefeitura/concursos?status=encerrado`)
        .then((d) => d.json())
        .then(dados => { 

            // eslint-disable-next-line
            if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message}`;}

            var arrayInicial = [];
            
            for(let i=0; i<dados.length; i++){ 

                if((dados == null) || (dados[i] == null))
                {
                    arrayInicial.push(
                        // eslint-disable-next-line
                        String('<button><p> Sem dados disponíveis. </p>'
                        +'<p> - </p>'
                        +'<p> - </p>'
                        +'</button>')                        
                    );
                    break;
                }

                var dataAbertura = new Date(dados[i].data_inicio).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).toString();
            
                arrayInicial.push(
                    // eslint-disable-next-line
                    String('<button><p>'+dados[i].titulo+'</p><p>Data de abertura: </p><p>'+dataAbertura+'</p><a target="_self" href=concurso/'+dados[i].slug+'>Saiba mais</a></button>')
                    
                );  
            }
            document.getElementById("encerrado").innerHTML = arrayInicial;   
        });   
        
        document.getElementById(0).click();
        
    }); 

    function menuConc(id) {       
        
        for(let i=0; i<3; i++){
            document.getElementById(i).style = null;
        }
        document.getElementById(id).style = optionClicado;     

        // eslint-disable-next-line
        if(id != 0)
        {
            document.getElementById('aberto').setAttribute('hidden', '');
        }
        else
        {
            document.getElementById('aberto').removeAttribute('hidden');
            document.getElementById('lancado').setAttribute('hidden', '');
            document.getElementById('encerrado').setAttribute('hidden', '');
        }

        // eslint-disable-next-line
        if(id != 1)
        {
            document.getElementById('lancado').setAttribute('hidden', '');
        }
        else
        {
            document.getElementById('lancado').removeAttribute('hidden');
            document.getElementById('aberto').setAttribute('hidden', '');
            document.getElementById('encerrado').setAttribute('hidden', '');
        }

        // eslint-disable-next-line
        if(id != 2)
        {
            document.getElementById('encerrado').setAttribute('hidden', '');
        }
        else
        {
            document.getElementById('encerrado').removeAttribute('hidden');
            document.getElementById('aberto').setAttribute('hidden', '');
            document.getElementById('lancado').setAttribute('hidden', '');
        }
    }
    
    return(
        <>   
            <Header />
            <CSSPadrao>
                <div className="concurso">
                    <div className="tituloPagina"> 
                        <p id="titu" >Concursos e seleções</p>
                    </div>   
                    <div className="concursoOpt" >
                        <button id="0" onClick={(e) => menuConc(e.target.id)}>Aberto</button>
                        <button id="1" onClick={(e) => menuConc(e.target.id)}>Lançados</button>
                        <button id="2" onClick={(e) => menuConc(e.target.id)}>Encerrados</button>
                    </div>                 
                    <div className="concursoCard" >
                        <div id="aberto">{loading()}</div>
                        <div id="lancado">{loading()}</div>
                        <div id="encerrado">{loading()}</div>
                    </div>                       
                </div>
            </CSSPadrao>
            <Footer/>
        </>   
    );
}

export default Pessoa;