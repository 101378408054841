import './faleConosco.css'
import { FaPhoneSquareAlt, FaRegEnvelope, FaExclamationCircle, FaLink, FaMapMarkerAlt,FaClock } from "react-icons/fa";
import {base_url} from '../../environment'
import { useEffect, useState } from "react";
import Modal from './Modal';
import { Link } from 'react-router-dom';

function Card(props){

    const [informacao, setInformacao] = useState('');
    const [informacaoEmail, setInformacaoEmail] = useState('');
    const [informacaoMapa, setInformacaoMapa] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    const toggleModal = () => {
        console.log("isOpen antes:", isOpen);
        setIsOpen(!isOpen);
        console.log("isOpen depois:", isOpen);
      };

    useEffect(() => {
        async function fetchData() {
          try {
            const url = await fetch(`${base_url}/public/fale-conosco`);
            const resource = await url.json();
           
            // eslint-disable-next-line
            if((resource.statusCode != undefined) && (resource.statusCode != 200)){window.location.pathname = `mensagem/${resource.message}`;}

                    
            setInformacao(resource)
            setInformacaoEmail(`mailto:${resource.email}`)
            setInformacaoMapa(resource.link_maps || "https://maps.google.com/maps?q=ookatecnologia&t=&z=15&ie=UTF8&iwloc=&output=embed");

          } catch (error) {
            // Trate o erro aqui, se necessário
          }
        }
        fetchData();
      }, []);

    return(
        <div className="card-container">
            <p className='tituloPagina'>Fale Conosco</p>
            <div className='card'>

                <div className="Email">
                    <FaRegEnvelope />
                    <h1>{props.title || `Mensagem via E-MAIL`}</h1>
                    <span></span>
                    {/*  <span>{formatarNumero(informacao.email)}</span> */} 
                    <p>{props.text || `Este canal presta informações gerais sobre serviços, não substitui a Ouvidoria/e-SIC e, além disso, não tem controle de registro.`}</p>
                    <a href={informacaoEmail} ><button> {<span> Enviar E-mail</span>}</button></a>
                </div>

                <div className="TelefoneContato">
                    <FaPhoneSquareAlt />
                    <h1>{props.title || `Telefones para contato`}</h1>
                    <span></span>
                    {/*  <span>{formatarNumero(informacao.fone)}</span> */} 
                    <p>{props.text || `Tenha acesso aos telefones dos serviços públicos. Clique abaixo para acessar os Contatos Úteis.`}</p>
                    <button onClick={toggleModal}><FaLink /> {<span> Contatos úteis</span>}</button>
                    {isOpen && (
                        <div className="modal-wrapper">
                        <Modal toggle={toggleModal} state={isOpen}/>
                        </div>
                    )}
                </div>

                <div className="Ouvidoria">
                <FaPhoneSquareAlt />
                    <h1>{ `Ouvidoria`}</h1>
                    <p>{`Acesse nosso canal de ouvidoria e envie suas manifestações, críticas, elogios, denúncias e ajude o governo a tornar nossa cidade cada vez melhor.`}</p>
                    <ul>
                                                        
                    </ul>
                    <Link to={`/ouvidoria`}>
                    <button>{<span> Acessar Ouvidoria</span>}</button>
                    </Link>
                </div>

                <div className="E-Sic">
                    <FaExclamationCircle />
                    <h1> E-Sic</h1>
                    <p>{`SIC (Serviço de Informações ao Cidadão) permite que qualquer pessoa encaminhe pedidos de informação aos órgãos e entidades do Poder Executivo Municipal.`}</p>
                    <Link to={'/esic'}>
                        <button>{<span> Acessar E-Sic</span>}</button>
                    </Link>
                </div>
            </div>

            <div className='containerEnd'>
                
                <div className='cardEndereco'>    
                    <div className="cardsEnd">
                        <p><FaMapMarkerAlt/> Endereço <br/> <span> {informacao.endereco} <br/> CEP: {informacao.cep} </span></p>                             
                    </div>   

                    <div className="cardsEnd">
                        <p><FaClock /> Horário <br/> <span>{informacao.expediente}</span> </p>
                        
                    </div> 
                </div> 

                     
                <div className="mapEndereco">
                    <div className="tamannhoMapa">
                        <iframe title="Descrição do conteúdo do iframe"  src={informacaoMapa} frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
                        </iframe>                        
                    </div>
                </div>

            </div>            
        </div>
    );
}

export default Card;