import React, { useEffect, useState } from 'react';
import {base_url} from '../../environment'
import { formatarNumero } from '../../environment/formatNumber';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import './faleConosco.css';

function Modal(props) {

    const [tables, setTables] = useState()

    useEffect(() => {
        async function fetchData() {
          try {
            const url = await fetch(`${base_url}/public/contatos-uteis`);
            const resource = await url.json();

            // eslint-disable-next-line
            if((resource.statusCode != undefined) && (resource.statusCode != 200)){window.location.pathname = `mensagem/${resource.message}`;}

           
            setTables(resource)

           
          } catch (error) {
            console.log('requisição não de certo')
          }
        }
        fetchData();
      }, []);
  return (
    <>
      {props.state && (
        <div className="modal">
          <TableContainer className="modal-content">
            <span className="close" onClick={props.toggle}>
              &times;
            </span>
            <h1>Contatos úteis</h1>
         
            <Table sx={{minWidth: 650}} arial-label="caption table" className="table">
              <TableHead>
                <TableRow>
                  <TableCell>Instituição</TableCell>
                  <TableCell>Telefone</TableCell>
                  <TableCell>Celular</TableCell>
                  <TableCell>E-mail</TableCell>
                  <TableCell>Endereço</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {tables && tables.map(item => (
                    <TableRow>
                        <TableCell>{item.nome || '--'}</TableCell>
                        <TableCell>{formatarNumero(item.fone) || '--'}</TableCell>
                        <TableCell>{formatarNumero(item.celular) || '--'}</TableCell>
                        <TableCell>{item.email || '--'}</TableCell>
                        <TableCell>{item.endereco || '--'} </TableCell>
                    </TableRow>

                    
                ))}
        
              </TableBody>
            </Table>
         
            <Button onClick={props.toggle} variant="outlined">Fechar</Button>

          </TableContainer>
        </div>
      )}
    </>
  );
}

export default Modal;
