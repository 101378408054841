import React, {useEffect, useRef, useState} from "react";
import {FaTimes, FaBars} from "react-icons/fa"
import { Link } from "react-router-dom";
import './header.css';
import '../Geral/geral.css';
import lupa from './lupa.png';
import carregando from '../Geral/loading.gif';
import {base_url, midia_url,foto_default,logo_default} from '../../environment'
import {MenuItems} from './secretaria';
import {FaArrowUp} from "react-icons/fa"
import styled from "@emotion/styled";

var bannerHeader = base_url+'/public/banners/header';
var url = base_url+'/public';
var urlSecretarias =  base_url+'/public/prefeitura/secretarias';
var legislacaoBtn = [{title: 'Legislação',submenu: [],},], inavez = true, pinavez = true;
var transparenciaBtn = [{title: 'Transparência',submenu: [],},];
var municipioBtn = [{title: 'Município',submenu: [],},];
var contatoBtn = [{title: 'Contato',submenu: [],},];
var blogBtn = [{title: 'Blog (Notícias)',submenu: [],},];
var banners = [], tempoBanners = 10;

var umSecretarias = true, umBanner = true, umLogo = true;

var cssGeral;

// pegar css
await fetch(url)
    .then((d) => d.json())
    .then(dados => {      

        cssGeral = `
            --mainColor: ${dados.Config.cor_primaria};
            --mainColor2: ${dados.Config.cor_secundaria};  
            --mainColor3: #b6a6a6;
            --mainColor4: #24977B;

            --textColor: #004348;
            --textColor2: white;

            --fontA: 'Reem Kufi Regular';  
        `
                                
});

localStorage.setItem("cssGeral", cssGeral);
var CSSPadrao = styled.div`${cssGeral}`

function Header() {

    // preencher sub-menus barra
    useEffect(() => {
        if(umSecretarias)
        {  
            fetch(urlSecretarias)
            .then((d) => d.json())
            .then(dados => {      
                //console.log(dados.length); 
                
                var qtdSecretarias = dados.length;  
                
                if(inavez)
                {
                    legislacaoBtn[0].submenu.push({title: "Proposições", url: String(window.location.origin)+"/proposicao/",},);
                    legislacaoBtn[0].submenu.push({title: "Plenária", url: String(window.location.origin)+"/plenaria/",},);
                    legislacaoBtn[0].submenu.push({title: "Comissões", url: String(window.location.origin)+"/comissao/",},);
                   
                    transparenciaBtn[0].submenu.push({title: "Licitações", url: String(window.location.origin)+"/licitacoes/",},);
                    transparenciaBtn[0].submenu.push({title: "Contratos", url: String(window.location.origin)+"/contratos/",},);
                    transparenciaBtn[0].submenu.push({title: "Documentos", url: String(window.location.origin)+"/documentos/",},);

                    municipioBtn[0].submenu.push({title: "Sobre", url: String(window.location.origin)+"/municipio/",},);                    
                    municipioBtn[0].submenu.push({title: "Perguntas", url: String(window.location.origin)+"/perguntas-frequentes/",},);
                    municipioBtn[0].submenu.push({title: "Prefeitos (as)", url: String(window.location.origin)+"/prefeitos/",},);
                    municipioBtn[0].submenu.push({title: "Turismo", url: String(window.location.origin)+"/pontos-turisticos/",},);
                    municipioBtn[0].submenu.push({title: "Concursos", url: String(window.location.origin)+"/concurso/",},);
                    municipioBtn[0].submenu.push({title: "Obras", url: String(window.location.origin)+"/obras/",},);
                    
                    blogBtn[0].submenu.push({title: "Notícias", url: String(window.location.origin)+"/noticias/",},);
                    blogBtn[0].submenu.push({title: "Galeria", url: String(window.location.origin)+"/galeria/",},);

                    contatoBtn[0].submenu.push({title: "Fale Conosco", url: String(window.location.origin)+"/fale-conosco/",},);
                    contatoBtn[0].submenu.push({title: "Ouvidoria", url: String(window.location.origin)+"/ouvidoria/",},);
                    contatoBtn[0].submenu.push({title: "E-Sic", url: String(window.location.origin)+"/esic/",},);
                    
                    inavez = false;
                }

                //console.log(window.location);
                                        
            });
            umSecretarias = false;
        }
    });

    // useEffect imagens banner
    useEffect(() => {
        if(umBanner)
        {  
            fetch(url)
            .then((d) => d.json())
            .then(dados => {            
                // eslint-disable-next-line
                //console.log(dados.Entidade);

                if(dados.Entidade.logo == null){document.getElementById("logo1").src = logo_default;}
                else
                {
                    document.getElementById("logo1").src = midia_url+dados.Entidade.logo;
                }            
            
            },);
            fetch(bannerHeader)
            .then((d) => d.json())
            .then(dados => {          
                if (dados.statusCode !== 200) return;

                dados.forEach(element => {
                    banners.push(midia_url+element.imagem);
                });

                // eslint-disable-next-line
                if(banners.length == 0){banners.push(foto_default);}

                //controle dos banners
                if(pinavez)
                {
                    var contador = -1;
                    setInterval(function () {
                        contador++;
                        document.getElementById("banner").src = banners[contador];
                        if(contador > (banners.length - 2))contador=-1;            
                    }, (tempoBanners * 1000)+2000);
                    pinavez = false;
                }   
            });
            umBanner = false;
        }
    });
    
    // menu mobile
    const navRef = useRef();
    const showNavBar = () => {
        
        navRef.current.classList.toggle('responsive_nav');

        console.log(navRef)
    }

    // menu pesquisa
    const [pesquisa, setPesquisa] = React.useState("");
    function handleSubmit(event) {
        event.preventDefault();
        //console.log("Digitou na pesquisa:" + pesquisa);

        //window.location.search = "";
        window.location.pathname = `pesquisa/${pesquisa}`;

        console.log(window.location);
        //console.log((window.location.pathname).substring(1,9));
    }   

    return (         
        <>  
            <CSSPadrao>
            <header id="header" >
                <nav ref={navRef} >
                    <Link to='/'> Início</Link>
                    <ul className="menus">
                        {transparenciaBtn.map((menu, index) => {
                        return <MenuItems items={menu} key={index} />;
                        })}
                    </ul>
                    <ul className="menus">
                        {municipioBtn.map((menu, index) => {
                        return <MenuItems items={menu} key={index} />;
                        })}
                    </ul>
                    <ul className="menus">
                        {blogBtn.map((menu, index) => {
                        return <MenuItems items={menu} key={index} />;
                        })}
                    </ul>
                    <Link to='/orgaos'>Órgãos</Link>
                    <Link to='/carta-servicos'>Carta de Serviços</Link>
                    <Link to='/covid'>Covid</Link>
                    <ul className="menus">
                        {legislacaoBtn.map((menu, index) => {
                        return <MenuItems items={menu} key={index} />;
                        })}
                    </ul>
                    <ul className="menus">
                        {contatoBtn.map((menu, index) => {
                        return <MenuItems items={menu} key={index} />;
                        })}
                    </ul>

                    <div className='admin'>
                        <Link to='/'>Webmail</Link>
                        <Link to='/'>Admin</Link>
                    </div>

                    <button className='nav-btn nav-close-btn' onClick={showNavBar}>
                        <FaTimes /> 
                    </button>
                </nav>
                
                <button className='nav-btn'  onClick={showNavBar}>
                    <FaBars/>
                </button>
                            
                <button className='nav-btn'  onClick={showNavBar}>
                </button>
            </header>
        
            <form onSubmit={handleSubmit} className="pesquisa" method="post" encType="application/json" id="pesquisar">
                <input type="text" name="pesquisa" id="pesquisa" placeholder="Pesquise:" autoComplete="off"  onChange={(e) => setPesquisa(e.target.value)} ></input>
                {/* eslint-disable-next-line */}
                <a onClick={handleSubmit}><img src={lupa} alt="lupa" className="lupa"></img></a>
            </form>

            <div className="banner">
                <img id="banner" alt="banner" src={carregando} />
            </div>

            <div className="logo1">
                <img id="logo1" alt="logo1" src={carregando}></img>
            </div>

            <a href="#header">
                <div className="botao_topo">              
                    <FaArrowUp/>                  
                </div>
            </a>    
            </CSSPadrao>
        </>
        
    );
   
}

export default Header;