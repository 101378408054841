import React, { useEffect} from "react";
import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/header";
import '../concursoEspecifico/ConcursoEspecifico.css'
import carregando from '../../components/Geral/loading.gif';
import {base_url, midia_url} from '../../environment'
import { useParams } from "react-router-dom";
import styled from "@emotion/styled";
var CSSPadrao = styled.div`${localStorage.getItem("cssGeral")}`

function Pessoa(){

    function loading() {
        return <div id='loading'><img src={carregando} alt="Carregando"/></div>;
    }

    const {slug} = useParams(); 

    // pegar dados do concurso especifico
    useEffect(() => {
        fetch(`${base_url}/public/prefeitura/concursos/${slug}`)
        .then((d) => d.json())
        .then(dados => {      
            //console.log(dados); 

            // eslint-disable-next-line
            if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message}`;}

            var arrayInicial = [];        
            
            // eslint-disable-next-line
            document.getElementById("card1").innerHTML = String('<p>'+dados.titulo.toUpperCase()+'</p><p>'+dados.conteudo+'</p>'+'<p>Inscritos: '+dados.inscritos+'.  Visualizações: '+dados.views+' <a target="_blank" href=https://'+dados.link_inscricao+'><button>Fazer inscrição</button></a></p>') 

            arrayInicial.push(
                // eslint-disable-next-line
                String('<p>EDITAIS E OUTROS ANEXOS</p>')
            
            );

            for(let i=0; i<dados.Anexo.length; i++){
               
                arrayInicial.push(
                    // eslint-disable-next-line
                    String('<p>'+dados.Anexo[i].titulo+'<a target="_blank" href='+midia_url+dados.Anexo[i].anexo+'><button>Baixar (Download)</button></a></p>')
                );  
            }

            //console.log(arrayInicial);  
            document.getElementById("card2").innerHTML = arrayInicial;   
            document.getElementById("titu").innerHTML = dados.titulo;

            var dataInicio = new Date(dados.data_inicio).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).toString();
            var dataFim = new Date(dados.data_fim).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).toString();
            document.getElementById("titu2").innerHTML = 'Início: '+dataInicio+' - Fim: '+dataFim;
            
        });     
    });  
    
    return(
        <>   
            <Header />
            <CSSPadrao>
                <div className="concursoEsp">
                    <div className="tituloPagina"> 
                        <p id="titu" >Carregando...</p>
                    </div>   
                    <div className="InicialNome"> 
                        <p id="titu2" ></p>
                    </div>                    
                    <div className="concursoEspCard" >
                        <div></div>
                        <div id="card1">{loading()}</div>                                        
                    </div>
                    <div className="concursoEspCard linha" >
                        <div></div>
                        <div id="card2">{loading()}</div>                    
                    </div>
                       
                </div>
            </CSSPadrao>
            <Footer/>
        </>   
    );
}

export default Pessoa;