import { TreeNode } from "react-organizational-chart";
import { AiFillMail } from "react-icons/ai";
import { BsFillPersonFill } from "react-icons/bs";
import '../style.css'
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

const Esics = styled.div`
    width: 20rem;
  border: 1px solid black;
  display: inline-block;
  color: #121212;
  text-align: start;
  border-radius: 10px;
  outline: none;

  h1{
    text-align: center;
    width: 100%;
    padding: 10px;
    font-size: 1.3rem;
    background-color: #89A0CC; 
    border-bottom: 1px solid black;
    font-family: 'Inter';
    font-weight: 500;
    color: white;
    border-radius: 10px 10px 0 0;
  }
  p{
    margin-top: 0;
    font-family: 'Inter';
    font-weight: 500;
    border-bottom: 1px solid black;
    font-size: 1rem;
    padding: 5px;
  }
`

function Esic(props){
    return (
        <TreeNode className="testeEnty"   
        label={
            
              <Esics>
                <Link to={`/esic/`}>
                  {<h1>Esic</h1>}
                </Link>              
             {props.resposavel && (<p> <BsFillPersonFill />{props.resposavel}</p>)}
               {props.email && (<p><AiFillMail/> {props.email}</p>)}
              {props.celular }
              </Esics>
         
        }
      >
                
      </TreeNode>
    );
}

export default Esic;