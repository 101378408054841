import React, { useEffect} from "react";
import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/header";
import './Covid.css'
import carregando from '../../components/Geral/loading.gif';
import {base_url, midia_url} from '../../environment'
import styled from "@emotion/styled";
var CSSPadrao = styled.div`${localStorage.getItem("cssGeral")}`

var optionClicado = "background-color: var(--mainColor); color: var(--textColor2);"

function Pessoa(){

    function loading() {
        return <div id='loading'><img src={carregando} alt="Carregando"/></div>;
    }
    // pegar dados 
    useEffect(() => {
        fetch(`${base_url}/public/prefeitura/covid/vacina`)
        .then((d) => d.json())
        .then(dados => { 

            // eslint-disable-next-line
            if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message}`;}

            // eslint-disable-next-line 
            if(dados.length == 0)
            {
                document.getElementById("vacinas").innerHTML = '<button><p>Não há vacinas.</p><p></p><p></p></button>';
                    
            }
            else
            {
                var arrayInicial = [];
                
                for(let i=0; i<dados.length; i++){

                    if((dados == null) || (dados[i] == null))
                    {
                        arrayInicial.push(
                            // eslint-disable-next-line
                            String('<button><p> Sem dados disponíveis. </p>'
                            +'<p> - </p>'
                            +'<p> - </p>'
                            +'</button>')                        
                        );
                        break;
                    }

                    var data_recebimento = new Date(dados[i].data_recebimento).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).toString();
                    var data_validade = new Date(dados[i].data_validade).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).toString();
                
                    arrayInicial.push(
                        // eslint-disable-next-line
                        String('<button><p>'+dados[i].nome+' ('+dados[i].Tipo.nome+') </p>'
                        +'<p>Data de recebimento: '+data_recebimento+'</p>'
                        +'<p>Data de vencimento: '+data_validade+'</p>'
                        +'<p>Quantidade: '+dados[i].quantidade+'</p>'
                        +'<p>Lote: '+dados[i].lote+'</p>'
                        +'<p>Valor Unitário: R$ '+dados[i].valor_unitario+'</p>'
                        +'<p>Valor Total: R$ '+dados[i].valor_total+'</p>'
                        +'<p>Fornecedor: '+dados[i].fornecedor+'</p>'
                        +'<p>Nota Fiscal: <a target="_blank" href='+midia_url+dados[i].nota_fiscal+'>Abrir</a></p>'


                        +'</button>')
                        
                    );  
                }
                document.getElementById("vacinas").innerHTML = arrayInicial;   
            }            
        });     

        fetch(`${base_url}/public/prefeitura/covid/grupo-vacinado`)
        .then((d) => d.json())
        .then(dados => { 

            // eslint-disable-next-line
            if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message}`;}

            var arrayInicial = [];
            
            for(let i=0; i<dados.length; i++){

                if((dados == null) || (dados[i] == null))
                {
                    arrayInicial.push(
                        // eslint-disable-next-line
                        String('<button><p> Sem dados disponíveis. </p>'
                        +'<p> - </p>'
                        +'<p> - </p>'
                        +'</button>')                        
                    );
                    break;
                }

                var data_atualizacao = new Date(dados[i].data_atualizacao).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).toString();
            
                arrayInicial.push(
                    // eslint-disable-next-line
                    String('<button><p>'+dados[i].gupo_nome+'</p>'
                    +'<p>Nome da dose: '+dados[i].dose_nome+'</p>'
                    +'<p>Quantidade: '+dados[i].sum+'</p>'
                    +'<p>Data da atualização: '+data_atualizacao+'</p>'
                    +'</button>')
                    
                );  
            }
            document.getElementById("grupo-vacinado").innerHTML = arrayInicial;   
        });      

        fetch(`${base_url}/public/prefeitura/covid/vacinometro`)
        .then((d) => d.json())
        .then(dados => { 

            // eslint-disable-next-line
            if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message}`;}

            var arrayInicial = [];

            var dataAtualizacao = new Date(dados.dataAtualizacao[0].data_atualizacao).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).toString();
                
            arrayInicial.push(
                // eslint-disable-next-line
                String('<button><p> Dados gerais </p>'
                +'<p>Data da atualização: '+dataAtualizacao+'</p>'
                +'<p>Doses Aplicadas: '+dados.dosesAplicadas[0].doses_aplicadas+'</p>'
                +'<p> - </p>'
                +'</button>')
                
            );  

            console.log(dados.totalPorDose.length)
                        
            for(let i=0; i<dados.totalPorDose.length; i++){ 

                if((dados.totalPorDose == null) || (dados.totalPorDose[i] == null))
                {
                    arrayInicial.push(
                        // eslint-disable-next-line
                        String('<button><p> Sem dados disponíveis. </p>'
                        +'<p> - </p>'
                        +'<p> - </p>'
                        +'</button>')                        
                    );
                    break;
                }

                arrayInicial.push(
                    // eslint-disable-next-line
                    String('<button><p> Total por dose </p>'
                    +'<p>Nome da dose: '+dados.totalPorDose[i].dose_nome+'</p>'
                    +'<p>Quantidade aplicada: '+dados.totalPorDose[i].sum+'</p>'
                    +'<p>Porcentagem: '+dados.totalPorDose[i].porcentagem+'</p>'
                    +'</button>')
                    
                );
            }

            for(let i=0; i<dados.vacinasRecebidas.length; i++){ 

                if((dados.vacinasRecebidas == null) || (dados.vacinasRecebidas[i] == null))
                {
                    arrayInicial.push(
                        // eslint-disable-next-line
                        String('<button><p> Sem dados disponíveis. </p>'
                        +'<p> - </p>'
                        +'<p> - </p>'
                        +'</button>')                        
                    );
                    break;
                }

                arrayInicial.push(
                    // eslint-disable-next-line
                    String('<button><p> Vacinas recebidas </p>'
                    +'<p>Tipo da vacina: '+dados.vacinasRecebidas[i].tipo+'</p>'
                    +'<p>Quantidade: '+dados.vacinasRecebidas[i].vacinas_recebidas+'</p>'
                    +'<p> - </p>'
                    +'</button>')
                    
                );
            }
            document.getElementById("vacinometro").innerHTML = arrayInicial;   
        });   

        fetch(`${base_url}/public/prefeitura/covid/boletim`)
        .then((d) => d.json())
        .then(dados => { 

            // eslint-disable-next-line
            if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message}`;}

            var arrayInicial = [];
            
            for(let i=0; i<dados.length; i++){

                if((dados == null) || (dados[i] == null))
                {
                    arrayInicial.push(
                        // eslint-disable-next-line
                        String('<button><p> Sem dados disponíveis. </p>'
                        +'<p> - </p>'
                        +'<p> - </p>'
                        +'</button>')                        
                    );
                    break;
                }

                var data = new Date(dados[i].data).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).toString();
            
                arrayInicial.push(
                    // eslint-disable-next-line
                    String('<button><p>'+dados[i].numero+'</p>'                    
                    +'<p>Data da atualização: '+data+'</p>'
                    +'<p>Ativos: '+dados[i].ativos+'</p>'
                    +'<p>Confirmados: '+dados[i].confirmados+'</p>'
                    +'<p>Descartados: '+dados[i].descartados+'</p>'
                    +'<p>Recuperados: '+dados[i].recuperados+'</p>'
                    +'<p>Isolamento: '+dados[i].isolamento+'</p>'
                    +'<p>Obitos: '+dados[i].obitos+'</p>'
                    +'<p>Monitorados: '+dados[i].monitorados+'</p>'
                    +'<p>Suspeitos: '+dados[i].suspeitos+'</p>'
                    +'<p>Hospitalizados: '+dados[i].hospitalizados+'</p>'
                    +'</button>')
                    
                );  
            }
            document.getElementById("boletim").innerHTML = arrayInicial;   
        });    

        fetch(`${base_url}/public/prefeitura/covid/legislacao`)
        .then((d) => d.json())
        .then(dados => { 

            // eslint-disable-next-line
            if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message}`;}

            var arrayInicial = [];
            
            for(let i=0; i<dados.length; i++){

                if((dados == null) || (dados[i] == null))
                {
                    arrayInicial.push(
                        // eslint-disable-next-line
                        String('<button><p> Sem dados disponíveis. </p>'
                        +'<p> - </p>'
                        +'<p> - </p>'
                        +'</button>')                        
                    );
                    break;
                }

                var LesData = new Date(dados[i].data).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).toString();
            
                arrayInicial.push(
                    String('<button><p>'
                        +dados[i].titulo+'</p><p>Data: '
                        +LesData+'</p><p>Número: '
                        +dados[i].numero+'</p><a target="_blank" href=documentos/'
                        +dados[i].slug+'>Ver documento</a></button>')  
                );  
            }
            document.getElementById("legislacao").innerHTML = arrayInicial;   
        });   
        
        
        document.getElementById(0).click();
        
    }); 

    function menuConc(id) {       
        
        for(let i=0; i<5; i++){
            document.getElementById(i).style = null;
        }
        document.getElementById(id).style = optionClicado;     

        // eslint-disable-next-line
        if(id != 0)
        {
            document.getElementById('vacinas').setAttribute('hidden', '');
        }
        else
        {
            document.getElementById('vacinas').removeAttribute('hidden');
            document.getElementById('grupo-vacinado').setAttribute('hidden', '');
            document.getElementById('vacinometro').setAttribute('hidden', '');
            document.getElementById('boletim').setAttribute('hidden', '');
            document.getElementById('legislacao').setAttribute('hidden', '');
        }

        // eslint-disable-next-line
        if(id != 1)
        {
            document.getElementById('grupo-vacinado').setAttribute('hidden', '');
        }
        else
        {
            document.getElementById('grupo-vacinado').removeAttribute('hidden');
            document.getElementById('vacinas').setAttribute('hidden', '');
            document.getElementById('vacinometro').setAttribute('hidden', '');
            document.getElementById('boletim').setAttribute('hidden', '');
            document.getElementById('legislacao').setAttribute('hidden', '');
        }

        // eslint-disable-next-line
        if(id != 2)
        {
            document.getElementById('vacinometro').setAttribute('hidden', '');
        }
        else
        {
            document.getElementById('vacinometro').removeAttribute('hidden');
            document.getElementById('vacinas').setAttribute('hidden', '');
            document.getElementById('grupo-vacinado').setAttribute('hidden', '');
            document.getElementById('boletim').setAttribute('hidden', '');
            document.getElementById('legislacao').setAttribute('hidden', '');
        }

        // eslint-disable-next-line
        if(id != 3)
        {
            document.getElementById('boletim').setAttribute('hidden', '');
        }
        else
        {
            document.getElementById('boletim').removeAttribute('hidden');
            document.getElementById('vacinas').setAttribute('hidden', '');
            document.getElementById('grupo-vacinado').setAttribute('hidden', '');
            document.getElementById('vacinometro').setAttribute('hidden', '');
            document.getElementById('legislacao').setAttribute('hidden', '');
        }

        // eslint-disable-next-line
        if(id != 4)
        {
            document.getElementById('legislacao').setAttribute('hidden', '');
        }
        else
        {
            document.getElementById('legislacao').removeAttribute('hidden');
            document.getElementById('vacinas').setAttribute('hidden', '');
            document.getElementById('grupo-vacinado').setAttribute('hidden', '');
            document.getElementById('vacinometro').setAttribute('hidden', '');
            document.getElementById('boletim').setAttribute('hidden', '');
        }
}
    
    return(
        <>   
            <Header />
            <CSSPadrao>
                <div className="concurso">
                    <div className="tituloPagina"> 
                        <p id="titu" >Covid</p>
                    </div>   
                    <div className="covidOpt" >
                        <button id="0" onClick={(e) => menuConc(e.target.id)}>Vacinas</button>
                        <button id="1" onClick={(e) => menuConc(e.target.id)}>Grupo Vacinado</button>
                        <button id="2" onClick={(e) => menuConc(e.target.id)}>Vacinômetro</button>
                        <button id="3" onClick={(e) => menuConc(e.target.id)}>Boletim</button>
                        <button id="4" onClick={(e) => menuConc(e.target.id)}>Legislação</button>
                    </div>                 
                    <div className="covidCard" >
                        <div id="vacinas">{loading()}</div>
                        <div id="grupo-vacinado">{loading()}</div>
                        <div id="vacinometro">{loading()}</div>
                        <div id="boletim">{loading()}</div>
                        <div id="legislacao">{loading()}</div>
                    </div>                       
                </div>
            <Footer/>
            </CSSPadrao>
        </>   
    );
}

export default Pessoa;