import React, { useEffect, useState} from "react";
import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/header";
import './Video.css'
import carregando from '../../components/Geral/loading.gif';
import {base_url} from '../../environment'
import FramePopUp from './FramePopUp'
import styled from "@emotion/styled";
var CSSPadrao = styled.div`${localStorage.getItem("cssGeral")}`

var optionClicado = "background-color: var(--mainColor); color: var(--textColor2);"

var urlDocs, essaVez=true, cadavez=true, pinaVez = true;

function Pessoa(){

    function loading() {
        return <div id='loading'><img src={carregando} alt="Carregando"/></div>;
    }

    const [isOpen, setIsOpen] = useState(false);
    const [videoUrl, setvideoUrl] = useState(''); 
    const [refreshKey, setRefreshKey] = useState(0);

    function toggleModal(id){
        //console.log(id);
        setvideoUrl(id);
        //console.log(videoUrl);
        //console.log("isOpen antes:", isOpen);
        setIsOpen(!isOpen);
        //console.log("isOpen depois:", isOpen);
    };

    const search = window.location.search;
    const params = new URLSearchParams(search);
    //console.log(String(params))

    //console.log(grupo_documento_id)
   
    if(String(params) == ""){
        urlDocs = String(base_url+"/public/videos");
    }
    else
    {
        urlDocs = String(base_url+"/public/videos"+"?"+params.toString());
    }
    
    // pegar dados dos documentos
    // filtros e funcoes no final
    useEffect(() => {
        fetch(urlDocs)
        .then((d) => d.json())
        .then(dados => { 

            // eslint-disable-next-line
            if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message}`;}

            // eslint-disable-next-line 
            if(dados.length == 0)
            {
                document.getElementById("valores").innerHTML = '<button><p>Não há documentos.</p><p></p><p></p></button>';
                    
            }
            else
            {

               
                    
                    if(dados == null)
                    {
                        var arrayInicial = [];   
                        arrayInicial.push(
                            // eslint-disable-next-line
                            String('<button><p> Sem dados disponíveis. </p>'
                            +'<p> - </p>'
                            +'<p> - </p>'
                            +'</button>')                        
                        );
                        document.getElementById("valores").innerHTML = arrayInicial;  
                    }
                    else
                    {
                        
                        document.getElementById("valores").innerHTML = null;      
                        for(let i=0; i<dados.length; i++){                                            

                            var data = new Date(dados[i].data).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).toString();
                            var titulo = dados[i].titulo.length < 20 ? titulo = dados[i].titulo+'<br></br>'  : titulo = dados[i].titulo.substring(0,40);
                        
                            const btn = document.createElement("button");
                            btn.id = i;

                            const p1 = document.createElement("p");
                            p1.innerHTML = "Título do vídeo: "+titulo;

                            const p2 = document.createElement("p");
                            p2.innerHTML = "Data: "+data;

                            const p3 = document.createElement("p");
                            p3.innerHTML = "- "; //tipo

                            const a = document.createElement("a");
                            a.innerHTML = "Ver vídeo";
                            a.addEventListener("click", (e) => toggleModal(dados[i].link));     

                            btn.appendChild(p1);
                            btn.appendChild(p2);
                            btn.appendChild(p3);
                            btn.appendChild(a);
                                           
                            document.getElementById("valores").appendChild(btn);
                        
                        }
                    
                }
            }            
        });     
             

       
        
        
    },[refreshKey]); 


    // menu pesquisa
    const [pesquisa, setPesquisa] = React.useState("");
    function handleSubmit(event) {

        document.getElementById("valores").innerHTML = '<button><p>Carregando...</p><p></p><p></p></button>';
        
        // Change URL without reloading webpage
        const search = window.location.search;
        const params = new URLSearchParams(search);

        params.set("pesquisa", pesquisa)      

        //console.log(params.toString());
        window.history.replaceState({}, "", String(window.location.pathname+"?"+params.toString()));
        
        const redireciona = setInterval(function () {

            setRefreshKey(oldKey => oldKey +1); 
            clearInterval(redireciona);

        }, 2000);    
    }   
    
    return(
        <>   
            <Header />
            <CSSPadrao>
                <div className="licitacao">
                    <div className="tituloPagina"> 
                        <p id="titu" >Vídeos</p>
                    </div>        

                    <div className="licitacaoOpt" >
                             
                        <div className="LicitacaoOptions">
                            <p>Pesquisar</p>
                            <input type="text" name="pesquisa" id="pesquisa" placeholder="Digite: " autoComplete="off" onChange={(e) => setPesquisa(e.target.value)} ></input>
                            <p onClick={handleSubmit}>Ir</p>
                        </div>             
                        
                    </div>   

                    <div className="licitacaoCard" >
                        <div id="valores">{loading()}</div>
                    </div>    

                     {isOpen && (
                        <div className="modal-wrapper">
                        <FramePopUp toggle={toggleModal} state={isOpen} video={videoUrl}/>
                        </div>
                    )}   

                </div>
                </CSSPadrao>
            <Footer/>
        </>   
    );
}

export default Pessoa;