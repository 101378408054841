import React, { useEffect, useState} from "react";
import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/header";
import './Plenaria.css'
import carregando from '../../components/Geral/loading.gif';
import {base_url, midia_url} from '../../environment'
import { useParams } from "react-router-dom";
import FramePopUp from './FramePopUp'
import styled from "@emotion/styled";
var CSSPadrao = styled.div`${localStorage.getItem("cssGeral")}`

function Pessoa(){

    function loading() {
        return <div id='loading'><img src={carregando} alt="Carregando"/></div>;
    }

    const [isOpen, setIsOpen] = useState(false);
    const [videoUrl, setvideoUrl] = useState(''); 
    const {slug} = useParams(); 

    function toggleModal(id){
        //console.log(id);
        setvideoUrl(id);
        //console.log(videoUrl);
        //console.log("isOpen antes:", isOpen);
        setIsOpen(!isOpen);
        //console.log("isOpen depois:", isOpen);
    };

    // pegar dados do concurso especifico
    useEffect(() => {
        fetch(`${base_url}/public/camara/sessoes-plenarias/${slug}`)
        .then((d) => d.json())
        .then(dados => {      
            //console.log(dados); 

            // eslint-disable-next-line
            if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message}`;}

            var arrayInicial = [];        
            
            let data = new Date(dados.data).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).toString();

            // eslint-disable-next-line
            document.getElementById("card1").innerHTML = String('<p>'+dados.titulo+'</p><p>Nº '+dados.numero+'</p>'+'<p>Tipo: '+dados.tipo+'  |   Data: '+data+'  <a target="_blank" id="videu" ><button>Ver vídeo</button></a></p>') 

            setTimeout(() => {                    
                document.getElementById("videu").addEventListener("click", (e) => toggleModal(dados.link_youtube));     
            }, "1000");

            arrayInicial.push(
                // eslint-disable-next-line
                String('<p>DOCUMENTOS</p>')
            
            );

            arrayInicial.push(
                // eslint-disable-next-line
                String('<p> ATA <a target="_blank" href='+midia_url+dados.ata+'><button>Baixar (Download)</button></a></p>')
            ); 

            arrayInicial.push(
                // eslint-disable-next-line
                String('<p> PAUTA <a target="_blank" href='+midia_url+dados.pauta+'><button>Baixar (Download)</button></a></p>')
            ); 

            //console.log(arrayInicial);  
            document.getElementById("card2").innerHTML = arrayInicial;   
            document.getElementById("titu").innerHTML = dados.titulo;

            
        });     
    });  
    
    return(
        <>   
            <Header />
            <CSSPadrao>
                <div className="concursoEsp">
                    <div className="tituloPagina"> 
                        <p id="titu" >Carregando...</p>
                    </div>   
                    <div className="InicialNome"> 
                        <p id="titu2" ></p>
                    </div>                    
                    <div className="concursoEspCard" >
                        <div></div>
                        <div id="card1">{loading()}</div>                                        
                    </div>
                    <div className="concursoEspCard linha" >
                        <div></div>
                        <div id="card2">{loading()}</div>                    
                    </div>
                       
                    {isOpen && (
                        <div className="modal-wrapper">
                        <FramePopUp toggle={toggleModal} state={isOpen} video={videoUrl}/>
                        </div>
                    )}   
                </div>
                </CSSPadrao>
            <Footer/>
        </>   
    );
}

export default Pessoa;