import React, { useEffect, useState } from "react";
import { base_url } from "../../environment";
import Header from "../../components/Header/header";
import Footer from "../../components/Footer/footer";
import { Link } from "react-router-dom";
import { formatarNumero } from "../../environment/formatNumber";
import "./style/esic.css";
import { AiOutlineDownload } from "react-icons/ai";
import ModalDocuments from "./components/ModalDocuments";
import GraficoPizza from "./components/GraficoPizza";
import styled from "@emotion/styled";
var CSSPadrao = styled.div`${localStorage.getItem("cssGeral")}`

export default function ESic() {
  const [esic, setEsic] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [grafico, setGrafico] = useState({});

  const [responsavel, setResponsavel] = useState('');

  const toggleModal = () => {
  
    setIsOpen(!isOpen);
    
  };

  useEffect(() => {
    async function fetchData1() {
      try {
        const url = await fetch(
          `${base_url}/public/ouvidoria/relatorio-status`
        );

        const resource = await url.json();
        // eslint-disable-next-line
        if((resource.statusCode != undefined) && (resource.statusCode != 200)){window.location.pathname = `mensagem/${resource.message}`;}
       
        setGrafico(resource);
       
      } catch (error) {
        console.log("requisição não de certo");
      }
    }
    fetchData1();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const url = await fetch(`${base_url}/public/esic`);

        const resource = await url.json();

        // eslint-disable-next-line
        if((resource.statusCode != undefined) && (resource.statusCode != 200)){window.location.pathname = `mensagem/${resource.message}`;}

        setResponsavel(resource.Responsavel.nome)

        setEsic(resource);
        
      } catch (error) {
        console.log("requisição não de certo");
      }
    }
    fetchData();
  }, []);

  return (
    <div className="esic-container">
      <Header />
      <CSSPadrao>
      <div className="colorFirstContainer">

        <h1 className="tituloPagina">E-Sic</h1>

        <div className="content-esic">
          <div className="esic-text">
            <div className="esic-info-text">
              <div>
                <h2>O que é o E-sic ?</h2>
                <p>
                  O Sistema Eletrônico do Serviço de Informações ao Cidadão
                  (e-SIC) permite que qualquer pessoa, física ou jurídica,
                  solicite informações públicas, acompanhe o prazo e receba a
                  resposta da solicitação realizada. O cidadão ainda pode entrar
                  com recursos e apresentar reclamações sem burocracia. Em
                  cumprimento a{" "}
                  <a
                    href="http://www.planalto.gov.br/ccivil_03/_ato2011-2014/2011/lei/l12527.htm"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    A Lei Federal nº 12.527/2011
                  </a>
                  , que encontra-se em período de regulamentação neste Município,
                  assim como está ocorrendo nos demais poderes, Municipais,
                  Estaduais e Federais, disponibilizamos neste site, todas as
                  informações sobre pedidos de informações relativas a este
                  Município, para conhecimento da população.
                </p>
              </div>

              <div>
                <p>
                  Caso prefira fazer ou entregar a sua solicitação pessoalmente,
                  faça o download do formulário de requerimento de informações e
                  entregue no endereço do SIC Presencial.
                </p>
                <button onClick={toggleModal}>
                  <AiOutlineDownload /> DOWNLOAD{" "}
                </button>

                {isOpen && (
                  <div className="modal-wrapper">
                    <ModalDocuments toggle={toggleModal} state={isOpen} />
                  </div>
                )}
              </div>
            </div>

            <div className="esic-info-text">
              <div>
                <h2>E- sic físico </h2>
                <p>
                  {" "}
                  <b>Responsável: </b> {responsavel}
                </p>
                <p>
                  {" "}
                  <b>E-mail: </b> {esic.email}
                </p>
                <p>
                  <b>Horário de funcionamento: </b>{" "}
                  {esic.expediente && esic.expediente}
                </p>
                <p>
                  {" "}
                  <b>Telefone: </b> {formatarNumero(esic.celular) || "-"} /{" "}
                  {formatarNumero(esic.fone) || "-"}
                </p>
                <p>
                  <b>Endereço: </b>{" "}
                  {esic.endereco && esic.endereco}
                </p>

                <div className="form-esic-btnManifestação">
                  <Link to={`/esic/solicitacao`}>
                    <button>SOLICITAR INFORMAÇÃO</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h1 className="tituloPagina">Solicitações</h1>     

        <div className="ouvidoria-manifestacao-grafico">
          <GraficoPizza grafico={grafico} />
        </div>

        <div className="ouvidoria-manifestacao-container">
          <div id="ouvidoria-manisfetacao-info">
            <div>
              <h2>Indeferido</h2>
              <p>
                Demonstração de satisfação ou agradecimento por serviço prestado
                pela Prefeitura de Paraíso do Tocantins - TO.
              </p>
            </div>

            <div>
              <h2>Respondidas</h2>
              <p>
                Demonstração de satisfação ou agradecimento por serviço prestado
                pela Prefeitura de Paraíso do Tocantins - TO.
              </p>
            </div>
          </div>

          <div id="ouvidoria-manisfetacao-info">
            <div>
              <h2>Em Tramição</h2>
              <p>
                Demonstração de satisfação ou agradecimento por serviço prestado
                pela Prefeitura de Paraíso do Tocantins - TO.
              </p>
            </div>

            <div>
              <h2>Fora do prazo</h2>
              <p>
                Demonstração de satisfação ou agradecimento por serviço prestado
                pela Prefeitura de Paraíso do Tocantins - TO.
              </p>
            </div>
          </div>

        </div> 

      </div>
      </CSSPadrao>
      <Footer />
    </div>
  );
}
