
export function formatarNumero(numero) {
    // Remover todos os caracteres não numéricos
    if (numero !== undefined && numero !== null) {
        const numeroLimpo = numero.replace(/\D/g, '');
        
        const ddd = numeroLimpo.slice(0, 2);
        if(numeroLimpo === 11){
            const primeiraParte = numeroLimpo.slice(2, 7);
            const segundaParte = numeroLimpo.slice(7, 11);
            return `(${ddd}) ${primeiraParte}-${segundaParte}`;
        }else{
            const primeiraParte = numeroLimpo.slice(2, 6);
            const segundaParte = numeroLimpo.slice(6, 11);
            return `(${ddd}) ${primeiraParte}-${segundaParte}`;
        }
    }
     
  }