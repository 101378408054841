import React, { useState, useEffect } from "react";
import {base_url, midia_url} from '../../environment'
import {
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill
} from "react-icons/bs";
import { FaTimes } from "react-icons/fa";
import styled from "@emotion/styled";
import './main2.css';

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); // Cor do fundo desfocado
  backdrop-filter: blur(5px); // Efeito de desfoque
  z-index: 9999999; // Certifique-se de que o backdrop esteja na frente do conteúdo
`;


const ModalContainer = styled.div`
  position: fixed;
  top: 25%;
  right: 10%;
  left: 10%;
  //transform: translate(0, -50%);
  width: 70%;
  min-height: auto;
  max-width: 100vh;
  
  border-radius: 5px;
  filter:drop-shadow(2px 2px 10px black);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //padding: 10px;
  //margin-top: 9rem;
  z-index: 100;

  @media (min-width: 1400px) and (min-height: 700px){
    left: 27%;
  }  

  @media (max-height: 700px){
    left: 35%;
  }  

  @media (max-height: 700px) and (max-width: 1200px){
    left: 15%;
  }  

  @media (max-width: 500px){
    width:95%;
    right: 2%;
    left: 2%;
  }  

`;



const ImageSlider = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const NavigationButtons = styled.div`
  position: absolute;
  top: 50%;
  left: 0%;
  right: 0%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
 
`;

const ArrowButton = styled.button`
  color: #fff;
  font-size: 24px;
  background-color: #004348;
  border: none;
  cursor: pointer;
  transition: color 0.3s;
  padding: 10px;
  display: flex;
  justify-content: center;
  border-radius: 10px;

  &:hover {
    color: #fff;
    background-color: #004348;
  }
 
`;

const CloseIcon = styled(FaTimes)`
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  background-color: rgba(229, 103, 103, 0.856);
  padding: 5px;
  font-size: 19px;
  border-radius: 50%;
  margin: -17px;
  cursor: pointer;
  z-index: 1; /* Adicionado */
`;
const ImageLink = styled.a`
  text-decoration: none; // Opcional: remover decoração de texto do link

  :target{
    target: "_blank";

  }
  
`;

const Modal = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % popup1.length);
    setvisto(visto + 1);
  };

  const prevImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + popup1.length) % popup1.length
    );

    if(visto > 0)
    {
      setvisto(visto - 1);
    };
  };

  

  const [popup1, setPopup1] = useState([]);

  const [visto, setvisto] = useState(1);
 
  const [isOpen, setIsOpen] = useState(true);

  if (isOpen !== false) {
    document.body.classList.add('is-open');
  } else {
    document.body.classList.remove('is-open');
  }



  const closeModal = () => {
    if(visualizados()){
      setIsOpen(false);
      localStorage.setItem('PopupCentral', "OK");
    }
    else
    {
      nextImage();
    }
  };

  const visualizados = () => {
    //console.log(visto)
    if(visto >= popup1.length)
    {
      return true;
    }
    else
    {
      return false;
    }
  };
  
 

  useEffect(() => {
    async function fetchData() {
      try {
        const url = await fetch(`${base_url}/public/popups/central`);
        const resource = await url.json();

        // eslint-disable-next-line
        if((resource.statusCode != undefined) && (resource.statusCode != 200)){window.location.pathname = `mensagem/${resource.message}`;}
    
        //console.log(resource);

       setPopup1(resource)
        //console.log(resource);
      } catch (error) {
        console.log('requisição não deu certo');
      }
    }

    fetchData();

    
  if(localStorage.getItem('PopupCentral') == "OK")
  {
    //console.log(localStorage.getItem('PopupCentral'))
    document.getElementById("popupCentral").hidden = true;
  }
  
  }, []);

  return (
    <div id="popupCentral">
      {isOpen && (
        <Backdrop>
          <ModalContainer>
            <CloseIcon size={20} onClick={closeModal} />
            <ImageSlider>
              <NavigationButtons>
                <ArrowButton onClick={prevImage}>
                  <BsFillArrowLeftCircleFill />
                </ArrowButton>
                <ArrowButton onClick={nextImage}>
                  <BsFillArrowRightCircleFill />
                </ArrowButton>
              </NavigationButtons>
              <ImageLink href={popup1[currentImageIndex]?.link}>
                <Image
                  key={currentImageIndex}
                  src={midia_url + popup1[currentImageIndex]?.imagem}
                  alt="Imagem"
                />
              </ImageLink>
            </ImageSlider>
          </ModalContainer>
        </Backdrop>
      )}
    </div>
  );
};

export default Modal;