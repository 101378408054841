import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import '../../faleConosco/faleConosco.css';

function ModalDocuments(props) {

  return (
    <>
      {props.state && (
        <div className="modal">
          <TableContainer className="modal-content">
            <span className="close" onClick={props.toggle}>
              &times;
            </span>
            
         
            <Table sx={{minWidth: 650}} arial-label="caption table" className="table">
              <TableHead>
                <TableRow>
                  <TableCell>Escolha o tipo de formulário:</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                    <TableRow>
                        <TableCell>
                          <a href='./Documents/form-requerimento_pf.doc' download='form-requerimento_pf.doc'> Formulário de Requerimento Pessoa Física </a>
                        </TableCell>                    
                    </TableRow>       
                    <TableRow>
                        <TableCell>
                          <a href='./Documents/form-requerimento-pj.doc' download='form-requerimento_pj.doc'> Formulário de Requerimento Pessoa Jurídica</a>
                        </TableCell>                        
                    </TableRow>       
              </TableBody>
            </Table>
         
            <Button onClick={props.toggle} variant="outlined">Fechar</Button>

          </TableContainer>
        </div>
      )}
    </>
  );
}

export default ModalDocuments;
