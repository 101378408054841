import { useEffect, useState } from "react";
import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/header";
import carregando from '../../components/Geral/loading.gif';
import '../noticias/Noticias.css'
import {base_url, midia_url, foto_default, error_default} from '../../environment'
import Skeleton from "@mui/material/Skeleton";
import styled from "@emotion/styled";
var CSSPadrao = styled.div`${localStorage.getItem("cssGeral")}`

var urlGeral = base_url+'/public/noticias';
var urlSecretarias =  base_url+'/public/prefeitura/secretarias';

var qtdPaginas=1, page, inicio = 0, fim, urlPagina,  tamanhoFixo = 9, orgao_slug;
var umaVez=true;
var unaVez=true;
var pinaVez=true;
var optionClicado = "background-color: var(--mainColor); color: var(--textColor2);"

var secretarias= [];

function Noticias(){

    function loading() {
        return <div id='loading'><img src={carregando} alt="Carregando"/></div>;
    }  

    const [noticiasArray, setnoticiasArray] = useState([]);
       
    const search = window.location.search;
    const params = new URLSearchParams(search);
    page = params.get('page');
    if(page == null)page = 1;

    inicio = tamanhoFixo * (page -1); 
    fim = tamanhoFixo * page;

    orgao_slug = params.get('orgao_slug');    
    localStorage.setItem("secSelecionada", orgao_slug);

    if(orgao_slug == null){
        urlPagina = String(urlGeral+"?&start="+inicio+"&size="+fim);
    }
    else
    {
        urlPagina = String(urlGeral+"?start="+inicio+"&size="+fim+"&orgao_slug="+orgao_slug);
    }

    // preencher botoes secretarias
    if(unaVez)
    {
        fetch(urlSecretarias)
        .then((d) => d.json())
        .then(dados => {      
            //console.log(dados.length); 
                // eslint-disable-next-line
            if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message}`;}
                    
            var qtdSecretarias = dados.length;  

            if(orgao_slug == null)
            {
                document.getElementById('none').style = optionClicado;
            }

            var mover = null;
            
            
            for(let i=0; i<qtdSecretarias; i++){

                //guarda dados das secretarias
                secretarias.push(dados[i]);
            
                var soNome = dados[i].nome.substring(13)
                const butin = document.createElement("button");  
                butin.innerHTML = soNome;

                // eslint-disable-next-line
                if(orgao_slug == dados[i].slug)
                {
                    butin.style = optionClicado;
                    document.getElementById('none').style = null;
                    mover = String(dados[i].slug);
                }                   

                const link = document.createElement("a");
                link.href = String("?orgao_slug="+dados[i].slug);
                link.target = "_self";
                link.id = String(dados[i].slug);

                link.appendChild(butin);
                document.getElementById("secretarias").appendChild(link);


            }
            
            if(mover != null)
            {
                document.getElementById(mover).scrollIntoView({ behavior: "smooth", block: "end" });
            }

            //console.log(secretarias); 
        });

        unaVez=false;
    }  

    // pegar dados das noticias (intervalo)
    useEffect(() => {
        if(pinaVez)
        {
            fetch(urlPagina)
            .then((d) => d.json())
            .then(dados => {      

                //setnoticiasArray(dados);
   

                var noticias = [];
                for(let i=0; i<=dados.length; i++){

                    if(dados[i] == null)
                    {
                        if(i > 0)break;
                        noticias.push(
                            // eslint-disable-next-line
                            String('<a target="_self" href=/pontos-turisticos rel="noreferrer"><button><img src='+error_default+
                            ' alt="Noticia"/><p></p><p>Não há dados. </p></button></a>')
                        );   
                    }
                    else
                    {

                        var origemNoticia = window.location.origin+"/noticia?slug="+dados[i].slug;

                        var imgSource;
                        if(dados[i].logo == null){
                            imgSource = error_default;}
                        else{
                            imgSource = midia_url+dados[i].logo;
                        }
                        
                        noticias.push(
                            // eslint-disable-next-line
                            String('<a target="_self" href='+origemNoticia+' rel="noreferrer"><button><img src='+midia_url+dados[i].capa+' alt="Noticia"/><p>'+dados[i].titulo+': '+dados[i].subtitulo+'</p><p>'+nomeSecretaria(dados[i].Orgao)+'</p></button></a>')
                        
                        );
                    }
                }                  
                document.getElementById("dados").innerHTML = noticias; 

            });
            
            pinaVez=false;
        }
    }, []);

    // verificar quantas páginas tem
    if(umaVez)
    {
        if(orgao_slug == null){
            fetch(urlGeral)
            .then((d) => d.json())
            .then(dados => {      
                //console.log(dados.length); 
                // eslint-disable-next-line
                if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message}`;}

                var extrapolou = dados.length % tamanhoFixo;
                qtdPaginas = (dados.length - extrapolou) / tamanhoFixo;
                // eslint-disable-next-line
                if(extrapolou != 0) qtdPaginas = qtdPaginas + 1;
                //console.log(qtdPaginas);    
                
                var movepag = null;
                
                for(let i=1; i<=qtdPaginas; i++){

                    const index = document.createElement("a");
                    index.href = "noticias?page="+i;
                    const butao = document.createElement("button");
                    butao.innerHTML = i;
                    butao.id = i;


                    // eslint-disable-next-line
                    if(page == i)
                    {
                        butao.style = optionClicado;
                        movepag = String(i);
                    }  

                    index.appendChild(butao);
                    document.getElementById("indexNoticias").appendChild(index);   
                }

                if(movepag != null)
                {
                    document.getElementById(movepag).scrollIntoView({ behavior: "smooth", block: "end" });
                }                
                
                document.getElementById("ultimaPagina").href = 'noticias?page='+ qtdPaginas;
                    
            });
        }
        else
        {
            fetch(urlPagina)
            .then((d) => d.json())
            .then(dados => {      
                //console.log(dados.length); 
                // eslint-disable-next-line
                if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message}`;}

                var extrapolou = dados.length % tamanhoFixo;
                qtdPaginas = (dados.length - extrapolou) / tamanhoFixo;
                // eslint-disable-next-line
                if(extrapolou != 0) qtdPaginas = qtdPaginas + 1;
                //console.log(qtdPaginas);     
                
                
                    for(let i=1; i<=qtdPaginas; i++){

                        const index = document.createElement("a");
                        index.href = "noticias?page="+i+"&orgao_slug="+orgao_slug;
                        const butao = document.createElement("button");
                        butao.innerHTML = i;
                        butao.id = i;

                        index.appendChild(butao);
                        document.getElementById("indexNoticias").appendChild(index);   
                    }

                
                
                
                document.getElementById("ultimaPagina").href = 'noticias?page='+ qtdPaginas +"&orgao_slug="+orgao_slug;
                document.getElementById("primeiraPagina").href = 'noticias?page=1&orgao_slug='+orgao_slug;  
            });
        }     
        
        umaVez=false;
    }   
   
    // pular dez apenas se tiver páginas suficientes
    if((qtdPaginas-page) > 10){
        document.getElementById("menos10").href = 'noticias?page='+ (page - 10);
        document.getElementById("mais10").href = 'noticias?page='+ (page + 10);
    } 

    function nomeSecretaria(valor){
        if(valor != null)
        {
            //console.log(valor);
            return valor.nome.substring(13);
        }
        else
        {
            return "Geral";
        }
    }

    function slugSecretaria(valor){
        if(valor != null)
        {
            return valor.slug;
        }
        else
        {
            return "Geral";
        }
    }



    return(
        <>   
            <Header />
            <CSSPadrao>
                <p id="pagNoticias" className="pagNoticias">Notícias da secretaria de: </p>
                <div className="secretarias">
                    <div id="secretarias" >
                        <a href="noticias" target="_self"><button id="none">Todas</button></a>
                    </div>   
                </div>

                <div className="ptsTuristicos">
                    <div id="ptsTuristicos" >
                    </div>                    
                    
                    <div className="noticiasInfo" >
                        <div id='dados'>{loading()} </div>  
                    </div>   
                </div>
                
                <div className="menuNoticias">
                    <div id="indexNoticias"></div>
                    <div>
                        <a id="primeiraPagina" href="noticias" ><button >Primeira</button></a>
                        <a id="menos10" href="noticias" ><button >-10 páginas</button></a>
                        <a id="mais10" href="noticias" ><button >+10 páginas</button></a>
                        <a id="ultimaPagina" href="noticias" ><button >Última</button></a>
                    </div>
                </div>
            </CSSPadrao>
            <Footer/>
        </>   
    );
}

export default Noticias;