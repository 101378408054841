import React, { useEffect, useState} from "react";
import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/header";
import './Contratos.css'
import carregando from '../../components/Geral/loading.gif';
import {base_url} from '../../environment'
import styled from "@emotion/styled";
var CSSPadrao = styled.div`${localStorage.getItem("cssGeral")}`

var optionClicado = "background-color: var(--mainColor); color: var(--textColor2);"

var urlDocs, essaVez=true, cadavez=true, pinaVez=true;

function Pessoa(){

    function loading() {
        return <div id='loading'><img src={carregando} alt="Carregando"/></div>;
    }

    const [refreshKey, setRefreshKey] = useState(0);

    const search = window.location.search;
    const params = new URLSearchParams(search);
    //console.log(String(params))

    var tipo_contrato_id = params.get('tipo_contrato_id');
    var licitacao_id = params.get('licitacao_id');
    var fornecedor = params.get('fornecedor');
    
    //console.log(fornecedor)
   
    if(String(params) == ""){
        urlDocs = String(base_url+"/public/contratos");
    }
    else
    {
        urlDocs = String(base_url+"/public/contratos"+"?"+params.toString());
    }

    // pegar dados tipo contrato
    if(essaVez){
        fetch(`${base_url}/public/tipos-contrato`)
        .then((d) => d.json())
        .then(dados => { 


            // eslint-disable-next-line 
            if(dados.length == 0)
            {
                window.location.pathname = `mensagem/Não há tipos de contrato.`;    
            }
            else
            {
                //console.log(dados);
                

                var dado = document.getElementById("tipo_contrato_id");   

                const option = document.createElement("option");
                option.value = "";
                option.innerHTML = "--";      
                option.id = "todos";        
                dado.appendChild(option);
                            
                for(let i=0; i<dados.length; i++){
                    const option = document.createElement("option");
                    option.value = dados[i].id;
                    option.id = dados[i].id;
                    option.innerHTML = dados[i].nome;                  
                    dado.appendChild(option);
                }

                if(tipo_contrato_id != null)
                document.getElementById(tipo_contrato_id).selected = true;
           


            }            
        })    
        .catch(error => { 
            console.log(error.message);
            if(error.response)
            {
                // eslint-disable-next-line
                if(error.dados.statusCode == 401)
                {
                    window.location.pathname = `login`;
                }
                else{
                    window.location.pathname = `mensagem/${error.message}`;
                }  
            }
            else{
                window.location.pathname = `mensagem/${error.message}`;
            }            
        });
        essaVez=false;
    }

    // pegar dados licitacoes
    if(cadavez){
        fetch(`${base_url}/public/licitacoes`)
        .then((d) => d.json())
        .then(dados => { 


            // eslint-disable-next-line 
            if(dados.length == 0)
            {
                window.location.pathname = `mensagem/Não há licitações.`;    
            }
            else
            {
                //console.log(dados);
                

                var dado = document.getElementById("licitacao_id");   

                const option = document.createElement("option");
                option.value = "";
                option.innerHTML = "--";      
                option.id = "todos";        
                dado.appendChild(option);
                            
                for(let i=0; i<dados.length; i++){
                    const option = document.createElement("option");
                    option.value = dados[i].id;
                    option.id = dados[i].id;
                    option.innerHTML = dados[i].numero;                  
                    dado.appendChild(option);
                }

                if(licitacao_id != null)
                document.getElementById(licitacao_id).selected = true;

            }            
        })    
        .catch(error => { 
            console.log(error.message);
            if(error.response)
            {
                // eslint-disable-next-line
                if(error.dados.statusCode == 401)
                {
                    window.location.pathname = `login`;
                }
                else{
                    window.location.pathname = `mensagem/${error.message}`;
                }  
            }
            else{
                window.location.pathname = `mensagem/${error.message}`;
            }            
        });
        cadavez=false;
    }

    // pegar dados fornecedor
    if(pinaVez){
        fetch(`${base_url}/public/fornecedores`)
        .then((d) => d.json())
        .then(dados => { 


            // eslint-disable-next-line 
            if(dados.length == 0)
            {
                window.location.pathname = `mensagem/Não há fornecedores.`;    
            }
            else
            {
                //console.log(dados);
                

                var dado = document.getElementById("fornecedor");   

                const option = document.createElement("option");
                option.value = "";
                option.innerHTML = "--";      
                option.id = "todos";        
                dado.appendChild(option);
                            
                for(let i=0; i<dados.length; i++){
                    const option = document.createElement("option");
                    option.value = dados[i].id;
                    option.id = dados[i].id;
                    option.innerHTML = dados[i].nome;                  
                    dado.appendChild(option);
                }

                if(fornecedor != null)
                document.getElementById(fornecedor).selected = true;

            }            
        })    
        .catch(error => { 
            console.log(error.message);
            if(error.response)
            {
                // eslint-disable-next-line
                if(error.dados.statusCode == 401)
                {
                    window.location.pathname = `login`;
                }
                else{
                    window.location.pathname = `mensagem/${error.message}`;
                }  
            }
            else{
                window.location.pathname = `mensagem/${error.message}`;
            }            
        });
        pinaVez=false;
    }
    
    // pegar dados dos contratos
    // filtros e funcoes no final
    useEffect(() => {
        fetch(urlDocs)
        .then((d) => d.json())
        .then(dados => { 

            // eslint-disable-next-line
            if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message}`;}

            // eslint-disable-next-line 
            if(dados.length == 0)
            {
                document.getElementById("valores").innerHTML = '<button><p>Não há contratos.</p><p></p><p></p></button>';
                    
            }
            else
            {
                var arrayInicial = [];
                
                for(let i=0; i<dados.length; i++){

                    if((dados == null) || (dados[i] == null))
                    {
                        arrayInicial.push(
                            // eslint-disable-next-line
                            String('<button><p> Sem dados disponíveis. </p>'
                            +'<p> - </p>'
                            +'<p> - </p>'
                            +'</button>')                        
                        );
                        break;
                    }

                    var dataInicio = new Date(dados[i].data_inicio).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).toString();
                    var dataFim = new Date(dados[i].data_fim).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).toString();

                    var fornecedor = dados[i].Fornecedor[0].nome.length < 20 ? fornecedor = dados[i].Fornecedor[0].nome +'<br></br>'  : fornecedor = dados[i].Fornecedor[0].nome.substring(0,50);
                                      
                
                    arrayInicial.push(
                        // eslint-disable-next-line
                        String('<button><p>Contrato nº '+dados[i].numero+'</p>'   
                        +'<p>Data inicial: '+dataInicio+'</p>'                                   
                        +'<p>Data final: '+dataFim+'</p>'           
                        +'<p>Fornecedor (1º): '+fornecedor+'</p>'  
                        +'<p>Tipo: '+dados[i].Tipo.nome+'</p>'      
                        +'<a target="_self" href=../contratos/'+dados[i].slug+'>Saiba mais</a>'
                        +'</button>')
                        
                    ); 
                    
                }
                document.getElementById("valores").innerHTML = arrayInicial;   
            }            
        });     
             

        //chama funcao ao selecionar o fornecedor
        document.getElementById("fornecedor").addEventListener("change", (e) => filtros(document.getElementById("fornecedor").value, null, null));
        
        
        //chama funcao ao selecionar a tipo_contrato_id
        document.getElementById("tipo_contrato_id").addEventListener("change", (e) => filtros(null, document.getElementById("tipo_contrato_id").value, null));
        
        //chama funcao ao selecionar o licitacao_id
        document.getElementById("licitacao_id").addEventListener("change", (e) => filtros(null, null, document.getElementById("licitacao_id").value));
        
    },[refreshKey]); 

    function primeiraMauiscula(valor) {
        
        var str = valor.replace("_", " ");
        str = str.toLowerCase();
        const words = str.split(' ');
        for (let i = 0; i < words.length; i++) {
            words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
        }
        return words.join(' ');
    }

    function filtros(valor1, valor2, valor3){

        document.getElementById("valores").innerHTML = '<button><p>Carregando...</p><p></p><p></p></button>';

        
        //window.location = String("licitacoes?modalidades-licitacao="+filtro);    
        
        // Change URL without reloading webpage
        const search = window.location.search;
        const params = new URLSearchParams(search);

        if(valor1 != null){params.set("fornecedor", valor1)}        
        if(valor2 != null)params.set("tipo_contrato_id", valor2);
        if(valor3 != null)params.set("licitacao_id", valor3);
        
        if (valor1 == ""){params.delete("fornecedor");}
        if (valor2 == ""){params.delete("tipo_contrato_id");}
        if (valor3 == ""){params.delete("licitacao_id");}

        //console.log(params.toString());
        window.history.replaceState({}, "", String(window.location.pathname+"?"+params.toString()));
        
        const redireciona = setInterval(function () {

            setRefreshKey(oldKey => oldKey +1); 
            clearInterval(redireciona);

        }, 2000);     
       // window.history.replaceState({}, "", String(window.location.pathname+window.location.search+"?modalidades-licitacao="+filtro));

    }

    // menu pesquisa
    const [pesquisa, setPesquisa] = React.useState("");
    function handleSubmit(event) {

        document.getElementById("valores").innerHTML = '<button><p>Carregando...</p><p></p><p></p></button>';
        
        // Change URL without reloading webpage
        const search = window.location.search;
        const params = new URLSearchParams(search);

        params.set("pesquisa", pesquisa)      

        //console.log(params.toString());
        window.history.replaceState({}, "", String(window.location.pathname+"?"+params.toString()));
        
        const redireciona = setInterval(function () {

            setRefreshKey(oldKey => oldKey +1); 
            clearInterval(redireciona);

        }, 2000);    
    }   
    
    return(
        <>   
            <Header />
            <CSSPadrao>
                <div className="licitacao">
                    <div className="tituloPagina"> 
                        <p id="titu" >Contratos</p>
                    </div>        

                    <div className="licitacaoOpt" >
                        
                        <div className="LicitacaoOptions">
                            <p>Fornecedor</p>
                            <select name="fornecedor" id="fornecedor">
                            </select>
                        </div>        

                        <div className="LicitacaoOptions">
                            <p>Tipo</p>
                            <select name="tipo_contrato_id" id="tipo_contrato_id">
                            </select>
                        </div>   


                        <div className="LicitacaoOptions">
                            <p>Licitação</p>
                            <select name="licitacao_id" id="licitacao_id">
                            </select>
                        </div>         

                        <div className="LicitacaoOptions">
                            <p>Pesquisar</p>
                            <input type="text" name="pesquisa" id="pesquisa" placeholder="Digite: " autoComplete="off" onChange={(e) => setPesquisa(e.target.value)} ></input>
                            <p onClick={handleSubmit}>Ir</p>
                        </div>             
                        
                    </div>   

                    <div className="licitacaoCard" >
                        <div id="valores">{loading()}</div>
                    </div>                       
                </div>
            <Footer/>
            </CSSPadrao>
        </>   
    );
}

export default Pessoa;