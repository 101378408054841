import { useEffect, useState } from "react";
import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/header";
import Carrousel from  '../../components/Galeria/Galeria'
import carregando from '../../components/Geral/loading.gif';
import './Noticia.css'
import {base_url, foto_default, midia_url} from '../../environment'
import Skeleton from "@mui/material/Skeleton";
import styled from "@emotion/styled";
var CSSPadrao = styled.div`${localStorage.getItem("cssGeral")}`

var linkAcaoPadrao = "_self", ptEnderecoLink;
var url = base_url+'/public/noticias';
var urlSecretarias = base_url+'/public/prefeitura/secretarias';
var caminhoGlobal = midia_url;

const images = [{original: carregando,},];
var umaVez = true, unaVez=true, pinaVez=true, orgao_slug;
var secretarias= [];

function Noticia(){
    
    const search = window.location.search;
    const params = new URLSearchParams(search);

    const [noticiaArray, setnoticiaArray] = useState([]);

    const slug = params.get('slug');
    // eslint-disable-next-line
    if(slug == null){window.location = "/*";}

    var orgao_slug = localStorage.getItem("secSelecionada");    


    //fltro das relacionadas lateral
    // eslint-disable-next-line
    if((orgao_slug != 'null') && (orgao_slug != 'undefined')  && (orgao_slug != null)){

        url = base_url+"/public/noticias?orgao_slug="+orgao_slug;
    } 

    // pegar info da secretaria pro botao 
    useEffect(() => {
        if(pinaVez){
            fetch(urlSecretarias)
            .then((d) => d.json())
            .then(dados => {      
                //console.log(dados.length); 
                            // eslint-disable-next-line
                if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message}`;}

                var qtdSecretarias = dados.length;
                        
                // eslint-disable-next-line
                if(orgao_slug != 'null'){
                    
                    for(let i=0; i<qtdSecretarias; i++){
                        // eslint-disable-next-line
                        if(dados[i].slug == orgao_slug)
                        {
                            //qualSecretaria = dados[i].nome.substring(13);
                            //console.log(dados[i].nome.substring(13)); 
                            document.getElementById("selecionada").innerHTML = ''+dados[i].nome.substring(13)+' &nbsp;&nbsp; &#10006';    
                        }                
                    }
                }   
                else
                {
                    document.getElementById("selecionada").innerHTML = 'Geral  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &#10006';    
                }      
                
                for(let i=0; i<qtdSecretarias; i++){

                    //guarda dados das secretarias
                    secretarias.push(dados[i]);
                }

                //console.log(secretarias); 

            });
            pinaVez=false;
        }
    });

    var urlEspecifica = base_url+'/public/noticias/' + slug;
    
    function loading() {
        return <div id='loading'><img src={carregando} alt="Carregando"/></div>;
    }
   
    // pegar dados da noticia principal
    useEffect(() => {

        if(umaVez)
            {
            fetch(urlEspecifica)
            .then((d) => d.json())
            .then(dados => {      
                //console.log(dados.length); 
                // eslint-disable-next-line
                if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message}`;}
            
                document.getElementById("ntEspTitulo").innerHTML = dados.titulo; 

                var divGeral = document.getElementById("destaque");            

                images.pop(); 

                if(dados.Imagem.length < 1){images.push({original: foto_default,},);}
       
                for(let i=0; i<dados.Imagem.length; i++){    
                        
                    images.push({original: caminhoGlobal+dados.Imagem[i].imagem,},);
                }
                            
                const noticiasInfo = document.createElement("p");
                let dataCorrigida = new Date(dados.data).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).toString();
                noticiasInfo.innerHTML = "Publicada em "+ dataCorrigida;
                noticiasInfo.className = "noticiasInfoEspecifica";

                const noticiasInfo2 = document.createElement("p");
                noticiasInfo2.innerHTML = "Visualizações: "+dados.views;  
                noticiasInfo2.className = "noticiasInfoEspecifica";

                
                const textoNoticiaEsp = document.createElement("p");
                textoNoticiaEsp.innerHTML = dados.conteudo;  
                textoNoticiaEsp.className = "textoNoticiaEspecifica";

                
                divGeral.appendChild(noticiasInfo); 
                divGeral.appendChild(noticiasInfo2);                 
                divGeral.appendChild(textoNoticiaEsp); 
                            
                
            });

            umaVez = false;
        }
    });

    // pegar dados das noticias relacionadas
    useEffect(() => {

        if(unaVez)
        {

            fetch(url)
            .then((d) => d.json())
            .then(dados => {      
                //console.log(dados.length); 
                
                // eslint-disable-next-line
                if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message}`;}
                
                var arrayInicial = [];
                var origemNoticia = '#';
                var mostraSecretaria;
                
                for(let i=0; i<3; i++){

                    //console.log(dados[i].Orgao);

                    if(dados[i] == null)
                    {
                        break;
                    }

                    mostraSecretaria = null;
                    
                    origemNoticia = "noticia?slug="+dados[i].slug;

                    mostraSecretaria = dados[i].Orgao.nome.substring(13);

                    arrayInicial.push(
                        // eslint-disable-next-line
                        String('<a target='+linkAcaoPadrao+ ' href='+origemNoticia+
                        ' rel="noreferrer"><button><img src='+midia_url+dados[i].capa+
                        ' alt="Ponto-Turistico"/><p>'+dados[i].titulo+
                        '</p><p>'+mostraSecretaria+'</p></button></a>')
                    );  
                    
                }
                
                if(orgao_slug == 'null')
                {
                    arrayInicial.push(
                        // eslint-disable-next-line
                        String('<a target='+linkAcaoPadrao+' href="/noticias" rel="noreferrer" id="maisPontos" ><button>Mais pontos</button></a>')
                    );  
                }
                else
                {
                    arrayInicial.push(
                        // eslint-disable-next-line
                        String('<a target='+linkAcaoPadrao+' href=/noticias?orgao_slug='+orgao_slug+' rel="noreferrer" id="maisPontos" ><button>Mais pontos</button></a>')
                    );  
                }
                

                document.getElementById("inicial").innerHTML = arrayInicial;       

            });
            unaVez=false;
        }
    });   

    
    function nomeSecretaria(valor){
        if(valor != null)
        {
            return valor.nome.substring(13);
        }
        else
        {
            return "Geral";
        }
    }

    

    return(
        <>   
            <Header />
            <CSSPadrao>
            <div className="ponto-turistico">
                    <p className="noticiaTitulo" id="ntEspTitulo"></p>
                    <a href="noticias" target="_self"><button className="selecionada" id="selecionada" >Carregando...  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &#10006;</button></a>
                    
                    <div className="noticiaEspecifico">
                        <div className="noticiaEspecificoInfo">
                            <div id='destaque'> <Carrousel image={images}/></div>    
                            <div id='inicial' className='noticiaOutros' >{loading()}</div>
                        </div>
                    </div>
                </div>
            </CSSPadrao>
            <Footer/>
        </>   
    );
}

export default Noticia;