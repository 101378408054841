import React from "react";
import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/header";
import './Mensagem.css'
import {Link} from "react-router-dom";
import {BiErrorCircle} from "react-icons/bi"
import { useParams } from "react-router-dom";
import styled from "@emotion/styled";
var CSSPadrao = styled.div`${localStorage.getItem("cssGeral")}`
function Mensagem(){

    const {mensagem} = useParams();
  
    return(
        <>   
            <Header />
            <CSSPadrao>
                <div className="pagina404"> 
                    <button className="erru" >< BiErrorCircle /></button>
                    <h1>{mensagem+"."}</h1>
                    <Link to={'/'}> <p>Ir para página inicial </p></Link>
                </div>       
            </CSSPadrao>        
            <Footer/>
        </>   
    );
}

export default Mensagem;