import React, { useEffect} from "react";
import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/header";
import './Proposicao.css'
import carregando from '../../components/Geral/loading.gif';
import {base_url, midia_url} from '../../environment'
import { useParams } from "react-router-dom";
import styled from "@emotion/styled";
var CSSPadrao = styled.div`${localStorage.getItem("cssGeral")}`

function Pessoa(){

    function loading() {
        return <div id='loading'><img src={carregando} alt="Carregando"/></div>;
    }

    const {slug} = useParams(); 

    // pegar dados do concurso especifico
    useEffect(() => {
        fetch(`${base_url}/public/camara/proposicoes/${slug}`)
        .then((d) => d.json())
        .then(dados => {      
            //console.log(dados); 

            // eslint-disable-next-line
            if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message}`;}

            var arrayInicial = [];      
            
            var comissao = dados.comissao = true ? "Comissão de Justiça e Cidadania da Cidade" : "Geral.";
            
            // eslint-disable-next-line
            document.getElementById("card1").innerHTML = String('<p>'+dados.titulo+'</p><p>Nº '+dados.numero+'</p>'+'<p>Comissão: '+comissao+'  |   Tipo: '+dados.Tipo.nome+'  |  Tipo Autoria: '+dados.TipoAutoria.nome+'  |  Status: '+dados.Status.nome+' </p>') 

            arrayInicial.push(
                // eslint-disable-next-line
                String('<p>EMENTA</p>')
            
            );

            arrayInicial.push(
                // eslint-disable-next-line
                String('<div><p>'+dados.ementa+'</p></div>')
            );  

            for(let i=0; i<dados.Anexo.length; i++){
               
                arrayInicial.push(
                    // eslint-disable-next-line
                    String('<p>'+dados.Anexo[i].titulo+'<a target="_blank" href='+midia_url+dados.Anexo[i].anexo+'><button>Baixar (Download)</button></a></p>')
                );  
            }

            //console.log(arrayInicial);  
            document.getElementById("card2").innerHTML = arrayInicial;   
            document.getElementById("titu").innerHTML = dados.titulo;

            var data = new Date(dados.data).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).toString();
            document.getElementById("titu2").innerHTML = 'Data: '+data;
            
        });     
    });  
    
    return(
        <>   
            <Header />
            <CSSPadrao>
                <div className="concursoEsp">
                    <div className="tituloPagina"> 
                        <p id="titu" >Carregando...</p>
                    </div>   
                    <div className="InicialNome"> 
                        <p id="titu2" ></p>
                    </div>                    
                    <div className="concursoEspCard" >
                        <div></div>
                        <div id="card1">{loading()}</div>                                        
                    </div>
                    <div className="concursoEspCard linha proposicaoTxt" >
                        <div></div>
                        <div id="card2">{loading()}</div>                    
                    </div>
                       
                </div>
                </CSSPadrao>
            <Footer/>
        </>   
    );
}

export default Pessoa;