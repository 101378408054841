import React from 'react';
import ReactDOM from 'react-dom/client';

import Home from './pages/home/Home';
import Municipio from './pages/municipio/Municipio';
import Galeria from './pages/Galeria/Galeria'
import GaleriaEspecifica from './pages/GaleriaEspecifica/GaleriaEspecifica';
import Noticia from './pages/noticia/Noticia';
import Noticias from './pages/noticias/Noticias';
import Organograma from './pages/Organograma/Organograma';
import Pessoas from './pages/pessoas/Pessoas'
import Pessoa from './pages/pessoa/Pessoa';
import Orgaos from './pages/orgaos/Orgaos';
import Orgao from './pages/orgao/Orgao';
import Page404 from './pages/404/404';
import Mensagem from './pages/mensagem/Mensagem';
import FaleConosco from './pages/faleConosco/FaleConosco';
import Ouvidoria from './pages/Ouvidoria/Ouvidoria';
import Concurso from './pages/concurso/Concurso'
import ConcursoEspecifico from './pages/concursoEspecifico/ConcursoEspecifico'
import PontoTuristico from './pages/pontoTuristico/PontoTuristico';
import PontosTuristicos from './pages/pontosTuristicos/PontosTuristicos';
import Manifestacao from './pages/Ouvidoria/Manifestacao.tsx';
import ManifestacaoMostrar from './pages/Ouvidoria/ManifestacaoMostrar.tsx';
import Solicitacao from './pages/Ouvidoria/Solicitacao.tsx';
import SolicitacaoMostrar from './pages/Ouvidoria/SolicitacaoMostrar.tsx';
import ESic from './pages/Ouvidoria/E-Sic';
import FAQ from './pages/FAQ/PerguntasFrequentes';
import Chat from './pages/Ouvidoria/components/Chat';
import Pesquisa from './pages/pesquisa/Pesquisa';
import Covid from './pages/covid/Covid'
import Obra from './pages/obra/Obra';
import Obras from './pages/obras/Obras';
import Licitacao from './pages/licitacao/Licitacao';
import Licitacoes from './pages/licitacoes/Licitacoes';
import Documento from './pages/documento/Documento.js';
import Documentos from './pages/documentos/Documentos.js';
import Contrato from './pages/contrato/Contrato.js';
import Contratos from './pages/contratos/Contratos.js';
import Video from './pages/video/Video.js';
import Carta from './pages/carta/Carta.js';
import Comissoes from './pages/comissoes/Comissoes'
import Comissao from './pages/comissao/Comissao'
import Plenarias from './pages/plenarias/Plenarias'
import Plenaria from './pages/plenaria/Plenaria'
import Proposicoes from './pages/proposicoes/Proposicoes'
import Proposicao from './pages/proposicao/Proposicao'
import Diretorias from './pages/diretorias/Diretorias'
import Diretoria from './pages/diretoria/Diretoria'

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import { PopupProvider } from './context/PopupContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <PopupProvider>
     <Router>   
        <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='/municipio' element={<Municipio/>}/>
            <Route path='/galeria' element={<Galeria/>} />
            <Route path='/galeria/:slug' element={<GaleriaEspecifica/>} />
            <Route path='/noticias' element={<Noticias/>}/>
            <Route path='/noticia' element={<Noticia/>}/>
            <Route path='/organograma' element={<Organograma/>}/>
            <Route path='/prefeitos' element={<Pessoas/>}/>
            <Route path='/pessoas/:slug' element={<Pessoa/>}/>
            <Route path='/fale-conosco' element={<FaleConosco/>}/>
            <Route path='/orgaos' element={<Orgaos/>}/>     
            <Route path='/orgao/:slug' element={<Orgao/>}/>     
            <Route path='/ouvidoria' element={<Ouvidoria/>}/> 
            <Route path='/concurso' element={<Concurso/>}/> 
            <Route path='/concurso/:slug' element={<ConcursoEspecifico/>}/>            
            <Route path='/ponto-turistico' element={<PontoTuristico/>}/>
            <Route path='/pontos-turisticos' element={<PontosTuristicos/>}/>
            <Route path='/ouvidoria/manifestacao' element={<Manifestacao/>}/>
            <Route path='/ouvidoria/manifestacao/:id' element={<ManifestacaoMostrar/>}/>
            <Route path='/esic/solicitacao' element={<Solicitacao/>}/> 
            <Route path='/esic/solicitacao/:id' element={<SolicitacaoMostrar/>}/>
            <Route path='/covid' element={<Covid/>}/> 
            <Route path='/chat' element={<Chat/> }/>
            <Route path='/esic' element={<ESic/> }/>
            <Route path='/perguntas-frequentes' element={<FAQ/> }/>
            <Route path='/pesquisa/:pesquisa' element={<Pesquisa/> }/>
            <Route path='*' element={<Page404/>}/>
            <Route path='mensagem/:mensagem' element={<Mensagem/>}/>
            <Route path='/obras' element={<Obras/>}/>
            <Route path='/obra' element={<Obra/>}/>
            <Route path='/licitacoes' element={<Licitacoes/>}/>
            <Route path='/licitacoes/:slug' element={<Licitacao/>}/>
            <Route path='/documentos' element={<Documentos/>}/>
            <Route path='/documentos/:slug' element={<Documento/>}/>
            <Route path='/contratos' element={<Contratos/>}/>
            <Route path='/contratos/:slug' element={<Contrato/>}/>
            <Route path='/video' element={<Video/>}/>
            <Route path='/carta-servicos' element={<Carta/>}/>
            <Route path='/comissao' element={<Comissoes/>}/> 
            <Route path='/comissao/:slug' element={<Comissao/>}/>       
            <Route path='/plenaria' element={<Plenarias/>}/> 
            <Route path='/plenaria/:slug' element={<Plenaria/>}/>       
            <Route path='/proposicao' element={<Proposicoes/>}/> 
            <Route path='/proposicao/:slug' element={<Proposicao/>}/>       
            <Route path='/diretoria' element={<Diretorias/>}/> 
            <Route path='/diretoria/:slug' element={<Diretoria/>}/>       

        </Routes>
      
      </Router>
     
      </PopupProvider>
  </React.StrictMode>
);



