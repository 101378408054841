import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/header";
import carregando from '../../components/Geral/loading.gif';
import './Orgao.css'
import {base_url, midia_url, foto_default, error_default} from '../../environment'
import { formatarNumero } from '../../environment/formatNumber';

import {RiWhatsappFill} from "react-icons/ri"
import {MdWatchLater} from "react-icons/md"
import {FaPhoneSquareAlt} from "react-icons/fa"
import {ImHome3} from "react-icons/im"
import { useParams } from "react-router-dom";
import { GrDocumentVerified } from "react-icons/gr";
import { FaNewspaper } from "react-icons/fa6";
import styled from "@emotion/styled";
var CSSPadrao = styled.div`${localStorage.getItem("cssGeral")}`

var optionClicado = "background-color: var(--mainColor); color: var(--textColor2);"
var linkAcaoPadrao = "_blank";

function Secretaria(){

    const [sobre, setsobre] = useState('');
    const [nome, setnome] = useState('');
    const [titulo, settitulo] = useState('');
    const [logo, setlogo] = useState('');

    const [whatsapp, setwhatsapp] = useState('');
    const [horario, sethorario] = useState('');
    const [fone, setfone] = useState('');
    const [endereco, setendereco] = useState('');

    const [secretario, setsecretario] = useState('');
    const [foto, setfoto] = useState('');
    const [id, setid] = useState('');

    const {slug} = useParams();
    if(slug == null){window.location = "/*";}

    function loading() { 
        return <div id='loading'><img src={carregando} alt="Carregando"/></div>;
    }  

    // pegar dados da secretaria e noticias
    useEffect(() => {
        fetch(`${base_url}/public/orgaos/${slug}`)
        .then((d) => d.json())
        .then(dados => {      
            //console.log(dados); 
            //console.log(dados.statusCode); 

            // eslint-disable-next-line
            if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message}`;}
                        
            // eslint-disable-next-line
            if(dados.statusCode == 404){
                window.location = "/*";
            }
            else
            {
                setsobre(dados.sobre || "(Não informado)");
                setnome(dados.nome || "(Não informado)");
                settitulo(dados.sigla || "(Não informado)");

                var logou;
                if(dados.logo == null){
                    logou = error_default;
                }
                else
                {
                    logou = midia_url+dados.logo;
                }
                setlogo(logou);
                
                setid(dados.id);
                setfone(formatarNumero(dados.fone) || "(Não informado)");
                setendereco(dados.endereco || "(Não informado)");
                
                setwhatsapp(formatarNumero(dados.celular) || "(Não informado)");
                //mudar clasname para ButtonC quando tiver dados"
                sethorario(dados.expediente || "(Não informado)");

                if((dados.Responsavel == null) || (dados.Responsavel.nome == null) ){
                    setsecretario("(Não informado)"); 
                }
                else
                {setsecretario(dados.Responsavel.nome)}

                var foto;
                if((dados.Responsavel == null) || (dados.Responsavel.foto == null)){
                    foto = foto_default;
                }
                else
                {
                    foto = midia_url+dados.Responsavel.foto;
                }
                setfoto(foto);  
                //console.log(foto); 
                document.getElementById("sobre").innerHTML = "Sobre"; 
            }
        });

             
    });   

     
    return(
        <>   
            <Header />
            <CSSPadrao>
                <div className="pagina">
                    <div className="paginaInfo">
                        <div id='infos' className='infos'>
                            <button className='buttonA'>
                                <img src={logo} alt="Foto"></img>
                                <p>{nome}</p>
                                <p>{titulo}</p>
                            </button>

                            <div className='buttonD'>
                                <img src={foto} alt="Foto"></img>
                                <span>Secretário (a): <br></br>{secretario}</span>
                            </div>
                            
                            <div className='buttonC'>
                                <button className="icones" >< MdWatchLater /></button>
                                <span>{horario}</span>
                            </div>

                            <div className='buttonB'>
                                <button className="icones" >< RiWhatsappFill /></button>
                                <span>{whatsapp}</span>
                            </div>

                            <div className='buttonB'>
                                <button className="icones" >< FaPhoneSquareAlt /></button>
                                <span>{fone}</span>
                            </div>

                            <div className='buttonB'>
                                <button className="icones" >< ImHome3 /></button>
                                <span>{endereco}</span>
                            </div>
                            
                           

                        </div>

                        

                        <div className="divSobre"> 
                            
                            <p  id="sobre" className="sobre">Carregando...</p>
                            <p className="sobre">{sobre}</p>

                            <div id='infos' className="sobre butSecr">
                                <div className='buttonB'>
                                    <a target="_blank" href={String("/documentos?orgao_id="+id)}> 
                                         <button className="Linkicone" >< GrDocumentVerified  />Documentos desta Secretaria</button>
                                    </a>
                                   
                                </div>
                                
                                <div className='buttonB '>
                                    <a target="_blank" href={String("/noticias?orgao_slug="+slug)}> 
                                        <button className="Linkicone" >< FaNewspaper   />Noticias desta Secretaria</button>
                                    </a>
                                    
                                </div>
                            </div>

                           
                        </div>

                    </div>   
                </div>
            </CSSPadrao>
            <Footer/>
        </>   
    );
}

export default Secretaria;