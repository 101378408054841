import {  TreeNode } from "react-organizational-chart";
import { AiFillMail } from "react-icons/ai";
import { BsFillPersonFill, BsTelephoneFill } from "react-icons/bs";

import '../style.css'
import styled from "@emotion/styled";

const Entidade = styled.div`

    width: 20rem;
  border: 1px solid black;
  display: inline-block;
  color: #121212;
  text-align: start;
  border-radius: 10px;
  outline: none;

  h1{
    text-align: center;
    width: 100%;
    padding: 10px;
    font-size: 1.3rem;
    background-color: #18BBD4; 
    border-bottom: 1px solid black;
    font-family: 'Inter';
    font-weight: 500;
    color: white;
    border-radius: 10px 10px 0 0;
  }
  p{
    margin-top: 0;
    font-family: 'Inter';
    font-weight: 500;
    border-bottom: 1px solid black;
    font-size: 1rem;
    padding: 5px;
  }
`



function Entidades(props){
    return(

        
        <TreeNode className="testeEnty"   
        label={
            
          <Entidade>
            <h1>{props.nome}</h1>
            <p> <BsFillPersonFill />{props.resposavel}</p>
            <p><AiFillMail/> {props.email}</p>
            <p><BsTelephoneFill />{props.celular}</p> 
          </Entidade>
         
        }
      >
                
      </TreeNode>
      

      

    );
}

export default Entidades;