import { useEffect, useRef, useState } from "react";
import carregando from '../../components/Geral/loading.gif';
import {base_url, midia_url, foto_default} from '../../environment'
import axios from 'axios';
import { useParams } from "react-router-dom";
import './style/manifestacao.css'
import Header from "../../components/Header/header";
import Footer from "../../components/Footer/footer";

import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import styled from "@emotion/styled";
var CSSPadrao = styled.div`${localStorage.getItem("cssGeral")}`

var unavez = true, umavez = true;

function Manifestação (){
    
    const [refreshKey, setRefreshKey] = useState(0);
    
    function atualiza() {
        setRefreshKey(oldKey => oldKey +1); 
    }

    const [alertAberto, setAlertAberto] = React.useState(false);
    const [alertMensagem, setAlertMensagem] = React.useState('');
    const [tipoAlerta, setTipoAlerta] = React.useState('');

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    function meuAlert(texto, tipo) {
        setTipoAlerta(tipo);
        setAlertMensagem(texto);
        window.location = `#root`;
        setAlertAberto(true);
    }

    function loading() {return <div id='loading'><img src={carregando} alt="Carregando"/></div>; }    
    
    const {id} = useParams();
    var token = localStorage.getItem('LOCAL_STORAGE_KEY__ACCESS_TOKEN');
 
    // pegar dados se não for novo
    useEffect(() => {              
        
        axios.get(`${base_url}/public/manifestacao/${id}`, 
        { headers: {
            'Authorization': `Bearer ${token}`,
        }})
        .then(async response => {             
            //console.log("Resposta ok");
            //console.log(response);
            //console.log(response.data);
        
            //pegar mensagens
            if(umavez){
                document.getElementById("manifestacaoMensagens").innerHTML = null;   
                for(let i=0; i<response.data.Mensagem.length; i++){
                                        
                    var divGeral = document.getElementById("manifestacaoMensagens");
                    
                    const divContainter = document.createElement("div");
    
                    const usuario = document.createElement("p");
                    
                    if(response.data.Mensagem[i].Usuario != null) 
                    {
                        usuario.innerHTML = 'Usuário: ' + response.data.Mensagem[i].Usuario.nome;
                    }
                    else
                    {
                        usuario.innerHTML = 'Usuário: ' + response.data.nome_manifestante;
                    }   
                    
                    const data = document.createElement("p");
                    data.innerHTML = 'Data: ' +  ((new Date(response.data.Mensagem[i].created_at).toISOString().split('T')[0]).split("-").reverse().join("/") || 'Data indefinida.');

                    const conteudo = document.createElement("p");
                    if(response.data.Mensagem[i].conteudo != null) 
                    {
                        conteudo.innerHTML = 'Conteúdo da mensagem: ' + response.data.Mensagem[i].conteudo;
                    }
                    else
                    {
                        conteudo.innerHTML = 'Conteúdo da mensagem: Sem mensagens.';
                    }  
                        
                    const anexos = document.createElement("p");
                    anexos.innerHTML = 'Anexos:';
                      
                    divContainter.appendChild(usuario);         
                    divContainter.appendChild(data);      
                    divContainter.appendChild(conteudo); 
                    divContainter.appendChild(anexos); 

                    for(let y=0; y<response.data.Mensagem[i].Anexo.length; y++){

                        const a = document.createElement("a");
                        //ou button?
                        a.innerHTML = response.data.Mensagem[i].Anexo[y].titulo;
                        a.target = "_blank";
                        a.href = midia_url+response.data.Mensagem[i].Anexo[y].anexo;
                        
                        divContainter.appendChild(a); 
                    }
                        
                    divGeral.appendChild(divContainter);
                    
                };
                umavez = false;
            } 
        })
        .catch(error => { 
            console.log(error.message);
            if(error.response)
            {
                if((error.response.data.statusCode == 404) || (error.response.data.statusCode == 400))
                {
                    if(unavez)
                    {
                        meuAlert("Comissão não existente.", "warning ");
                        //window.location.pathname = `concurso/editar/novo`;
                        unavez = false;
                    }
                    
                }
                else{
                    meuAlert(`${error.message}`, "error");
                }
                if(error.response.data.statusCode == 401)
                {
                    window.location.pathname = `login`;
                }
            }   
            else{
                meuAlert(`${error.message}`, "error");
            }            
        });
        
               
    },[refreshKey]); 

    
    function verificaVazios() {

        if(document.getElementById("conteudo").value == ""){meuAlert("Conteúdo não preenchido.", "warning");}
        else
        {
            return true;
        }    
    }
    
    // Cadastra manifestacao
    const FileUploadMultiple  = () => {

      const [alertAberto, setAlertAberto] = React.useState(false);
      const [alertMensagem, setAlertMensagem] = React.useState('');
      const [tipoAlerta, setTipoAlerta] = React.useState('');
    
      const [open, setOpen] = React.useState(false);
      const handleClose = () => {
          setOpen(false);
      };
      const handleOpen = () => {
          setOpen(true);
      };
    
      function meuAlert(texto, tipo) {
          setTipoAlerta(tipo);
          setAlertMensagem(texto);
          ///window.location = `#root`;
          setAlertAberto(true);
      }
    
      const {id} = useParams();
      const [fileList, setFileList] = useState<FileList | null>(null);
    
      const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFileList(e.target.files);
      };

        function iniciarVerificacao()
        {
            if(verificaVazios())
            {
                handleUploadClick();
            }; 
        }
    
      const handleUploadClick = async () => {
        //if (!fileList) {return;}
    
        // 👇 Create new FormData object and append files
        const data = new FormData();
        //files.forEach((file, i) => {
        files.forEach((file) => {
          //data.append(`file-${i}`, file, file.name);
          data.append(`anexo`, file, file.name);
        });

        data.append('conteudo', String(document.getElementById("conteudo").value))
        //data.append('anexo', input.files[0])  
            
        // 👇 Uploading the files using the fetch API to the server
    
        const url = `${base_url}/public/manifestacao/${id}`;
        var token = localStorage.getItem('LOCAL_STORAGE_KEY__ACCESS_TOKEN');
    
        document.getElementById("mensagem").innerHTML = "Processo em andamento, aguarde...";
        // eslint-disable-next-line
        {handleOpen()}   
        
        await axios.post(url, data, 
            { headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,
            }})
            .then(response => { 
                //console.log(response);
                //console.log("Resposta ok");
                //window.location.pathname = `concurso/editar/${response.data.id}`;
                // eslint-disable-next-line
                console.log(response.data.id);

                document.getElementById("mensagem").innerHTML = "Mensagem cadastrada com sucesso!";
                const mostrar = setInterval(function () {{handleClose()}; clearInterval(mostrar);}, 2000);     

                meuAlert(`Mensagem cadastrada com sucesso! Recarregue a página para ver o histórico.`, "success");
        
            })
            .catch(error => { 
              // eslint-disable-next-line
              {handleClose()}            
              console.log(error.message);
              //console.log("Erro "+error.response.data.statusCode);
              if(error.response)
              {
                // eslint-disable-next-line
                if(error.response.data.statusCode == 415)
                {
                  //alert("Formato de arquivo incorrreto! ")
                  meuAlert("Formato de arquivo incorrreto! ", "warning");
                }
                else
                {
                  meuAlert(`${error.message}`, "error");
                }
              }
              else{
                meuAlert(`${error.message}`, "error");
              }            
            })
    
      };
    
      // 👇 files is not an array, but it's iterable, spread to get an array of files
      const files = fileList ? [...fileList] : [];
      //console.log(files);
    
      return (
        <>  
          <div className='alertUpload'>
            <Box sx={{ width: '100%'}}>
              <Collapse in={alertAberto}>
                  <Alert
                  severity={tipoAlerta}
                  variant="filled"
                  action={
                      <IconButton
                      aria-label="close"
                      size="small"
                      color="inherit"
                      onClick={() => {
                          setAlertAberto(false);
                      }}
                      >
                      <CloseIcon/>
                      </IconButton>
                  }
                  sx={{ mb: 2 }}
                  >
                  {alertMensagem}
                  </Alert>
              </Collapse>
            </Box>
          </div>      
    
          <div>
            <input type="file" onChange={handleFileChange} multiple />
    
            <ul>
              {files.map((file, i) => (
                <li key={i}>
                  {(file.name.split(".").slice(0,1)).join().substring(0,15)+"..."} - Tipo: {file.type}
                </li>
              ))}
            </ul>
    
            <button className="form-btn" onClick={iniciarVerificacao}>Atualizar Manifestação</button>
          </div>
    
          <div className='uploadWait'>
              <Backdrop
                  sx={{ color: '#FFF', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={open}
              ><p id="mensagem" ></p>
                  <CircularProgress color="inherit" />
              </Backdrop>
          </div>
        </> 
      ); 
    }      
  
  return(
        <>
            <Header/>  
            <CSSPadrao>
            <Box sx={{ width: '100%'}}>
              <Collapse in={alertAberto}>
                  <Alert
                  severity={tipoAlerta}
                  variant="filled"
                  action={
                      <IconButton
                      aria-label="close"
                      size="small"
                      color="inherit"
                      onClick={() => {
                          setAlertAberto(false);
                      }}
                      >
                      <CloseIcon/>
                      </IconButton>
                  }
                  sx={{ mb: 2 }}
                  >
                  {alertMensagem}
                  </Alert>
              </Collapse>
            </Box>

            <div className="form-ouvidoria-container">
                
                <div className='form-ouvidoria-content'>                    

                    <div className='form-ouvidoria-header'><h2>Manifestação com o Protocolo: {id}</h2></div>
                    
                    <div className='form-ouvidoria-margin'>

                        <div className="cadastroDoc">
                            <div id="cadastroMensagem">
                                <div className="cadastroDocInterno">
                                    <p>Mensagens da manifestação</p>
                                    <div id="manifestacaoMensagens" className="anexos">
                                        {loading()}
                                    </div>   
                                </div>
                            </div>                        
                        </div>
                    
                        <div className="form-group textArea-ouvidoria">
                            <label >Digite aqui sua resposta:</label>      
                            <textarea                                                                
                                id="conteudo" 
                                name="conteudo" 
                                placeholder='Escreva aqui o conteúdo da sua resposta...'
                                rows="5"
                                //{...register("textoArea")}
                            /> 
                        </div>
                    
                        <div className="form-group form-documents">
                            <label>Anexar arquivos (PDF, PNG, JPG, DOCx, XLSx)</label>
                            <FileUploadMultiple/>    
                        </div>                       

                        
                    </div>
                    
                </div>            

            </div>
            </CSSPadrao>
            <Footer/>

            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                ><p id="notifica" >vghfgh</p>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </>
    );
}

export default Manifestação;