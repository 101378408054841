import Header from "../../components/Header/header";
import Footer from "../../components/Footer/footer";
import AccordionComponet from "../../components/Accordion/accordion";
import {React, useEffect, useState} from "react";
import { base_url } from "../../environment";
import './pergFreq.css'
import styled from "@emotion/styled";
var CSSPadrao = styled.div`${localStorage.getItem("cssGeral")}`

function FAQ (){

    const [question, setPergunta] = useState([])

    useEffect( () =>{
      async function fetchData1() { 
        try {
          const url = await fetch(
            `${base_url}/public/perguntas-frequentes`
          );
  
          const resource = await url.json();
         
          // eslint-disable-next-line
          if((resource.statusCode != undefined) && (resource.statusCode != 200)){window.location.pathname = `mensagem/${resource.message}`;}


          setPergunta(resource);
          console.log(resource)
         
        } catch (error) {
          console.log("requisição não de certo");
        }
      }
      fetchData1();
    },[])
    return(
            <>
                <Header/>
                <CSSPadrao>
                <div id="container-faq">
                  <p className="tituloPagina">Perguntas frequentes</p>
                  <div id="background-faq">
                
                    {question.map( itens  => (
                        <AccordionComponet title={itens.pergunta} description={itens.resposta}/>
                    ))}
                    
                  </div>
                </div>
                </CSSPadrao>
                <Footer/>
            </>
    );
}

export default FAQ;