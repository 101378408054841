import React, { useEffect, useState} from "react";
import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/header";
import './Proposicoes.css'
import carregando from '../../components/Geral/loading.gif';
import {base_url} from '../../environment'
import styled from "@emotion/styled";
var CSSPadrao = styled.div`${localStorage.getItem("cssGeral")}`
var optionClicado = "background-color: var(--mainColor); color: var(--textColor2);"

var urlDocs, essaVez=true, cadavez=true, pinaVez=true;

function Pessoa(){

    function loading() {
        return <div id='loading'><img src={carregando} alt="Carregando"/></div>;
    }

    const [refreshKey, setRefreshKey] = useState(0);

    
    const search = window.location.search;
    const params = new URLSearchParams(search);

    var tipo_id = params.get('tipo_id');
    var status_id = params.get('status_id');
    var tipo_autoria_id = params.get('tipo_autoria_id');

    if(String(params) == ""){
        urlDocs = String(base_url+"/public/camara/proposicoes");
    }
    else
    {
        urlDocs = String(base_url+"/public/camara/proposicoes"+"?"+params.toString());
    }

    // pegar dados tipo-proposicoes
    if(essaVez){
        fetch(`${base_url}/public/camara/tipo-proposicoes`)
        .then((d) => d.json())
        .then(dados => { 


            // eslint-disable-next-line 
            if(dados.length == 0)
            {
                window.location.pathname = `mensagem/Não há tipos de proposições.`;    
            }
            else
            {
                //console.log(dados);
                

                var dado = document.getElementById("tipo_id");   

                const option = document.createElement("option");
                option.value = "";
                option.innerHTML = "--";      
                option.id = "todos";        
                dado.appendChild(option);
                            
                for(let i=0; i<dados.length; i++){
                    const option = document.createElement("option");
                    option.value = dados[i].id;
                    option.id = dados[i].id;
                    option.innerHTML = dados[i].nome;                  
                    dado.appendChild(option);
                }

                if(tipo_id != null)
                document.getElementById(tipo_id).selected = true;
           


            }            
        })    
        .catch(error => { 
            console.log(error.message);
            if(error.response)
            {
                // eslint-disable-next-line
                if(error.dados.statusCode == 401)
                {
                    window.location.pathname = `login`;
                }
                else{
                    window.location.pathname = `mensagem/${error.message}`;
                }  
            }
            else{
                window.location.pathname = `mensagem/${error.message}`;
            }            
        });
        essaVez=false;
    }

    // pegar dados status-proposicoes
    if(cadavez){
        fetch(`${base_url}/public/camara/status-proposicoes`)
        .then((d) => d.json())
        .then(dados => { 


            // eslint-disable-next-line 
            if(dados.length == 0)
            {
                window.location.pathname = `mensagem/Não há status de proposições.`;    
            }
            else
            {
                //console.log(dados);
                

                var dado = document.getElementById("status_id");   

                const option = document.createElement("option");
                option.value = "";
                option.innerHTML = "--";      
                option.id = "todos";        
                dado.appendChild(option);
                            
                for(let i=0; i<dados.length; i++){
                    const option = document.createElement("option");
                    option.value = dados[i].id;
                    option.id = dados[i].id;
                    option.innerHTML = dados[i].nome;                  
                    dado.appendChild(option);
                }

                if(status_id != null)
                document.getElementById(status_id).selected = true;

            }            
        })    
        .catch(error => { 
            console.log(error.message);
            if(error.response)
            {
                // eslint-disable-next-line
                if(error.dados.statusCode == 401)
                {
                    window.location.pathname = `login`;
                }
                else{
                    window.location.pathname = `mensagem/${error.message}`;
                }  
            }
            else{
                window.location.pathname = `mensagem/${error.message}`;
            }            
        });
        cadavez=false;
    }

    // pegar dados tipo_autoria_id
    if(pinaVez){
        fetch(`${base_url}/public/camara/tipo-autoria-proposicoes`)
        .then((d) => d.json())
        .then(dados => { 


            // eslint-disable-next-line 
            if(dados.length == 0)
            {
                window.location.pathname = `mensagem/Não há tipo de autoria de proposições.`;    
            }
            else
            {
                //console.log(dados);
                

                var dado = document.getElementById("tipo_autoria_id");   

                const option = document.createElement("option");
                option.value = "";
                option.innerHTML = "--";      
                option.id = "todos";        
                dado.appendChild(option);
                            
                for(let i=0; i<dados.length; i++){
                    const option = document.createElement("option");
                    option.value = dados[i].id;
                    option.id = dados[i].id;
                    option.innerHTML = dados[i].nome;                  
                    dado.appendChild(option);
                }

                if(tipo_autoria_id != null)
                document.getElementById(tipo_autoria_id).selected = true;

            }            
        })    
        .catch(error => { 
            console.log(error.message);
            if(error.response)
            {
                // eslint-disable-next-line
                if(error.dados.statusCode == 401)
                {
                    window.location.pathname = `login`;
                }
                else{
                    window.location.pathname = `mensagem/${error.message}`;
                }  
            }
            else{
                window.location.pathname = `mensagem/${error.message}`;
            }            
        });
        pinaVez=false;
    }

    // pegar dados das proposicoes
    // filtros e funcoes no final
    useEffect(() => {
        fetch(urlDocs)
        .then((d) => d.json())
        .then(dados => { 

            // eslint-disable-next-line
            if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message}`;}

            // eslint-disable-next-line 
            if(dados.length == 0)
            {
                document.getElementById("aberto").innerHTML = '<button><p>Não há proposições.</p><p></p><p></p></button>';
                    
            }
            else
            {
                var arrayInicial = [];
                
                for(let i=0; i<dados.length; i++){

                    if((dados == null) || (dados[i] == null))
                    {
                        arrayInicial.push(
                            // eslint-disable-next-line
                            String('<button><p> Sem dados disponíveis. </p>'
                            +'<p> - </p>'
                            +'<p> - </p>'
                            +'</button>')                        
                        );
                        break;
                    }

                    var data = new Date(dados[i].data).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).toString();
                
                    arrayInicial.push(
                        // eslint-disable-next-line
                        String('<button><p>'+dados[i].titulo+', nº '+dados[i].numero+'</p><p>Data: </p><p>'+data+'</p><a target="_self" href=proposicao/'+dados[i].slug+'>Saiba mais</a></button>')
                        
                    );  
                }
                document.getElementById("aberto").innerHTML = arrayInicial;   
            }            
        });     


        //chama funcao ao selecionar o tipo_autoria_id
        document.getElementById("tipo_autoria_id").addEventListener("change", (e) => filtros(document.getElementById("tipo_autoria_id").value, null, null));


        //chama funcao ao selecionar a tipo_id
        document.getElementById("tipo_id").addEventListener("change", (e) => filtros(null, document.getElementById("tipo_id").value, null));
        
        //chama funcao ao selecionar o status_id
        document.getElementById("status_id").addEventListener("change", (e) => filtros(null, null, document.getElementById("status_id").value));

        
    },[refreshKey]); 

    
    function filtros(valor1, valor2, valor3){

        document.getElementById("aberto").innerHTML = '<button><p>Carregando...</p><p></p><p></p></button>';

        
        //window.location = String("licitacoes?modalidades-licitacao="+filtro);    
        
        // Change URL without reloading webpage
        const search = window.location.search;
        const params = new URLSearchParams(search);

        if(valor1 != null){params.set("tipo_autoria_id", valor1)}        
        if(valor2 != null)params.set("tipo_id", valor2);
        if(valor3 != null)params.set("status_id", valor3);
        
        if (valor1 == ""){params.delete("tipo_autoria_id");}
        if (valor2 == ""){params.delete("tipo_id");}
        if (valor3 == ""){params.delete("status_id");}

        //console.log(params.toString());
        window.history.replaceState({}, "", String(window.location.pathname+"?"+params.toString()));
        
        const redireciona = setInterval(function () {

            setRefreshKey(oldKey => oldKey +1); 
            clearInterval(redireciona);

        }, 2000);     
       // window.history.replaceState({}, "", String(window.location.pathname+window.location.search+"?modalidades-licitacao="+filtro));

    }

    // menu pesquisa
    const [pesquisa, setPesquisa] = React.useState("");
    function handleSubmit(event) {

        document.getElementById("aberto").innerHTML = '<button><p>Carregando...</p><p></p><p></p></button>';
        
        // Change URL without reloading webpage
        const search = window.location.search;
        const params = new URLSearchParams(search);

        params.set("pesquisa", pesquisa)      

        //console.log(params.toString());
        window.history.replaceState({}, "", String(window.location.pathname+"?"+params.toString()));
        
        const redireciona = setInterval(function () {

            setRefreshKey(oldKey => oldKey +1); 
            clearInterval(redireciona);

        }, 2000);    
    }   

    return(
        <>   
            <Header />
            <CSSPadrao>
                <div className="concurso">
                    <div className="tituloPagina"> 
                        <p id="titu" >Proposição</p>
                    </div>                

                    <div className="licitacaoOpt" >
                        
                        <div className="LicitacaoOptions">
                            <p>Autoria</p>
                            <select name="tipo_autoria_id" id="tipo_autoria_id">
                            </select>
                        </div>        

                        <div className="LicitacaoOptions">
                            <p>Tipo</p>
                            <select name="tipo_id" id="tipo_id">
                            </select>
                        </div>   


                        <div className="LicitacaoOptions">
                            <p>Status</p>
                            <select name="status_id" id="status_id">
                            </select>
                        </div>         

                        <div className="LicitacaoOptions">
                            <p>Pesquisar</p>
                            <input type="text" name="pesquisa" id="pesquisa" placeholder="Digite: " autoComplete="off" onChange={(e) => setPesquisa(e.target.value)} ></input>
                            <p onClick={handleSubmit}>Ir</p>
                        </div>             
                        
                    </div>   

                    <div className="concursoCard" >
                        <div id="aberto">{loading()}</div>
                    </div>                       
                </div>
                </CSSPadrao>
            <Footer/>
        </>   
    );
}

export default Pessoa;