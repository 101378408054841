import { useEffect, useState } from "react";
import {useParams } from "react-router-dom";
import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/header";
import './galeriaEspecifica.css'
import Image from "./components/Image";
import CloseIcon from '@mui/icons-material/Close';
import {base_url, midia_url} from '../../environment'
import styled from "@emotion/styled";
var CSSPadrao = styled.div`${localStorage.getItem("cssGeral")}`

let img = [];
function GaleriaEspecifica() { 

    const {slug} = useParams();
    const [imagens, setImagens] = useState([])
    //const [slide, setSlide] = useState([])
    const [model, setModel] = useState(false)
    const [tempimgSrc, setTempImgSrc] = useState('')
   

    useEffect(() => {
        fetch(`${base_url}/public/galerias/${slug}`)
        .then(resource => resource.json())
        .then(data => {
            
            // eslint-disable-next-line
            if((data.statusCode != undefined) && (data.statusCode != 200)){window.location.pathname = `mensagem/${data.message}`;}

            img = []; // resetar o array de imagens antes de adicionar novas imagens
            for (let i = 0; i < data.Imagem.length; i++) {   
                img.push({
                    original: midia_url + data.Imagem[i].imagem,
                    originalIndex: i // adicione o índice da imagem ao objeto de imagem
                });
            }
            //setSlide(img)    
            setImagens(data)
            //console.log(data)

            let teste = document.querySelector('.imagens_relacionadas');

           teste.addEventListener('click', function(){
                //if(model == false){
                    teste.classList.add('class')
                       
               // }
            })
       
        }, [])

      
    }, [slug])
    
    

    const getImg = (imagem, index) => {
       
        setModel(true);
        setTempImgSrc(index)
      
    }
   
   
    return ( 
        <div>
            <Header/>
            <CSSPadrao>
            <div className="container-img">
            <div className="container-galeriaEspecifica">
              
                <div className="container-imgEspecifico">
                   <div className="container-capa">
                        <img  src={midia_url+ imagens.capa} alt="Capagaleria"/>
                        
                    </div>
                    <div className="conteudo-img">
                        <h1 >{imagens.titulo || "Conteúdo não informado."} </h1>
                        <p>{imagens.conteudo || "Conteúdo não informado."}</p>
                        <p>{"Data de postagem: "+new Date(imagens.data).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).toString() || "Conteúdo não informado."}</p>
                    </div>
                </div>
                
                <div className="mains_imagens">


                <div  className={model? "model open": "model"} >
                                         
                    <div className="modal_imagem">
                   
                        <Image image={img} index={tempimgSrc} />   
                    </div>
                  
                    <CloseIcon className="cloneX" onClick={() => setModel(false)}/>
                </div>  

                <div className="imagens_relacionadas">
                    {imagens.Imagem?.map((image, index) => {           
                        return (
                            <div onClick={ () => getImg(image.imagem, index)}>
                                 <img src={midia_url + image.imagem} key={index} alt="galeria"/>
                               
                            </div>
                        )
                                     
                        })}
               
                </div>       
                <a href="/galeria" >
                    <button className="btnGaleria"> <span> VOLTAR </span></button>
                </a> 
              </div>
            </div>
            </div>
            </CSSPadrao>
            <Footer/>
        </div>
     );
}

export default GaleriaEspecifica;