import React, { useEffect, useState } from "react";
import './footer.css';

import logo3 from '../Geral/ooka.png';
import carregando from '../Geral/loading.gif';

import Facebook from '../../img/redes/Facebook.png';
import Instagram from '../../img/redes/Instagram.png';
import Twitter from '../../img/redes/Twitter.png';
import Tiktok from '../../img/redes/Tiktok.png';
import Youtube from '../../img/redes/Youtube.png';
import Telegram from '../../img/redes/Telegram.png';
import Whatsapp from '../../img/redes/Whatsapp.png';
import Linkedin from '../../img/redes/Linkedin.png';

import {base_url, midia_url,logo_default} from '../../environment'
import { formatarNumero } from '../../environment/formatNumber';

import styled from "@emotion/styled";
var CSSPadrao = styled.div`${localStorage.getItem("cssGeral")}`

var titulo1 = 'Links';
var titulo2 = 'Carregando...';

var url = base_url+'/public';
var caminhoGlobal = midia_url;
var linksFooter = base_url+'/public/links/footer';
var linkAcaoPadrao = "_blank";

var umPublic = true, umLinkFooter = true;

function Footer() {

    const [endereco, setEndereco] = useState('')
    const [fone, setFone] = useState('')
    const [email, setEmail] = useState('')
    const [expediente, setExpediente] = useState('')

     // useEffect public
    useEffect(() => {
        if(umPublic)
        { 
            fetch(url)
            .then((d) => d.json())
            .then(dados => {
                setEndereco(dados.endereco)
                //console.log(dados.endereco)
                setFone(formatarNumero(dados.fone))
                //console.log(dados.fone)
                setEmail(dados.email)
                //console.log(dados.email)
                setExpediente(dados.expediente)
                //console.log(dados.expediente)

                //console.log(dados.Entidade);

                if(dados.Entidade.logo == null){document.getElementById("logo2").src = logo_default;}
                else
                {
                    document.getElementById("logo2").src = caminhoGlobal+dados.Entidade.logo; 
                }   

                if(dados.Entidade.facebook != null){document.getElementById("fb").href = String(dados.Entidade.facebook); }
                if(dados.Entidade.instagram != null){document.getElementById("is").href = String(dados.Entidade.instagram); }
                if(dados.Entidade.twitter != null){document.getElementById("tw").href = String(dados.Entidade.twitter); }
                if(dados.Entidade.youtube != null){document.getElementById("yt").href = String(dados.Entidade.youtube); }
                     

                if(dados.Entidade.Responsavel.nome != null){

                    const a11 = document.createElement("a");
                    a11.innerHTML = "Responsável: "+dados.Entidade.Responsavel.nome; 
                    a11.href = "/pessoas/"+dados.Entidade.Responsavel.slug; 
                    a11.target = "_self";
                    
                    document.getElementById("responsavel").appendChild(a11); 
                }
                
                
                //if(dados.Entidade.tiktok != null){document.getElementById("4").href = dados.Entidade.tiktok;}
                //if(dados.Entidade.telegram != null){document.getElementById("6").href = dados.Entidade.telegram; }
                //if(dados.Entidade.whatsapp != null){document.getElementById("7").href = dados.Entidade.whatsapp;}
                //if(dados.Entidade.linkedin != null){document.getElementById("8").href = dados.Entidade.linkedin;}

                document.getElementById("segundoLink").innerHTML = String('<p>'+dados.nome+'</p><br/>');

                if(dados.Entidade.brasao != null){                         
                    document.getElementById("segundoLink").innerHTML = document.getElementById("segundoLink").innerHTML + 
                    String('<img src='+midia_url+dados.Entidade.brasao+' ></img>');  
                }

            });
            umPublic = false;
        }
    });

    var foneUrl = "tel:" + fone;
    var emailUrl = "mailto:" + email;

    // useEffect links do footer
    useEffect(() => {
        if(umLinkFooter)
        { 
            fetch(linksFooter)
            .then((d) => d.json())
            .then(dados => {            


                document.getElementById("primeiroLink").innerHTML = String('<p>'+titulo1+'</p><br/>');
            
                for(let i=0; i<dados.length; i++){

                    if(dados[i].tipo == "INTERNO"){
                        linkAcaoPadrao = "_self";
                    }
                    else
                    {
                        linkAcaoPadrao = "_blank";
                    }
                                
                    document.getElementById("primeiroLink").innerHTML = document.getElementById("primeiroLink").innerHTML + 
                    String('<a target='+linkAcaoPadrao+' href='+dados[i].url+'><p>'+dados[i].titulo+'</p></a>');  

                }



                {/*

                    document.getElementById("segundoLink").innerHTML = String('<p>'+titulo2+'</p><br/>');
                
                    for(let i=0; i<dados.length; i++){

                        if(dados[i].link == null){
                            urlFinal = dados[i].uri;
                            linkAcaoPadrao = "_self";
                        }
                        else
                        {urlFinal = dados[i].link;}
                                    
                        document.getElementById("segundoLink").innerHTML = document.getElementById("segundoLink").innerHTML + 
                        String('<a target='+linkAcaoPadrao+' href='+urlFinal+'><p>'+dados[i].titulo+'</p></a>');  

                        linkAcaoPadrao = "_blank";
                    }

                */} 
                

            });
            umLinkFooter = false;
        }
    });

    return (
        <>       
            <CSSPadrao>
            <div className="footer">
                <div id="footerA" className="footerA" >
                    <li>
                        <div className="logo2"><img id="logo2" className="loading" src={carregando} alt="banner2"/></div>
                        <br/>
                        <div className="footerInfoCardA">
                            <p>{endereco} <br/> {expediente}</p>
                            <p>Endereço</p>
                        </div>   
                        <div className="footerInfoCardB">
                            <p><a href={foneUrl}>{fone}</a><br/><a href={emailUrl}>{email}</a></p>
                            <p>Fale conosco</p>
                        </div>     
                    </li>
                    <div className="logo3" id="responsavel">
                        <img id="logo3" src={logo3} alt="fabricante"/>
                    </div>
               
                </div>    
                <div id="footerB" className="footerB">
                    <div className="footerRedes">

                        <p>Redes sociais </p>
                        <p>Redes sociais ( &uarr; para mais)</p>
                        <div className="redes"  id="redes">
                            
                           
                            <a  target="_blank" id="fb" ><button><img src={Facebook} alt="Facebook"/></button></a>  
                            <a  target="_blank" id="is" ><button><img src={Instagram} alt="Instagram"/></button></a>                          
                            <a  target="_blank" id="yt" ><button><img src={Youtube} alt="Youtube"/></button></a>    
                            <a  target="_blank" id="tw" ><button><img src={Twitter} alt="Twitter"/></button></a>   

                            {/*

                            
                            <a href="/" target="_blank" id="7" ><button><img src={Whatsapp} alt="Whatsapp"/></button></a>                           
                            <a href="/" target="_blank" id="8" ><button><img src={Linkedin} alt="Linkedin"/></button> </a>
                            <a href="/" target="_blank" id="4" ><button><img src={Tiktok} alt="Tiktok"/></button></a>
                            <a href="/" target="_blank" id="6" ><button><img src={Telegram} alt="Telegram"/></button></a>

                            */} 
                            
                        </div> 
                    </div>  
                    <div className="footerLinks footerLinksAtivo">
                        <li id="primeiroLink" >
                            <p>{titulo1}</p>
                            <br/>
                        </li>
                    </div>  
                    <div className="footerLinks">
                        <li id="segundoLink" className="logo4">
                            <p>{titulo2}</p>
                            <br/>
                        </li>
                    </div>
                    
                </div>  
                
            </div>    
            </CSSPadrao>
        </>
  );
}

export default Footer;