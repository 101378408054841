import React, { createContext, useState, useEffect } from "react";

const PopupContext = createContext();

export const PopupProvider = ({ children }) => {

  const [isOpen, setIsOpen] = useState(false);

  const setdata = () => {
    const datetime = new Date();
    if (datetime.getHours() === 10 && datetime.getMinutes() === 10) {
      return true;
    }
    return false;
  };
  
  const closePopup = setdata();
  
  useEffect(() => {
    setIsOpen(closePopup);
  }, [closePopup]);
  
  return (
    <PopupContext.Provider value={{ isOpen, closePopup }}>
      {children}
    </PopupContext.Provider>
  );
}

export default PopupContext;
