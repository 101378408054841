//import React, { useState} from "react";
import "./image.css";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
// Desinstalei essa biblioteca
//import "react-image-lightbox/style.css";

const Image = ( props ) => {
  return (
    <>
     <ImageGallery
        key={props.image}
        items={props.image}
        startIndex={props.index}
        showBullets={true}
        showFullscreenButton={false}
        showPlayButton={false}
      />
      
    </>
  );
};

export default Image;


//https://codesandbox.io/s/heuristic-bessie-252bsj?file=/demo.js:0-2139
