import styled from "@emotion/styled";

export const StyledNode = styled.div`
  border-radius: 10px;
  outline: none;
  width: 20rem;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  h1{
    margin-bottom: 0.5rem;
    text-align: center;
    width: 100%;
    padding: 10px;
    font-size: 1.3rem;
    background-color: var(--mainColor);
    border-bottom: 1px solid black;
    font-family: var(--fontA);
    font-weight: 500;
    color: var(--textColor2);
    border-radius: 10px 10px 0 0;
  }

  p{
    margin-top: 0;
    font-family: var(--fontA);
    font-weight: 500;
    border-bottom: 1px solid black;
    font-size: 1.1rem;
    padding: 5px;
  }
`;

export const Secretarias = styled.div `
    width: 20rem;
    border: 1px solid black;
    display: inline-block;
    color: var(--textColor3);
    text-align: start;
    border-radius: 10px;
    outline: none;
   
  h1{
    margin-bottom: 0.5rem;
    text-align: center;
    width: 100%;
    padding: 10px;
    font-size: 1.3rem;
    background-color: var(--mainColor4);
    border-bottom: 1px solid black;
    font-weight: 500;
    font-family: var(--fontA);
    color: var(--textColor2);
    border-radius: 10px 10px 0 0;
  }

  p{
    font-family: var(--fontA);
    font-weight: 500;
    font-size: 1.1rem;
    padding: 5px;
    border-bottom: 1px solid black;
  }

`;

export const Unidades = styled.div`
    width: 20rem;
    border: 1px solid black;
    display: inline-block;
    color: var(--textColor3);
    text-align: start;
    border-radius: 10px;

  h1{
    margin-bottom: 0.5rem;
    text-align: center;
    width: 100%;
    padding: 10px;
    font-size: 1.3rem;
    background-color: var(--mainColor3);
    border-bottom: 1px solid black;
    font-weight: 500;
    font-family: var(--fontA);
    color: var(--textColor2);
    border-radius: 10px 10px 0 0;
  }
  p{
    font-family: var(--fontA);
    font-weight: 500;
    font-size: 1.1rem;
    padding: 5px;
    border-bottom: 1px solid black;
  }

`;