import React, { useEffect} from "react";
import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/header";
import './Contrato.css'
import carregando from '../../components/Geral/loading.gif';
import {base_url, midia_url} from '../../environment'
import { useParams } from "react-router-dom";
import styled from "@emotion/styled";
var CSSPadrao = styled.div`${localStorage.getItem("cssGeral")}`

function Pessoa(){

    function loading() {
        return <div id='loading'><img src={carregando} alt="Carregando"/></div>;
    }

    const {slug} = useParams(); 

    // pegar dados do concurso especifico
    useEffect(() => {
        fetch(`${base_url}/public/contratos/${slug}`)
        .then((d) => d.json())
        .then(dados => {      
            //console.log(dados); 

            // eslint-disable-next-line
            if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message}`;}

            var arrayInicial = [];     
            
            var dataInicio = new Date(dados.data_inicio).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).toString();
            var dataFim = new Date(dados.data_fim).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).toString();
            var dataLicitacao = new Date(dados.Licitacao.data).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).toString();
            
            // eslint-disable-next-line
            document.getElementById("card1").innerHTML = 
            String('<p>Contrato Nº: '+dados.numero                
            +'.</p><p> Licitação: '+dados.Licitacao.numero
            +'</p><p>Data inicial: '+dataInicio
            +'.</p><p> Data final: '+dataFim            
            +'.</p><p> Data Licitação: '+dataLicitacao
            +'.</p><p> Objeto: '+dados.objeto
            +'.</p><p> Valor: '+dados.valor
            +'.</p><p> Fornecedor: '+dados.Fornecedor[0].identidade
            +'.</p><p> Nome do Fornecedor: '+dados.Fornecedor[0].nome
            +'.</p><p> Apelido do Fornecedor: '+dados.Fornecedor[0].apelido
            +'</p>'

            +'<p>Tipo de contrato: '+dados.Tipo.nome
            +' <a target="_blank" href='+midia_url+dados.arquivo
            +'><button>Baixar (Download)</button></a></p>') 

            arrayInicial.push(
                // eslint-disable-next-line
                String('<p>DEMAIS ANEXOS</p>')
            
            );

            for(let i=0; i<dados.Anexo.length; i++){
               
                arrayInicial.push(
                    // eslint-disable-next-line
                    String('<p>'+dados.Anexo[i].titulo+'<a target="_blank" href='+midia_url+dados.Anexo[i].anexo+'><button>Baixar (Download)</button></a></p>')
                );  
            }

            //console.log(arrayInicial);  
            document.getElementById("card2").innerHTML = arrayInicial;   
            document.getElementById("titu").innerHTML = "Contrato";

            //var dataInicio = new Date(dados.data_inicio).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).toString();
            //var dataFim = new Date(dados.data_fim).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).toString();
            //document.getElementById("titu2").innerHTML = 'Início: '+dataInicio+' - Fim: '+dataFim;
            
        });     
    });  
    
    return(
        <>   
            <Header />
            <CSSPadrao>
                <div className="concursoEsp">
                    <div className="tituloPagina"> 
                        <p id="titu" >Carregando...</p>
                    </div>   
                    <div className="InicialNome"> 
                        <p id="titu2" ></p>
                    </div>                    
                    <div className="concursoEspCard" >
                        <div></div>
                        <div id="card1">{loading()}</div>                                        
                    </div>
                    <div className="concursoEspCard linha" >
                        <div></div>
                        <div id="card2">{loading()}</div>                    
                    </div>
                       
                </div>
            </CSSPadrao>
            <Footer/>
        </>   
    );
}

export default Pessoa;