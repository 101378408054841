import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/header";
import Carrousel from "../../components/Galeria/Galeria"
import '../municipio/municipio.css'

import {FaMapMarkerAlt} from "react-icons/fa"
import CarrouselSimbolo from "./components/CarrouselSimbolo";
import { useEffect, useState } from "react";
import carregando from '../../components/Geral/loading.gif';
import {base_url, midia_url} from '../../environment'
import styled from "@emotion/styled";
var CSSPadrao = styled.div`${localStorage.getItem("cssGeral")}`

const imagens = [{original: carregando,},];

var unaVez = true;

function Municipio(){
   
    let caminhoImg = midia_url;
    const [municipio, setMuniciopio] = useState({})
    const [date, setDate] = useState('')
    const [populacao, setPopulacao] = useState(0)

    useEffect (() =>{
        fetch(`${base_url}/public/municipio`)
        .then( resultInfo => resultInfo.json())
        .then( dados => {

        if(unaVez)
        {
            
            // eslint-disable-next-line
            if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message}`;}
           
            let dataformatada = new Date(dados.data_aniversario).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' })
            //console.log(dataformatada.toString())
            let populacaoFormatada = dados.populacao.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");             
            
           
            imagens.pop();       
            for(let i=0; i<dados.Galeria.Imagem.length; i++){   
                imagens.push({original: caminhoImg+dados.Galeria.Imagem[i].imagem,},);
            }

            setMuniciopio(dados) 
            setDate(dataformatada);
            setPopulacao(populacaoFormatada)
                        
          
               
        
            fetch(`${base_url}/public/prefeitura/prefeito`)
            .then((d) => d.json())
            .then(dados => {                 
                
                // eslint-disable-next-line
                if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message}`;}

                document.getElementById('prefeito').src = caminhoImg+dados.foto;
                document.getElementById('nomePrefeito').innerHTML = dados.nome;

                
            });

            unaVez =false;
        }

        }) 
    })

      

    return(
        <div >
            <Header />               
            <CSSPadrao>
            <div className="container-municipio">
                
                <div className="municipio">
                    <div id="sobre" className="municipioSobre">                  
                        <div className="container">                    
                            <h2>Sobre</h2>
                            <p>{municipio.sobre}</p>
                        </div>   
                    </div>
                    
                    <div id="historia" className="historia">
                        
                        <div className="carrousel">        
                            {  
                                <Carrousel image={imagens} />
                            }
                                                                            
                        </div>
                        <div className="container">
                            <h2>História</h2>
                        <p>{municipio.historia}</p>
                        </div>
                    </div>

                    
                    
                    <div id="simbolos" className="simbolos">
                        <div className="container">
                        <h2>Símbolos</h2>
                            <div className="cardImg">
                            { municipio.Simbolo?.map( item => (
                                <CarrouselSimbolo img={caminhoImg+item.imagem} />
                            ))
                            }
                                                
                            </div>
                        </div>
                    </div>

                    <div id="hino" className="hino">
                
                        <div className="container">
                        <h2>Hino</h2>
                            <div className="text-hino">
                            
                            <p> {municipio.hino}</p>
                        
                                
                            </div>
                            
                        </div>                   
                    </div>

                    <div id="pontosTuristicos" className="pontosTuristicos">
                        <div className="container">
                        
                        
                            <div class="mapouter">
                            <div className="info">
                                <h2>Pontos turísticos  da cidade de Palmas</h2>
                                <a href="pontos-turisticos" target="_blank" ><button ><FaMapMarkerAlt/> <span>VER PONTOS</span></button></a>
                            </div>
                                <div class="gmap_canvas">
                                    <iframe src={municipio.link_maps} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </div>
                    </div>
                </div>
                    
                </div>

                <div className="menu-municipio">
                    <div className="container-prefeita">
                        <div className="bg-prefeita">
                            <div className="circle">  
                                    <img id="prefeito" src='' alt="fotoPrefeito(a)"></img>                             
                            </div>
                            
                            <div className="bg-text">
                                <h2>Prefeito (a) Municipal</h2>
                                <h3 id="nomePrefeito"></h3>                                           
                            </div>
                        </div>   
                    </div>

                    <ul className="menuItemInfo">
                        <li><b> Getilíco:</b> {municipio.gentilico}</li>
                        <li><b>População:</b> {populacao} </li>
                        <li><b> Area:</b> {municipio.area} km²</li>
                        <li><b>Data de aniversário:</b> {date}</li>
                    </ul>
                    <ul className="menuItem">
                        <a href="#sobre"><li>Sobre</li></a>
                        <a href="#historia"><li>História</li></a>                        
                        <a href="#simbolos"><li>Símbolos</li></a>
                        <a href="#hino"><li>Hino</li></a>
                        <a href="#pontosTuristicos"><li>Pontos turísticos</li></a> 
                    </ul>
                </div>
            </div>
            </CSSPadrao>
            <Footer/>
        </div>
    );
}

export default Municipio;