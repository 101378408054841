import React, { useEffect} from "react";
import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/header";
import './Comissao.css'
import carregando from '../../components/Geral/loading.gif';
import {base_url, midia_url} from '../../environment'
import { useParams } from "react-router-dom";
import styled from "@emotion/styled";
var CSSPadrao = styled.div`${localStorage.getItem("cssGeral")}`

function Pessoa(){

    function loading() {
        return <div id='loading'><img src={carregando} alt="Carregando"/></div>;
    }

    const {slug} = useParams(); 

    // pegar dados do concurso especifico
    useEffect(() => {
        fetch(`${base_url}/public/camara/comissoes/${slug}`)
        .then((d) => d.json())
        .then(dados => {      
            //console.log(dados); 

            // eslint-disable-next-line
            if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message}`;}

            var data = new Date(dados.data).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).toString();
            
            // eslint-disable-next-line
            document.getElementById("card1").innerHTML = String('<p>Data: '+data+'</p>'+'<p>'+dados.conteudo) 

            document.getElementById("titu").innerHTML = dados.nome;

        });     
    });  
    
    return(
        <>   
            <Header />
            <CSSPadrao>
                <div className="concursoEsp">
                    <div className="tituloPagina"> 
                        <p id="titu" >Carregando...</p>
                    </div>                 
                    <div className="concursoEspCard" >
                        <div></div>
                        <div id="card1">{loading()}</div>                                        
                    </div>
                       
                </div>
            </CSSPadrao>
            <Footer/>
        </>   
    );
}

export default Pessoa;