import React, { useState } from "react";
import styled from "@emotion/styled";
import Header from '../../../components/Header/header'
import Footer from '../../../components/Footer/footer'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
//import { MuiFileInput } from 'mui-file-input'


const ChatContainer = styled.div`
   /* Estilos aqui */

  width: 100%;

`;


const Container = styled.div`
 width:100% ;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
`;

const Head = styled.div`
margin-top: 10rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #075e54;
  color: #fff;
  padding: 10px;
  width:80% ;
`;

const ChatBox = styled.div`
  flex: 1;
  padding: 20px 20px 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width:80% ;
`;

const Message = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.isUserSender ? "flex-end" : "flex-start")};
  margin-bottom: 10px;
  
`;

const MessageText = styled.p`
  background-color: ${(props) => (props.isUserSender ? "#004348" : "#eee")};
  color: ${(props) => (props.isUserSender ? "white" : "black")};
  padding: 10px;
  border-radius: 10px;
  max-width: 60%;
`;

const MessageDate = styled.span`
  font-size: 12px;
  margin-top: 5px;
  color: #888;
  align-self: ${(props) =>
    props.className === "user" ? "flex-end" : "flex-start"};
`;

const MessageForm = styled.form`
  display: flex;
  align-items: center;
  background-color: #ededed;
  padding: 10px;
  width:80% ;
`;

const Input = styled.input`
  flex: 1;
  margin-right: 10px;
  padding: 10px;
  border: none;
  border-radius: 25px;
  background-color: #f5f5f5;
  font-size: 16px;
  color: #333;
  ::placeholder {
    color: #ccc;
  }
`;

const Button = styled.button`
  border: none;
  width: 3rem;
  height: 3rem;
  padding: 10px;
  border-radius: 82rem;
  margin-left: 0.5rem;
  background-color: #075e54;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
`;



const Chat = () => {
  // eslint-disable-next-line
  const [file, setFile] = React.useState(null)
// eslint-disable-next-line
  const handleChangeFile = (newFile) => {
    setFile(newFile)
  }
  const [message, setMessage] = useState("");
  const [isUserSender, setIsUserSender] = useState(true);
  

  const [messages, setMessages] = useState([
    {
      text: "Olá, como você está?",
      timestamp: new Date().getTime(),
      nome: 'Funcionario',
      isUserSender: false
    },
   

    {
      text: "Estou bem, e você?",
      timestamp: new Date().getTime(),
      nome: 'Você',
      isUserSender: true
    }
  ]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!message.trim()) return;
    const newMessage = {
      text: message,
      timestamp: new Date().getTime(),
      isUserSender
    };
    setMessages([...messages, newMessage]);
    setIsUserSender(!isUserSender);
    setMessage("");
  };

  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  return (
    <ChatContainer>
        
    <Header />
    
    <Container>
        
  
      <Head>
        <div>Procolo: 2320237293729</div>
        <div>Nome: Pessoa que fez a manifestação</div>
      </Head>
      <ChatBox>
        {messages.map((msg, index) => (
          <Message isUserSender={msg.isUserSender} key={index}>
        
            <MessageText isUserSender={msg.isUserSender}>
            <p style={{ fontWeight: "400", fontSize: "1rem", fontFamily: "Inter"}}>{msg.nome}</p>
                 
             <p> {msg.text}</p>
            </MessageText>
            <MessageDate className={msg.isUserSender ? "user" : "other"}>
              {new Date(msg.timestamp).toLocaleTimeString()}
            </MessageDate>
          </Message>
        ))}
      </ChatBox>
      <MessageForm onSubmit={handleSubmit}>
        <Input
          type="text"
          placeholder="Digite uma mensagem"
          value={message}
          onChange={handleChange}
        />
        <Button type="submit">&#10148;</Button>
        <Button type="submit"><InsertDriveFileIcon /> </Button>
      </MessageForm>
    </Container>
    <Footer />
    </ChatContainer>
  );
};

export default Chat;
