import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/header";
import '../pessoa/Pessoa.css'
import {base_url, midia_url, foto_default} from '../../environment'
import { formatarNumero } from '../../environment/formatNumber';

import {AiFillInstagram, AiFillTwitterCircle} from "react-icons/ai"
import {FaPhoneSquareAlt} from "react-icons/fa"
import {ImHome3} from "react-icons/im"
import {MdEmail} from "react-icons/md"
import {BsFacebook} from "react-icons/bs"
import { useParams } from "react-router-dom";
import styled from "@emotion/styled";
var CSSPadrao = styled.div`${localStorage.getItem("cssGeral")}`

function Pessoa(){

    const [sobre, setsobre] = useState('');
    const [nome, setnome] = useState('');
    const [titulo, settitulo] = useState('');
    const [foto, setfoto] = useState('');
    const [email, setemail] = useState('');
    const [facebook, setfacebook] = useState('');
    const [instagram, setinstagram] = useState('');
    const [twitter, settwitter] = useState('');
    const [fone, setfone] = useState('');
    const [endereco, setendereco] = useState('');
    
    const {slug} = useParams();
    
    // pegar dados da pessoa
    useEffect(() => {
        fetch(`${base_url}/public/pessoa/${slug}`)
        .then((d) => d.json())
        .then(dados => {      
            //console.log(dados); 
            //console.log(dados.statusCode); 
                        
            // eslint-disable-next-line
            if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message}`;}
            
            setsobre(dados.sobre || "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.");

            setnome(dados.nome || "(Não informado)");
            settitulo(dados.Cargo.titulo || "(Não informado)");

            var foto;
            if(dados.foto == null){
                foto = foto_default;
            }
            else
            {
                foto = midia_url+dados.foto;
            }
            setfoto(foto);
            
            setemail(dados.email || "(Não informado)");            
            setfone(formatarNumero(dados.fone) || "(Não informado)");
            setendereco(dados.endereco || "(Não informado)");
            
            var facebook, instagram, twitter;
            if(dados.facebook !=null)facebook=dados.facebook.substring(24);
            if(dados.instagram !=null)instagram=dados.instagram.substring(25);
            if(dados.twitter !=null)twitter=dados.twitter.substring(19);
            
            setfacebook(facebook || "(Não informado)");
            setinstagram(instagram || "(Não informado)");
            settwitter(twitter || "(Não informado)");
                       
        });
    });   
  
     
    return(
        <>   
            <Header />
            <CSSPadrao>
                <div className="pagina">
                    <div className="paginaInfo" id="secretariasInfo">
                         <div id='infos' className='infosA'>
                            <button className='buttonA'>
                                <img src={foto} alt="Foto"></img>
                                <p>{nome}</p>
                                <p>{titulo}</p>
                            </button>

                            <div className='buttonB'>
                                <button className="icones" >< MdEmail /></button>
                                <span>{email}</span>
                            </div>                          

                            <div className='buttonB'>
                                <button className="icones" >< BsFacebook /></button>
                                <span>{facebook}</span>
                            </div>

                            <div className='buttonB'>
                                <button className="icones" >< AiFillInstagram /></button>
                                <span>{instagram}</span>
                            </div>

                            <div className='buttonB'>
                                <button className="icones" >< AiFillTwitterCircle /></button>
                                <span>{twitter}</span>
                            </div>

                            <div className='buttonB'>
                                <button className="icones" >< FaPhoneSquareAlt /></button>
                                <span>{fone}</span>
                            </div>

                            <div className='buttonB'>
                                <button className="icones" >< ImHome3 /></button>
                                <span>{endereco}</span>
                            </div>
                            
                        </div>

                        <div id="sobre"> 
                            <p className="sobre">Sobre</p>
                            <p className="sobre">{sobre}</p>
                        </div>
                        
                    </div>   
                </div>
                </CSSPadrao>
            <Footer/>
        </>   
    );
}

export default Pessoa;