import React, { useEffect} from "react";
import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/header";
import carregando from '../../components/Geral/loading.gif';
import '../pontosTuristicos/PontosTuristicos.css';
import {base_url, midia_url, error_default} from '../../environment'
import styled from "@emotion/styled";
var CSSPadrao = styled.div`${localStorage.getItem("cssGeral")}`

var urlGeral = base_url+'/public/prefeitura/pontos-turisticos';
var urlCategorias =  base_url+'/public/prefeitura/categorias-ponto-turistico';
var caminhoGlobal = midia_url;
var linkAcaoPadrao = "_self";

var qtdPaginas=1, page, inicio = 0, fim, urlPagina,  tamanhoFixo = 9, categoria_id;
var umaVez=true;
var unaVez=true;
var optionClicado = "background-color: var(--mainColor); color: var(--textColor2);"

function PontosTuristicos(){

    function loading() {
        return <div id='loading'><img src={carregando} alt="Carregando"/></div>;
    }  
       
    const search = window.location.search;
    const params = new URLSearchParams(search);
    page = params.get('page');
    if(page == null)page = 1;

    inicio = tamanhoFixo * (page -1); 
    fim = tamanhoFixo * page;

    categoria_id = params.get('categoria_id');    
    console.log("categoria "+categoria_id); 
    localStorage.setItem("ptTurSelecionada", categoria_id);

    if(categoria_id == null){
        urlPagina = String(urlGeral+"?&start="+inicio+"&size="+fim);
    }
    else
    {
        urlPagina = String(urlGeral+"?start="+inicio+"&size="+fim+"&categoria_id="+categoria_id);
    }
  
    // preencher botoes secretarias
    useEffect(() => {
        fetch(urlCategorias)
        .then((d) => d.json())
        .then(dados => {      
            //console.log(dados.length); 

            // eslint-disable-next-line
            if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message}`;}
               
            var qtdSecretarias = dados.length;  

            if(categoria_id == null)
            {
                document.getElementById('none').style = optionClicado;
            }
            
            if(unaVez)
            {
                var mover = null;

                for(let i=0; i<qtdSecretarias; i++){
                  
                    var soNome = dados[i].nome;
                    const butin = document.createElement("button");  
                    butin.innerHTML = soNome;

                    // eslint-disable-next-line
                    if(categoria_id == dados[i].id)
                    {
                        butin.style = optionClicado;
                        document.getElementById('none').style = null;
                        mover = String(dados[i].id);
                    }                   

                    const link = document.createElement("a");
                    link.href = String("?categoria_id="+dados[i].id);
                    link.target = "_self";
                    link.id = dados[i].id;

                    link.appendChild(butin);
                    document.getElementById("ptsTuristicos").appendChild(link);
                }

                unaVez=false;

                if(mover != null)
                {
                    document.getElementById(mover).scrollIntoView({ behavior: "smooth", block: "end" });
                }
            }    
            
            
            //console.log(secretarias); 
                              
        });
    });

    // pegar dados dos PontosTuristicos (intervalo)
    useEffect(() => {
        fetch(urlPagina)
        .then((d) => d.json())
        .then(dados => {      
            //console.log(dados.length); 

            // eslint-disable-next-line
            if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message}`;}
               
            var arrayInicial = [];
            var origemNoticia;
            var mostraSecretaria;
            
            for(let i=0; i<tamanhoFixo; i++){
                //console.log(dados[i].Orgao);

                if(dados[i] == null)
                {
                    if(i > 0)break;
                    arrayInicial.push(
                        // eslint-disable-next-line
                        String('<a target="_self" href=/ rel="noreferrer"><button><img src='+error_default+
                        ' alt="Noticia"/><p></p><p>Não há dados. </p></button></a>')
                    );   
                }
                else
                {

                    mostraSecretaria = null;
                    
                    origemNoticia = "ponto-turistico?slug="+dados[i].slug;

                    mostraSecretaria = dados[i].Categoria.nome;

                    arrayInicial.push(
                        // eslint-disable-next-line
                        String('<a target='+linkAcaoPadrao+ ' href=/'+origemNoticia+
                        ' rel="noreferrer"><button><img src='+caminhoGlobal+dados[i].capa+
                        ' alt="Noticia"/><p>'+dados[i].titulo+
                        '</p><p>'+mostraSecretaria+'</p></button></a>')
                    );            

                    
                    linkAcaoPadrao = "_self";
                }
            }
            
            document.getElementById("dados").innerHTML = arrayInicial;            
            //console.log(arrayInicial);     
        });
    });
    

    // verificar quantas páginas tem
    useEffect(() => {

        if(categoria_id == null){
            fetch(urlGeral)
            .then((d) => d.json())
            .then(dados => {      
                //console.log(dados.length); 

                // eslint-disable-next-line
                if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message}`;}
               
                var extrapolou = dados.length % tamanhoFixo;
                qtdPaginas = (dados.length - extrapolou) / tamanhoFixo;
                // eslint-disable-next-line
                if(extrapolou != 0) qtdPaginas = qtdPaginas + 1;
                //console.log(qtdPaginas);     
                
                var movepag = null;

                if(umaVez)
                {

                    for(let i=1; i<=qtdPaginas; i++){

                        const index = document.createElement("a");
                        index.href = "?page="+i;
                        const butao = document.createElement("button");
                        butao.innerHTML = i;
                        butao.id = i;


                        // eslint-disable-next-line
                        if(page == i)
                        {
                            butao.style = optionClicado;
                            movepag = String(i);
                        }  

                        index.appendChild(butao);
                        document.getElementById("indexptsTuristicos").appendChild(index);   
                    }

                    umaVez=false;

                    if(movepag != null)
                    {
                        document.getElementById(movepag).scrollIntoView({ behavior: "smooth", block: "end" });
                    }   
                }
                
                document.getElementById("ultimaPagina").href = '?page='+ qtdPaginas;
                    
            });
        }
        else
        {
            fetch(urlPagina)
            .then((d) => d.json())
            .then(dados => {      
                //console.log(dados.length); 
            // eslint-disable-next-line
                if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message}`;}
               
                var extrapolou = dados.length % tamanhoFixo;
                qtdPaginas = (dados.length - extrapolou) / tamanhoFixo;
                // eslint-disable-next-line
                if(extrapolou != 0) qtdPaginas = qtdPaginas + 1;
                //console.log(qtdPaginas);     
                
                if(umaVez)
                {
                    for(let i=1; i<=qtdPaginas; i++){

                        const index = document.createElement("a");
                        index.href = "?page="+i;
                        const butao = document.createElement("button");
                        butao.innerHTML = i;

                        index.appendChild(butao);
                        document.getElementById("indexptsTuristicos").appendChild(index);   
                    }

                    umaVez=false;
                }
                
                document.getElementById("ultimaPagina").href = '?page='+ qtdPaginas
                document.getElementById("primeiraPagina").href = '?page=1';  
            });
        }        
    });

    // pular dez apenas se tiver páginas suficientes
    if((qtdPaginas-page) > 10){
        document.getElementById("menos10").href = '?page='+ (page - 10);
        document.getElementById("mais10").href = '?page='+ (page + 10);
    } 

    return(
        <>   
            <Header />
            <CSSPadrao>
                <div className="pontos-turisticos">
                    <p id="ptsTursTitulo" className="ptsTursTitulo">Guia da Cidade</p>
                    <div className="ptsTuristicos">
                        <div id="ptsTuristicos" >
                            <a href="/pontos-turisticos" target="_self"><button id="none">Todas</button></a>
                        </div>                    
                        
                        <div className="ptsTuristicosInfo" id="ptsTuristicosInfo">
                            <div id='dados'>{loading()} </div>  
                        </div>   
                    </div>
                    
                    <div className="ptsTuristicosMenu">
                        <div id="indexptsTuristicos"></div>
                        <div>
                            <a id="primeiraPagina" href="" ><button >Primeira</button></a>
                            <a id="menos10" href="" ><button >-10 páginas</button></a>
                            <a id="mais10" href="" ><button >+10 páginas</button></a>
                            <a id="ultimaPagina" href="" ><button >Última</button></a>
                        </div>
                    </div>
                </div>
                </CSSPadrao>
            <Footer/>
        </>   
    );
}

export default PontosTuristicos;