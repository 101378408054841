import { Chart } from 'react-google-charts';
import React from 'react';
//import { useEffect, useState } from 'react';
//import {base_url} from '../../../environment'

function GraficoPizza(props) {

  


  return (
    <Chart
      width={'100%'}
      height={'500px'}
      chartType="PieChart"
      data={[
        ['Task', 'Hours per Day'],

        ...Object.entries(props.grafico)
       /*  ['Total', 11],
        ['Em aberto', 2],
        ['Encerrado', 2], */
      ]}
      options={{
        backgroundColor: 'none',
        title: "Gráfico de Manisfestações",
        titleTextStyle: {
          
          color: '000000',
          fontSize: 25,
          textAlign: 'center',
          
        },
        is3D: true,
        chartArea: { width: '70%' },
        legend: { position: 'bottom' },
        responsive: true,
        breakpoints: {
          320: { chartArea: { width: '40%' } },
        },
      }}
      rootProps={{ 'data-testid': '1' }}
    />
  );
}

export default GraficoPizza;
