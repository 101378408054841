import React, { useState, useEffect } from "react";
import {base_url, midia_url} from '../../environment'
import {
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill
} from "react-icons/bs";
import { FaTimes } from "react-icons/fa";
import styled from "@emotion/styled";


const ModalContainer = styled.div`
  position: fixed;
  top: 19%;
  right: 1%;
  transform: translate(0, -50%);
  height: auto !important;
  width: 20vw;
  border-radius: 5px;
  filter:drop-shadow(2px 2px 10px black);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-top: 9rem;
  z-index: 99;

  @media screen and (max-width: 320px) {
    top: 10%;
    right: 2%;
    width: 80vw;
    height: 30vh;
  }

  @media (min-width: 321px) and (max-width: 475px) {
    top: 10%;
    right: 2%;
    width: 70vw;
    height: 30vh;
  }

  @media (min-width: 476px) and (max-width: 768px) {
    top: 15%;
    right: 2%;
    width: 55vw;
    height: 20vh;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    top: 30%;
    right: 5%;
    width: 40vw;
    height: 20vh;
  }

  @media (min-width: 1026px) and (max-width: 1286px) {
    top: 36%;
    right: 5%;
    width: 40vw;
    height: 20vh;
  }

  @media (min-width: 1287px) and (max-width: 1440px) {
    top: 36%;
    right: 5%;
    width: 40vw;
    height: 20vh;  
  }
`;

const ImageSlider = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const NavigationButtons = styled.div`
  position: absolute;
  top: 50%;
  left: 3%;
  right: 3%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  align-items: center;


`;

const ArrowButton = styled.button`
  color: #fff;
  font-size: 24px;
  background-color: #004348;
  border: none;
  cursor: pointer;
  transition: color 0.3s;
  padding: 10px;
  display: flex;
  justify-content: center;
  border-radius: 10px;

  &:hover {
    color: #fff;
    background-color: #004348;
  }
 
`;

const ImageLink = styled.a`
  text-decoration: none; // Opcional: remover decoração de texto do link
  
`;

const CloseIcon = styled(FaTimes)`
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  background-color: rgba(229, 103, 103, 0.856);
  padding: 5px;
  font-size: 19px;
  border-radius: 50%;
  margin: -17px;
  cursor: pointer;
  z-index: 1; /* Adicionado */
`;

const Modal = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % popup1.length);
    setvisto(visto + 1);
  };

  const prevImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + popup1.length) % popup1.length
    );

    if(visto > 0)
    {
      setvisto(visto - 1);
    };
  };

  const [isOpen, setIsOpen] = useState(true);
  const [visto, setvisto] = useState(1);
 
  

  const closeModal = () => {
    if(visualizados()){
      setIsOpen(false);
      localStorage.setItem('popupLateral', "OK");
    }
    else
    {
      nextImage();
    }
  };

  const visualizados = () => {
    //console.log(visto)
    if(visto >= popup1.length)
    {
      return true;
    }
    else
    {
      return false;
    }
  };

  const [popup1, setPopup1] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const url = await fetch(`${base_url}/public/popups/lateral`);
        const resource = await url.json();

        // eslint-disable-next-line
        if((resource.statusCode != undefined) && (resource.statusCode != 200)){window.location.pathname = `mensagem/${resource.message}`;}
    
        setPopup1(resource);
        //console.log(resource);
      } catch (error) {
        console.log('requisição não deu certo');
      }
    }

    fetchData();

  if(localStorage.getItem('popupLateral') == "OK")
  {
    //console.log(localStorage.getItem('popupLateral'))
    document.getElementById("popupLateral").hidden = true;
  }
  }, []);

  return (
    <div id="popupLateral">
      {isOpen && (
        
          <ModalContainer>
            <CloseIcon size={20} onClick={closeModal} />
            <ImageSlider>
              <NavigationButtons>
                <ArrowButton onClick={prevImage}>
                  <BsFillArrowLeftCircleFill />
                </ArrowButton>
                <ArrowButton onClick={nextImage}>
                  <BsFillArrowRightCircleFill />
                </ArrowButton>
              </NavigationButtons>
              <ImageLink href={popup1[currentImageIndex]?.link}>
                <Image
                  key={currentImageIndex}
                  src={midia_url + popup1[currentImageIndex]?.imagem}
                  alt="Imagem"
                />
              </ImageLink>
            </ImageSlider>
          </ModalContainer>
       
      )}
    </div>
  );
};

export default Modal;