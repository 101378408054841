import React, { useEffect, useState} from "react";
import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/header";
import './Licitacoes.css'
import carregando from '../../components/Geral/loading.gif';
import {base_url} from '../../environment'
import styled from "@emotion/styled";
var CSSPadrao = styled.div`${localStorage.getItem("cssGeral")}`
var optionClicado = "background-color: var(--mainColor); color: var(--textColor2);"

var urlLicitacoes, essaVez=true, cadavez=true;

function Pessoa(){

    function loading() {
        return <div id='loading'><img src={carregando} alt="Carregando"/></div>;
    }

    const [refreshKey, setRefreshKey] = useState(0);

    const search = window.location.search;
    const params = new URLSearchParams(search);
    //console.log(String(params))

    var modalidades_licitacao = params.get('modalidade_licitacao_id');
    var orgao = params.get('orgao_id');
    var status = params.get('status');
    
    console.log(status)
   
    if(String(params) == ""){
        urlLicitacoes = String(base_url+"/public/licitacoes");
    }
    else
    {
        urlLicitacoes = String(base_url+"/public/licitacoes"+"?"+params.toString());
    }

    // pegar dados modalidades licitacao
    if(essaVez){
        fetch(`${base_url}/public/modalidades-licitacao`)
        .then((d) => d.json())
        .then(dados => { 


            // eslint-disable-next-line 
            if(dados.length == 0)
            {
                window.location.pathname = `mensagem/Não há modalidades de licitação.`;    
            }
            else
            {
                //console.log(dados);
                

                var dado = document.getElementById("modalidade");   

                const option = document.createElement("option");
                option.value = "";
                option.innerHTML = "--";      
                option.id = "todos";        
                dado.appendChild(option);
                            
                for(let i=0; i<dados.length; i++){
                    const option = document.createElement("option");
                    option.value = dados[i].id;
                    option.id = dados[i].id;
                    option.innerHTML = dados[i].nome;                  
                    dado.appendChild(option);
                }

                if(modalidades_licitacao != null)
                document.getElementById(modalidades_licitacao).selected = true;

                 //seleciona opção do status
                if(status != null) 
                document.getElementById(status).selected = true;                      


            }            
        })    
        .catch(error => { 
            console.log(error.message);
            if(error.response)
            {
                // eslint-disable-next-line
                if(error.dados.statusCode == 401)
                {
                    window.location.pathname = `login`;
                }
                else{
                    window.location.pathname = `mensagem/${error.message}`;
                }  
            }
            else{
                window.location.pathname = `mensagem/${error.message}`;
            }            
        });
        essaVez=false;
    }

    // pegar dados orgaos
    if(cadavez){
        fetch(`${base_url}/public/orgaos`)
        .then((d) => d.json())
        .then(dados => { 


            // eslint-disable-next-line 
            if(dados.length == 0)
            {
                window.location.pathname = `mensagem/Não há órgãos.`;    
            }
            else
            {
                //console.log(dados);
                

                var dado = document.getElementById("orgao");   

                const option = document.createElement("option");
                option.value = "";
                option.innerHTML = "--";      
                option.id = "todos";        
                dado.appendChild(option);
                            
                for(let i=0; i<dados.length; i++){
                    const option = document.createElement("option");
                    option.value = dados[i].id;
                    option.id = dados[i].id;
                    option.innerHTML = dados[i].nome;                  
                    dado.appendChild(option);
                }

                if(orgao != null)
                document.getElementById(orgao).selected = true;

            }            
        })    
        .catch(error => { 
            console.log(error.message);
            if(error.response)
            {
                // eslint-disable-next-line
                if(error.dados.statusCode == 401)
                {
                    window.location.pathname = `login`;
                }
                else{
                    window.location.pathname = `mensagem/${error.message}`;
                }  
            }
            else{
                window.location.pathname = `mensagem/${error.message}`;
            }            
        });
        cadavez=false;
    }
    
    // pegar dados dos licitações
    // filtros e funcoes no final
    useEffect(() => {
        fetch(urlLicitacoes)
        .then((d) => d.json())
        .then(dados => { 

            // eslint-disable-next-line
            if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message}`;}

            // eslint-disable-next-line 
            if(dados.length == 0)
            {
                document.getElementById("valores").innerHTML = '<button><p>Não há licitações.</p><p></p><p></p></button>';
                    
            }
            else
            {
                var arrayInicial = [];
                
                for(let i=0; i<dados.length; i++){

                    if((dados == null) || (dados[i] == null))
                    {
                        arrayInicial.push(
                            // eslint-disable-next-line
                            String('<button><p> Sem dados disponíveis. </p>'
                            +'<p> - </p>'
                            +'<p> - </p>'
                            +'</button>')                        
                        );
                        break;
                    }

                    var data = new Date(dados[i].data).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).toString();


                   
                
                    arrayInicial.push(
                        // eslint-disable-next-line
                        String('<button><p>Licitação nº '+dados[i].numero+'</p>'   
                        +'<p>Status: '+primeiraMauiscula(dados[i].status)+'</p>'
                        +'<p>Modalidade: '+dados[i].Modalidade.nome+'</p>'
                        +'<p>Processo: '+dados[i].num_processo+'</p>'                                         
                        +'<p>Data da licitação: '+data+'</p>'                       
                        +'<a target="_self" href=licitacoes/'+dados[i].slug+'>Saiba mais</a>'
                        +'</button>')
                        
                    ); 
                }
                document.getElementById("valores").innerHTML = arrayInicial;   
            }            
        });     
             

        //chama funcao ao selecionar o status
        document.getElementById("status").addEventListener("change", (e) => filtros(document.getElementById("status").value, null, null));
        
        
        //chama funcao ao selecionar a modalidade
        document.getElementById("modalidade").addEventListener("change", (e) => filtros(null, document.getElementById("modalidade").value, null));
        
        //chama funcao ao selecionar a modalidade
        document.getElementById("orgao").addEventListener("change", (e) => filtros(null, null, document.getElementById("orgao").value));
        
    },[refreshKey]); 

    function primeiraMauiscula(valor) {
        
        var str = valor.replace("_", " ");
        str = str.toLowerCase();
        const words = str.split(' ');
        for (let i = 0; i < words.length; i++) {
            words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
        }
        return words.join(' ');
    }

    function filtros(valor1, valor2, valor3){

        document.getElementById("valores").innerHTML = '<button><p>Carregando...</p><p></p><p></p></button>';

        
        //window.location = String("licitacoes?modalidades-licitacao="+filtro);    
        
        // Change URL without reloading webpage
        const search = window.location.search;
        const params = new URLSearchParams(search);

        if(valor1 != null){params.set("status", valor1)}        
        if(valor2 != null)params.set("modalidade_licitacao_id", valor2);
        if(valor3 != null)params.set("orgao_id", valor3);
        
        if (valor1 == ""){params.delete("status");}
        if (valor2 == ""){params.delete("modalidade_licitacao_id");}
        if (valor3 == ""){params.delete("orgao_id");}

        //console.log(params.toString());
        window.history.replaceState({}, "", String(window.location.pathname+"?"+params.toString()));
        
        const redireciona = setInterval(function () {

            setRefreshKey(oldKey => oldKey +1); 
            clearInterval(redireciona);

        }, 2000);     
       // window.history.replaceState({}, "", String(window.location.pathname+window.location.search+"?modalidades-licitacao="+filtro));

    }

    // menu pesquisa
    const [pesquisa, setPesquisa] = React.useState("");
    function handleSubmit(event) {

        document.getElementById("valores").innerHTML = '<button><p>Carregando...</p><p></p><p></p></button>';
        
        // Change URL without reloading webpage
        const search = window.location.search;
        const params = new URLSearchParams(search);

        params.set("pesquisa", pesquisa)      

        //console.log(params.toString());
        window.history.replaceState({}, "", String(window.location.pathname+"?"+params.toString()));
        
        const redireciona = setInterval(function () {

            setRefreshKey(oldKey => oldKey +1); 
            clearInterval(redireciona);

        }, 2000);    
    }   
    
    return(
        <>   
            <Header />
            <CSSPadrao>
                <div className="licitacao">
                    <div className="tituloPagina"> 
                        <p id="titu" >Licitações</p>
                    </div>        

                    <div className="licitacaoOpt" >
                        
                        <div className="LicitacaoOptions">
                            <p>Status</p>
                            <select name="status" id="status">
                                <option value="" id="" > -- </option>
                                <option value="RASCUNHO" id="RASCUNHO" >Rascunho</option>
                                <option value="ADIADA" id="ADIADA" >Adiada</option>
                                <option value="AGUARDANDO_PARECER" id="AGUARDANDO_PARECER" >Aguardando parecer</option>
                                <option value="AGUARDANDO_PROPOSTA" id="AGUARDANDO_PROPOSTA" >Aguardando proposta</option>
                                <option value="ANULADA" id="ANULADA" >Anulada</option>
                                <option value="DESERTA" id="DESERTA" >Deserta</option>
                                <option value="EM_ANDAMENTO" id="EM_ANDAMENTO" >Em andamento</option>
                                <option value="EM_PRAZO_RECURSAL" id="EM_PRAZO_RECURSAL" >Em prazo recursal</option>
                                <option value="ENCERRADA" id="ENCERRADA" >Encerrada</option>
                                <option value="FRACASSADA" id="FRACASSADA" >Fracassada</option>
                                <option value="HOMOLOGADA" id="HOMOLOGADA" >Homologada</option>
                                <option value="REVOGADA" id="REVOGADA" >Revogada</option>
                                <option value="SUSPENSA" id="SUSPENSA" >Suspensa</option>
                            </select>
                        </div>        

                        <div className="LicitacaoOptions">
                            <p>Modalidade</p>
                            <select name="modalidade" id="modalidade">
                            </select>
                        </div>   


                        <div className="LicitacaoOptions">
                            <p>Órgão</p>
                            <select name="orgao" id="orgao">
                            </select>
                        </div>         

                        <div className="LicitacaoOptions">
                            <p>Pesquisar</p>
                            <input type="text" name="pesquisa" id="pesquisa" placeholder="Digite: " autoComplete="off" onChange={(e) => setPesquisa(e.target.value)} ></input>
                            <p onClick={handleSubmit}>Ir</p>
                        </div>             
                        
                    </div>   

                    <div className="licitacaoCard" >
                        <div id="valores">{loading()}</div>
                    </div>                       
                </div>
            </CSSPadrao>
            <Footer/>
        </>   
    );
}

export default Pessoa;