import React, { useEffect} from "react";
import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/header";
import '../licitacao/Licitacao.css'
import carregando from '../../components/Geral/loading.gif';
import {base_url, midia_url} from '../../environment'
import { useParams } from "react-router-dom";
import styled from "@emotion/styled";
var CSSPadrao = styled.div`${localStorage.getItem("cssGeral")}`

function Pessoa(){

    function loading() {
        return <div id='loading'><img src={carregando} alt="Carregando"/></div>;
    }

    const {slug} = useParams(); 

    // pegar dados do concurso especifico
    useEffect(() => {
        fetch(`${base_url}/public/licitacoes/${slug}`)
        .then((d) => d.json())
        .then(dados => {      
            //console.log(dados); 

            // eslint-disable-next-line
            if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message}`;}

            var arrayInicial = [];        

            var data = new Date(dados.data).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).toString();
            var data_certame = new Date(dados.data_certame).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).toString();
            
            // eslint-disable-next-line
            document.getElementById("card1").innerHTML =             
            String('<p>Número do Processo: '+dados.num_processo+'</p>'                                       
                +'<p>Data da licitação: '+data+'</p>'                                                
                +'<p>Data do Certame: '+data_certame+'</p>'         
                +'<p>Status: '+primeiraMauiscula(dados.status)+'</p>'
                +'<p>Objeto: '+dados.objeto+'</p>'
                +'<p>Valor Total: '+dados.valor_total+'</p>'
                +'<p>Orgão: '+dados.Orgao.nome+'</p>'     
                +'<p>Modalidade: '+dados.Modalidade.nome+'</p>'
                +'<p></p>'
                +'<p>Vencedor: '+dados.Vencedor.nome+" - CPF/CNPJ: "+dados.Vencedor.identidade+'</p>'
            )

            arrayInicial.push(
                // eslint-disable-next-line
                String('<p>ANEXOS</p>')
            
            );

            for(let i=0; i<dados.Anexo.length; i++){
               
                arrayInicial.push(
                    // eslint-disable-next-line
                    String('<p>'+dados.Anexo[i].titulo+'<a target="_blank" href='+midia_url+dados.Anexo[i].anexo+'><button>Baixar (Download)</button></a></p>')
                );  
            }

            //console.log(arrayInicial);  
            var teste = arrayInicial;
            document.getElementById("card2").innerHTML = arrayInicial;
            document.getElementById("titu").innerHTML = `Licitação ${dados.numero}`;

        });     
    });  

    function primeiraMauiscula(valor) {
        
        var str = valor.replace("_", " ");
        str = str.toLowerCase();
        const words = str.split(' ');
        for (let i = 0; i < words.length; i++) {
            words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
        }
        return words.join(' ');
    }
    
    return(
        <>   
            <Header />
            <CSSPadrao>
                <div className="concursoEsp">
                    <div className="tituloPagina"> 
                        <p id="titu" >Carregando...</p>
                    </div>                    
                    <div className="licitacaoEspCard" >
                        <div></div>
                        <div id="card1">{loading()}</div>                                        
                    </div>
                    <div className="licitacaoEspCard linha" >
                        <div></div>
                        <div id="card2">{loading()}</div>                    
                    </div>
                       
                </div>
            </CSSPadrao>
            <Footer/>
        </>   
    );
}

export default Pessoa;