import './carrouselSimbolo.css'

function CarrouselSimbolo(props) {
  return (
      <div className="cardSimbolos">       
            <img src={props.img} alt="imagem" />             
      </div>
); 
}

export default CarrouselSimbolo;
