import React, { useEffect} from "react";
import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/header";
import './Diretorias.css'
import carregando from '../../components/Geral/loading.gif';
import {base_url, midia_url, foto_default} from '../../environment'
import styled from "@emotion/styled";
var CSSPadrao = styled.div`${localStorage.getItem("cssGeral")}`
var linkAcaoPadrao = "_self";
var linkpessoa;

function Pessoas(){

    function loading() {
        return <div id='loading'><img src={carregando} alt="Carregando"/></div>;
    }

    // pegar dados dos prefeitos
    useEffect(() => {
        fetch(`${base_url}/public/diretoria`)
        .then((d) => d.json())
        .then(dados => {      
            //console.log(dados); 
            // eslint-disable-next-line
            if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message}`;}
               
            var arrayInicial = [], foto;
            linkpessoa = "../pessoas/"+dados[0].slug;
            //console.log(linkpessoa); 
            
            for(let i=0; i<dados.length; i++){

                if(dados[i] == null)
                {
                    document.getElementById("tit").innerHTML = "Sem dados disponíveis."; 
                    break;
                }

                if(dados[i].foto == null){
                    foto = foto_default;
                }
                else
                {
                    foto = midia_url+dados[i].foto;
                }

                // eslint-disable-next-line
                
                arrayInicial.push(
                    // eslint-disable-next-line
                    String('<a target='+linkAcaoPadrao+ ' href="../pessoas/'+dados[i].Pessoa.slug+'"><button type="button" ><img src='+midia_url+dados[i].Pessoa.foto+' alt="Pessoa"/><p>'+dados[i].Pessoa.nome+'</p><p>'+dados[i].Cargo.titulo+'</p></button></a>')
                
                );
            }
            //console.log(arrayInicial); 
            document.getElementById("pessoas").innerHTML = arrayInicial;   
            document.getElementById("tit").innerHTML = "Diretoria"; 
        });     
    });  
    
    return(
        <>   
            <Header />
            <CSSPadrao>
                <div className="galeriaPessoas">
                    <div id="tit" className="utitulo tituloPagina"> 
                        <p >Carregando...</p>
                    </div>                    
                    <div className="pessoas" >
                        <div id="pessoas">{loading()}</div>                      
                    </div>
                </div>
                </CSSPadrao>
            <Footer/>
        </>   
    );
}

export default Pessoas;