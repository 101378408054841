import { useEffect, useState } from "react";
import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/header";
import carregando from '../../components/Geral/loading.gif';
import './Orgaos.css'
import {base_url, midia_url, foto_default, error_default} from '../../environment'
import Skeleton from "@mui/material/Skeleton";
import styled from "@emotion/styled";
var CSSPadrao = styled.div`${localStorage.getItem("cssGeral")}`

var urlGeral = base_url+'/public/orgaos';

var qtdPaginas=1, page, inicio = 0, fim, urlPagina,  tamanhoFixo = 9, orgao_slug;
var umaVez=true;
var unaVez=true;
var pinaVez=true;
var optionClicado = "background-color: var(--mainColor); color: var(--textColor2);"


function Noticias(){

    function loading() {
        return <div id='loading'><img src={carregando} alt="Carregando"/></div>;
    }  

    const [orgaosArray, setorgaosArray] = useState([]);
       
    const search = window.location.search;
    const params = new URLSearchParams(search);
    page = params.get('page');
    if(page == null)page = 1;

    inicio = tamanhoFixo * (page -1); 
    fim = tamanhoFixo * page;

    orgao_slug = params.get('orgao_slug');    
    localStorage.setItem("secSelecionada", orgao_slug);

    if(orgao_slug == null){
        urlPagina = String(urlGeral+"?&start="+inicio+"&size="+fim);
    }
    else
    {
        urlPagina = String(urlGeral+"?start="+inicio+"&size="+fim+"&orgao_slug="+orgao_slug);
    }

    // pegar dados das orgaos (intervalo)
    useEffect(() => {
        if(pinaVez)
        {
            fetch(urlPagina)
            .then((d) => d.json())
            .then(dados => {      

                //setorgaosArray(dados);
                var orgao = [];
                for(let i=0; i<=dados.length; i++){

                    if(dados[i] == null){break;}                                
                    var origemNoticia = window.location.origin+"/orgao/"+dados[i].slug;

                    var imgSource;
                    if(dados[i].logo == null){
                        imgSource = error_default;}
                    else{
                        imgSource = midia_url+dados[i].logo;
                    }

                    var siglaG = (dados[i].sigla || "(Não informado)");
                    
                    orgao.push(
                        // eslint-disable-next-line
                        String('<a target="_self" href='+origemNoticia+'><button><img src='+imgSource+' alt="Órgão"/><p>'+dados[i].nome+'</p><p> '+siglaG+' </p></button></a>')
                    );
                }                  
                document.getElementById("dados").innerHTML = orgao; 

            });
            
            pinaVez=false;
        }
    }, []);

    // verificar quantas páginas tem
    if(umaVez)
    {
        if(orgao_slug == null){
            fetch(urlGeral)
            .then((d) => d.json())
            .then(dados => {      
                //console.log(dados.length); 
                // eslint-disable-next-line
                if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message}`;}

                var extrapolou = dados.length % tamanhoFixo;
                qtdPaginas = (dados.length - extrapolou) / tamanhoFixo;
                // eslint-disable-next-line
                if(extrapolou != 0) qtdPaginas = qtdPaginas + 1;
                //console.log(qtdPaginas);    
                
                var movepag = null;
                
                for(let i=1; i<=qtdPaginas; i++){

                    const index = document.createElement("a");
                    index.href = "orgaos?page="+i;
                    const butao = document.createElement("button");
                    butao.innerHTML = i;
                    butao.id = i;


                    // eslint-disable-next-line
                    if(page == i)
                    {
                        butao.style = optionClicado;
                        movepag = String(i);
                    }  

                    index.appendChild(butao);
                    document.getElementById("indexNoticias").appendChild(index);   
                }

                if(movepag != null)
                {
                    document.getElementById(movepag).scrollIntoView({ behavior: "smooth", block: "end" });
                }                
                
                document.getElementById("ultimaPagina").href = 'orgaos?page='+ qtdPaginas;
                    
            });
        }
        else
        {
            fetch(urlPagina)
            .then((d) => d.json())
            .then(dados => {      
                //console.log(dados.length); 
                // eslint-disable-next-line
                if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message}`;}

                var extrapolou = dados.length % tamanhoFixo;
                qtdPaginas = (dados.length - extrapolou) / tamanhoFixo;
                // eslint-disable-next-line
                if(extrapolou != 0) qtdPaginas = qtdPaginas + 1;
                //console.log(qtdPaginas);     
                
                
                    for(let i=1; i<=qtdPaginas; i++){

                        const index = document.createElement("a");
                        index.href = "orgaos?page="+i+"&orgao_slug="+orgao_slug;
                        const butao = document.createElement("button");
                        butao.innerHTML = i;
                        butao.id = i;

                        index.appendChild(butao);
                        document.getElementById("indexNoticias").appendChild(index);   
                    }

                
                
                
                document.getElementById("ultimaPagina").href = 'orgaos?page='+ qtdPaginas +"&orgao_slug="+orgao_slug;
                document.getElementById("primeiraPagina").href = 'orgaos?page=1&orgao_slug='+orgao_slug;  
            });
        }     
        
        umaVez=false;
    }   
   
    // pular dez apenas se tiver páginas suficientes
    if((qtdPaginas-page) > 10){
        document.getElementById("menos10").href = 'orgaos?page='+ (page - 10);
        document.getElementById("mais10").href = 'orgaos?page='+ (page + 10);
    } 


    return(
        <>   
            <Header />
            <CSSPadrao>
                <div className="tituloPagina"> 
                    <p id="pagNoticias" >Órgãos e Secretarias</p>
                </div>   
                
                <div className="orgaoTitulo">
                    <div id="ptsTuristicos" >
                    </div>                    
                    
                    <div className="ptsTuristicosInfo" id="ptsTuristicosInfo">
                        <div id='dados'>{loading()} </div>  
                    </div>   
                </div>
                
                <div className="menuNoticias">
                    <div id="indexNoticias"></div>
                    <div>
                        <a id="primeiraPagina" href="orgaos" ><button >Primeira</button></a>
                        <a id="menos10" href="orgaos" ><button >-10 páginas</button></a>
                        <a id="mais10" href="orgaos" ><button >+10 páginas</button></a>
                        <a id="ultimaPagina" href="orgaos" ><button >Última</button></a>
                    </div>
                </div>
                </CSSPadrao>
            <Footer/>
        </>   
    );
}

export default Noticias;