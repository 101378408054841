import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/header";
import PopupCentral from "../popup/PopupCentral";
import PopupLateral from '../popup/PopupLateral'
import PopupInferior from '../popup/PopupInferior'

import './Home.css';

import { Link } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import Carrousel from  '../../components/Galeria/Galeria'
import carregando from '../../components/Geral/loading.gif';
import {base_url, midia_url,foto_default} from '../../environment';

import styled from "@emotion/styled";
var CSSPadrao = styled.div`${localStorage.getItem("cssGeral")}`

const images = [{original: carregando,},];

var urlNoticias = base_url+'/public/noticias';
var urlVideos = base_url+'/public/videos';
var galerias = base_url+'/public/galerias';
var cidadao = base_url+'/public/links/home/cidadao';
var servidor = base_url+'/public/links/home/servidor';
var empresa = base_url+'/public/links/home/empresa';
var turista = base_url+'/public/links/home/turista';
var administracao = base_url+'/public/administracao';
var municipio = base_url+'/public/municipio';
var camara = base_url+'/public/camara';

var caminhoGlobal = midia_url;
var url = base_url+'/public';

var optionClicado = "background-color: var(--mainColor); color: var(--textColor2);"
var optionDesclicado = "background-color: var(--mainColor2); color: var(--textColor);"

var totalOptions = 4;
var linkAcaoPadrao = "_blank";

var umTurista = true, umPessoas = true, umEmpresa = true, umServidor = true, umCidadao = true, umVideos = true, umNoticias = true, umLocal = true, umGaleria = true;


function Home() {

const [local, setLocal] = useState('')
 
const [refreshKey, setRefreshKey] = useState(0);


function loading() {
  return <div id='loading'><img src={carregando} alt="Logo da opção selecionada"/></div>;
}

//useEffect texto do local (prefeitura ou camara)
 useEffect(() => {
  if(umLocal)
  {  
     fetch(url)
     .then((d) => d.json())
     .then(dados => {
         // eslint-disable-next-line
         if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message} - ${dados.statusCode} (public)`;}

         setLocal(dados.nome)
         //console.log(dados.nome)

         // eslint-disable-next-line
         if(dados.Config.tipo == "PREFEITURA"){
             fetch(municipio)
            .then((d) => d.json())
            .then(dados => {
                
                //console.log(dados)
                // eslint-disable-next-line
                if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message} - ${dados.statusCode} (local)`;}
        
                //console.log(dados)
                document.getElementById("localTexto").innerHTML = dados.sobre;
                //images[0].original = "https://picsum.photos/id/700/1000/600/";
            }); 
         }
     });
     
     umLocal = false;
  }
},[refreshKey]); 


// useEffect galeria
useEffect(() => {
  if(umGaleria)
  {  
    fetch(galerias)
    .then((d) => d.json())
    .then(dados => {

        // eslint-disable-next-line
        if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message} - ${dados.statusCode} (galeria)`;}
      
        images.pop();
        //images.push({original: 'https://picsum.photos/id/700/1000/600/',},);
        //images.push({original: 'https://picsum.photos/id/1012/1000/600/',},);
        
        for(let i=0; i<dados.length; i++){    
                  
            images.push({original: caminhoGlobal+dados[i].capa,},);
        }
    });
    umGaleria = false;
  }
},[refreshKey]); 

// useEffect das notícias
useEffect(() => {
  if(umNoticias)
  {  
    fetch(urlNoticias)
    .then((d) => d.json())
    .then(dados => {

        // eslint-disable-next-line
        if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message} - ${dados.statusCode} (notícia)`;}

        var dadosNoticia = [];
        for(let i=0; i<6; i++){

            if(dados[i] == null){break;}                              
            var origemNoticia = window.location.origin+"/noticia?slug="+dados[i].slug;

            if(dados[i].Orgao != null)
            {
                var mostraSecretaria = dados[i].Orgao.nome.substring(13);
            }
            else
            {
                var mostraSecretaria = "Geral";
            }

            dadosNoticia.push(
                // eslint-disable-next-line
                String('<a target="_blank" href='+origemNoticia+'><button><img src='+midia_url+dados[i].capa+' alt="Noticia"/><p>'+dados[i].titulo+'</p><p>'+mostraSecretaria+'</p></button></a>')
            );
        }                  
        document.getElementById("noticiasInfo").innerHTML = dadosNoticia;
    });
    umNoticias = false;
  }
},[refreshKey]); 

// useEffect dos videos - não fiz os videos automatizados porque o iframe não carrega
useEffect(() => {

  if(umVideos)
  {
    fetch(urlVideos)
    .then((d) => d.json())
    .then(dados => {

        // eslint-disable-next-line
        if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message} - ${dados.statusCode} (vídeo)`;}

        if (dados.length === 0) return;
       
            document.getElementById("videoPrincipal").src = 'https://www.youtube.com/embed/' + dados[0].link + '?controls=0?rel=0';

            for(let i=0; i<=dados.length; i++){

                if(dados[i] == null){break;}
            
                const butao = document.createElement("button");
                butao.id = dados[i].link;
                butao.addEventListener("click", (e) => BaixoOptSelec(e.target.id));
                
                const biframe = document.createElement("iframe");
                biframe.src = 'https://www.youtube.com/embed/' + dados[i].link + '?controls=0?rel=0';
                biframe.title= "seletor";
                biframe.width="";
                biframe.height="";

                butao.appendChild(biframe);
                document.getElementById("videoSeletor").appendChild(butao);   

            } 

           
    });
    umVideos = false;
  }
},[refreshKey]); 

// useEffect das opcoes cidadao
useEffect(() => {
  if(umCidadao)
  {
    fetch(cidadao)
    .then((d) => d.json())
    .then(dados => {      

        // eslint-disable-next-line
        if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message} - ${dados.statusCode} (cidadão)`;}

        //console.log(dados.length); 

        var arrayOptions = [];
        var urlFinal;
        
        for(let i=0; i<dados.length; i++){

            if(dados[i].link == null){
                urlFinal = dados[i].uri;
                linkAcaoPadrao = "_self";
            }
            else
            {urlFinal = dados[i].link;}

            var icone;
            // eslint-disable-next-line
            if(dados[i].Icone.imagem == "" ){icone = foto_default;}
            else{icone = dados[i].Icone.imagem;}  
                          
            arrayOptions.push(
                String('<a target='+linkAcaoPadrao+ ' href='+urlFinal+'><button><img src='+icone+' alt='+dados[i].Icone.titulo+'/> <br><p>'+dados[i].titulo+' </p></button></a>')
            );

            linkAcaoPadrao = "_blank";
        }
        document.getElementById("cidadao").innerHTML = arrayOptions;                              
    });
    umCidadao = false;
  }
},[refreshKey]); 

// useEffect das opcoes servidor
useEffect(() => {

  if(umServidor)
  {
    fetch(servidor)
    .then((d) => d.json())
    .then(dados => {      

        // eslint-disable-next-line
        if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message} - ${dados.statusCode} (servidor)`;}

        //console.log(dados.length); 

        var arrayOptions = [];
        var urlFinal;
        
        for(let i=0; i<dados.length; i++){

            if(dados[i].link == null){
                urlFinal = dados[i].uri;
                linkAcaoPadrao = "_self";
            }
            else
            {urlFinal = dados[i].link;}
                          
            var icone;
            // eslint-disable-next-line
            if(dados[i].Icone.imagem == "" ){icone = foto_default;}
            else{icone = dados[i].Icone.imagem;}  
                          
            arrayOptions.push(
                String('<a target='+linkAcaoPadrao+ ' href='+urlFinal+'><button><img src='+icone+' alt='+dados[i].Icone.titulo+'/> <br><p>'+dados[i].titulo+' </p></button></a>')
            );

            linkAcaoPadrao = "_blank";
        }
        document.getElementById("servidor").innerHTML = arrayOptions;                              
    });
    umServidor = false;
  }
},[refreshKey]); 

// useEffect das opcoes empresa
useEffect(() => {
 
  if(umEmpresa)
  {
    fetch(empresa)
    .then((d) => d.json())
    .then(dados => {      

        // eslint-disable-next-line
        if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message} - ${dados.statusCode} (empresa)`;}

        //console.log(dados.length); 

        var arrayOptions = [];
        var urlFinal;
        
        for(let i=0; i<dados.length; i++){

            if(dados[i].link == null){
                urlFinal = dados[i].uri;
                linkAcaoPadrao = "_self";
            }
            else
            {urlFinal = dados[i].link;}
                          
            var icone;
            // eslint-disable-next-line
            if(dados[i].Icone.imagem == "" ){icone = foto_default;}
            else{icone = dados[i].Icone.imagem;}  
                          
            arrayOptions.push(
                String('<a target='+linkAcaoPadrao+ ' href='+urlFinal+'><button><img src='+icone+' alt='+dados[i].Icone.titulo+'/> <br><p>'+dados[i].titulo+' </p></button></a>')
            );

            linkAcaoPadrao = "_blank";
        }
        document.getElementById("empresa").innerHTML = arrayOptions;                              
    });
    umEmpresa = false;
  }
},[refreshKey]); 

// useEffect das opcoes turista
useEffect(() => {

  if(umTurista)
  {
    fetch(turista)
    .then((d) => d.json())
    .then(dados => {      

        // eslint-disable-next-line
        if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message} - ${dados.statusCode} (turista)`;}

        //console.log(dados.length); 

        var arrayOptions = [];
        var urlFinal;
        
        for(let i=0; i<dados.length; i++){

            if(dados[i].link == null){
                urlFinal = dados[i].uri;
                linkAcaoPadrao = "_self";
            }
            else
            {urlFinal = dados[i].link;}
                          
            var icone;
            // eslint-disable-next-line
            if(dados[i].Icone.imagem == "" ){icone = foto_default;}
            else{icone = dados[i].Icone.imagem;}  
                          
            arrayOptions.push(
                String('<a target='+linkAcaoPadrao+ ' href='+urlFinal+'><button><img src='+icone+' alt='+dados[i].Icone.titulo+'/> <br><p>'+dados[i].titulo+' </p></button></a>')
            );

            linkAcaoPadrao = "_blank";
        }
        document.getElementById("turista").innerHTML = arrayOptions;                              
    });
    umTurista = false;
  }
},[refreshKey]); 

// useEffect pegar pessoas
useEffect(() => {

  if(umPessoas)
  {
    fetch(administracao)
    .then((d) => d.json())
    .then(dados => {  

        // eslint-disable-next-line
        if((dados.statusCode != undefined) && (dados.statusCode != 200)){window.location.pathname = `mensagem/${dados.message} - ${dados.statusCode} (admin)`;}

                    
        

            const list = document.getElementById("servidores");
            list.removeChild(list.lastElementChild);

            for(let i=0; i<dados.length; i++){

                const node = document.createElement("div");      
                node.className = "servidoresCard";

                const img = document.createElement("img");
                img.className = "img";
                img.src = caminhoGlobal+dados[i].foto;
                img.alt = dados[i].nome;

                const paragrafo = document.createElement("p");
                paragrafo.className = "texto";
                paragrafo.innerHTML = dados[i].nome;
                
                const link = document.createElement("a");
                link.href = "pessoas/"+dados[i].slug;
                link.target = "_self";
                
                link.appendChild(node);
                node.appendChild(img);
                node.appendChild(paragrafo);                    
                document.getElementById("servidores").appendChild(link);    

              
        }                      
    })   
    
    umPessoas = false;
  };
},[refreshKey]); 

function BaixoOptSelec(id) {
  for(let i=5; i<=8; i++){
      document.getElementById(i).style = optionDesclicado;
  }
  document.getElementById(id).style = optionClicado;       
  //console.log("Selecionou o botão " + id);

  // eslint-disable-next-line
  if(id != 5)
  {
      document.getElementById("noticiasInfo").setAttribute('hidden', '');
  }
  else
  {
      document.getElementById("noticiasInfo").removeAttribute('hidden');
      document.getElementById("videos").setAttribute('hidden', '');
  }

  // eslint-disable-next-line
  if(id != 6){
      document.getElementById("videos").setAttribute('hidden', '');
      document.getElementById("videos").style = "display: none;";
  }
  else{
      document.getElementById("videos").removeAttribute('hidden');
      document.getElementById("videos").style = "display: flex;";
  }

  // eslint-disable-next-line
  if(id == 7){window.location = "galeria";}
  // eslint-disable-next-line
  if(id == 8){window.location = "noticias";}

  //se maior que 8 é vídeos
  if(isNaN(id)){
      document.getElementById("videos").style = "display: flex;";
      document.getElementById("6").style = optionClicado;    
      document.getElementById("videoPrincipal").src = 'https://www.youtube.com/embed/' + id + '?controls=0?rel=0';
  }
}

function CimaOptSelec(id) {
  for(let i=1; i<=totalOptions; i++){
      document.getElementById(i).style = optionDesclicado;
  }
  document.getElementById(id).style = optionClicado;      
  //console.log("Selecionou o botão " + id); 

  switch (id) {
  case '1':
      document.getElementById("cidadao").removeAttribute('hidden');
      document.getElementById("servidor").setAttribute('hidden', '');
      document.getElementById("empresa").setAttribute('hidden', '');
      document.getElementById("turista").setAttribute('hidden', '');
      break;
  case '2':
      document.getElementById("cidadao").setAttribute('hidden', '');
      document.getElementById("servidor").removeAttribute('hidden');
      document.getElementById("empresa").setAttribute('hidden', '');
      document.getElementById("turista").setAttribute('hidden', '');
      break;
  case '3':
      document.getElementById("cidadao").setAttribute('hidden', '');
      document.getElementById("servidor").setAttribute('hidden', '');
      document.getElementById("empresa").removeAttribute('hidden');
      document.getElementById("turista").setAttribute('hidden', '');
      break;
  case '4':
      document.getElementById("cidadao").setAttribute('hidden', '');
      document.getElementById("servidor").setAttribute('hidden', '');
      document.getElementById("empresa").setAttribute('hidden', '');
      document.getElementById("turista").removeAttribute('hidden');
      break;
  default:
      document.getElementById("cidadao").removeAttribute('hidden');
      document.getElementById("servidor").setAttribute('hidden', '');
      document.getElementById("empresa").setAttribute('hidden', '');
      document.getElementById("turista").setAttribute('hidden', '');
  }

}

  return (
    <>  
      <div className="App">

        <PopupCentral />
        <Header/>
        <CSSPadrao>
        <PopupLateral />
        <PopupInferior />
        
        <div className="options">
          <div>
              <button id="1" onClick={(e) => CimaOptSelec(e.target.id)}>Cidadão</button>
              <button id="2" onClick={(e) => CimaOptSelec(e.target.id)}>Servidor</button>
              <button id="3" onClick={(e) => CimaOptSelec(e.target.id)}>Empresa</button>
              <button id="4" onClick={(e) => CimaOptSelec(e.target.id)}>Turista</button>
          </div>

          <div className="optionsInfo" id="optionsInfo">
              <div id='cidadao' >{loading()}</div>
              <div id='servidor' hidden>{loading()}</div>
              <div id='empresa' hidden>{loading()}</div>
              <div id='turista' hidden>{loading()}</div>
          </div>   
      </div>

        <div className="intermediario">
            <li>
                <button>Portal</button>
                <Link to={`/ouvidoria`}><button>Ouvidoria</button></Link>
                <Link to={`/esic`}><button>ESIC</button></Link>
            </li> 
        </div>
        
        <div className="noticias">
            <li>
                <button id="5" onClick={(e) => BaixoOptSelec(e.target.id)}>Notícias recentes</button>
                <button id="6" onClick={(e) => BaixoOptSelec(e.target.id)}>Vídeos</button>
                <button id="7" onClick={(e) => BaixoOptSelec(e.target.id)}>Galeria</button>
                <button id="8" onClick={(e) => BaixoOptSelec(e.target.id)}>Todas as notícias</button>
            </li>    

            <div className="noticiasInfo" id="noticiasInfo">  
                {loading()}
            </div>

            <div id="videos" className="videos" hidden>
                <iframe id="videoPrincipal" width="" height="" 
                src="" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" allowfullscreen></iframe>
            

                <div className="videoSeletor" id="videoSeletor">
                    
                </div>   
            </div>
        </div>
        
        <div className="local">
            <div className="localInfo">
                <p>{local}<br/></p>
                <p id="localTexto" >Carregando...</p>
            </div>   
            <div className="localGaleria">

                <Carrousel image={images}/>
            </div>       
        </div> 

        <div className="servidores" id="servidores">
            <div className="servidoresCard"><img className="img" src={carregando} alt="pessoas"/><p className="texto" >Carregando...</p></div>     
        </div>  
        </CSSPadrao>
        <Footer/>

      </div>

    </>
  );
}

export default Home;
